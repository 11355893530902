import * as transactionProcessCartPurchase from './transactionProcessCartPurchase';
import * as transactionProcessCartChild from './transactionProcessCartChild';

const shoppingCartTransactionProcesses = [
  {
    name: transactionProcessCartPurchase.PROCESS_NAME,
    alias: transactionProcessCartPurchase.PROCESS_ALIAS,
    process: transactionProcessCartPurchase,
    unitTypes: transactionProcessCartPurchase.UNIT_TYPES,
  },
  {
    name: transactionProcessCartChild.PROCESS_NAME,
    alias: transactionProcessCartChild.PROCESS_ALIAS,
    process: transactionProcessCartChild,
    unitTypes: transactionProcessCartChild.UNIT_TYPES,
  },
];

export default shoppingCartTransactionProcesses;