import omit from 'lodash/omit';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import isEmpty from 'lodash/isEmpty';
import { FORM_ERROR } from 'final-form';

import {
  types as sdkTypes,
  createImageVariantConfig,
} from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  JH_LISTING_TYPES,
  LISTING_STATE_PENDING_APPROVAL,
  OTHER_OPTION,
} from '../../util/types';
import { listRecords } from '../../util/airtableHelpers/list';
import {
  approveListing,
  checkSellerOnboardingSuccess,
  findExistListing,
  validateAddressByShippo,
} from '../../util/api';
import { stripeCustomer } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import { createSlug } from '../../util/urlHelpers';
import { getStoreId } from './SellerOnboardingPage.helpers';

const { UUID } = sdkTypes;

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { uploadedImages, uploadedImagesOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.uploadedImages);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );
  return duplicateImageEntities.length > 0
    ? {
        uploadedImages: {},
        uploadedImagesOrder: [],
      }
    : {
        uploadedImages,
        uploadedImagesOrder,
      };
};

const getImageVariantInfo = listingImageConfig => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

// When navigating through weekly calendar,
// we want to merge new week-related data (inProgres, error) to weeklyExceptionQueries hashmap.
const mergeToWeeklyExceptionQueries = (
  weeklyExceptionQueries,
  weekStartId,
  newDataProps
) => {
  return weekStartId
    ? {
        weeklyExceptionQueries: {
          ...weeklyExceptionQueries,
          [weekStartId]: {
            ...weeklyExceptionQueries[weekStartId],
            ...newDataProps,
          },
        },
      }
    : {};
};
// When navigating through monthly calendar (e.g. when adding a new AvailabilityException),
// we want to merge new month-related data (inProgres, error) to monthlyExceptionQueries hashmap.
const mergeToMonthlyExceptionQueries = (
  monthlyExceptionQueries,
  monthId,
  newDataProps
) => {
  return monthId
    ? {
        monthlyExceptionQueries: {
          ...monthlyExceptionQueries,
          [monthId]: {
            ...monthlyExceptionQueries[monthId],
            ...newDataProps,
          },
        },
      }
    : {};
};

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});

const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => payload => ({
  type: actionType,
  payload,
  error: true,
});

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/SellerOnboardingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/SellerOnboardingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST =
  'app/SellerOnboardingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS =
  'app/SellerOnboardingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR =
  'app/SellerOnboardingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST =
  'app/SellerOnboardingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS =
  'app/SellerOnboardingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR =
  'app/SellerOnboardingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST =
  'app/SellerOnboardingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS =
  'app/SellerOnboardingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR =
  'app/SellerOnboardingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST =
  'app/SellerOnboardingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS =
  'app/SellerOnboardingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR =
  'app/SellerOnboardingPage/SHOW_LISTINGS_ERROR';

export const FETCH_EXCEPTIONS_REQUEST =
  'app/SellerOnboardingPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS =
  'app/SellerOnboardingPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR =
  'app/SellerOnboardingPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';
export const FETCH_EXTRA_EXCEPTIONS_SUCCESS =
  'app/SellerOnboardingPage/FETCH_EXTRA_AVAILABILITY_EXCEPTIONS_SUCCESS';

export const ADD_EXCEPTION_REQUEST =
  'app/SellerOnboardingPage/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS =
  'app/SellerOnboardingPage/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR =
  'app/SellerOnboardingPage/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST =
  'app/SellerOnboardingPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS =
  'app/SellerOnboardingPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR =
  'app/SellerOnboardingPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const SET_STOCK_REQUEST = 'app/SellerOnboardingPage/SET_STOCK_REQUEST';
export const SET_STOCK_SUCCESS = 'app/SellerOnboardingPage/SET_STOCK_SUCCESS';
export const SET_STOCK_ERROR = 'app/SellerOnboardingPage/SET_STOCK_ERROR';

export const UPLOAD_IMAGE_REQUEST =
  'app/SellerOnboardingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS =
  'app/SellerOnboardingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/SellerOnboardingPage/UPLOAD_IMAGE_ERROR';

export const REMOVE_LISTING_IMAGE =
  'app/SellerOnboardingPage/REMOVE_LISTING_IMAGE';

export const SAVE_PAYOUT_DETAILS_REQUEST =
  'app/SellerOnboardingPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS =
  'app/SellerOnboardingPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR =
  'app/SellerOnboardingPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FETCH_PRODUCT_ATTRIBUTES_SUCCESS =
  'app/SellerOnboardingPage/FETCH_PRODUCT_ATTRIBUTES_SUCCESS';

export const CHECK_UNIQUE_NAME_REQUEST =
  'app/SellerOnboardingPage/CHECK_UNIQUE_NAME_REQUEST';
export const CHECK_UNIQUE_NAME_SUCCESS =
  'app/SellerOnboardingPage/CHECK_UNIQUE_NAME_SUCCESS';
export const CHECK_UNIQUE_NAME_ERROR =
  'app/SellerOnboardingPage/CHECK_UNIQUE_NAME_ERROR';

export const CREATE_SELLER_ONBOARDING_SUCCESSFULLY =
  'app/SellerOnboardingPage/CREATE_SELLER_ONBOARDING_SUCCESSFULLY';

export const VALIDATE_ADDRESS_REQUEST =
  'app/SellerOnboardingPage/VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS =
  'app/SellerOnboardingPage/VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_ERROR =
  'app/SellerOnboardingPage/VALIDATE_ADDRESS_ERROR';

export const UPDATE_USER_PRIVATE_DATA_REQUEST =
  'app/SellerOnboardingPage/UPDATE_USER_PRIVATE_DATA_REQUEST';
export const UPDATE_USER_PRIVATE_DATA_ERROR =
  'app/SellerOnboardingPage/UPDATE_USER_PRIVATE_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  listingId: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  setStockError: null,
  setStockInProgress: false,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  uploadedImages: {},
  uploadedImagesOrder: [],
  removedImageIds: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  weeklyExceptionQueries: {
    // '2022-12-12': { // Note: id/key is the start of the week in given time zone
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  monthlyExceptionQueries: {
    // '2022-12': {
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  allExceptions: [],
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  independentFields: [],
  productAttributes: [],

  isRedirectToManageListingPage: false,

  // To check product name unique
  isStoreNameUnique: true,
  checkUniqueStoreNameInProgress: false,
  checkUniqueStoreNameError: null,

  isCreateSellerOnboardingSuccessfully: false,

  validateAddressInProgress: false,
  validateAddressError: null,
  validateAddressResult: null,

  updateUserPrivateDataInProgress: false,
  updateUserPrivateDataError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
        isCreateSellerOnboardingSuccessfully: false,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        listingId: payload.listingId,
        publishListingError: null,
        isCreateSellerOnboardingSuccessfully: false,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishListingError: {
          listingId: state.listingId,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateListingError: null,
        isCreateSellerOnboardingSuccessfully: false,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        updateInProgress: false,
        // availabilityCalendar: { ...state.availabilityCalendar },
      };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return {
        ...state,
        showListingsError: null,
        independentFields: [],
        productAttributes: [],
        isCreateSellerOnboardingSuccessfully: false,
      };
    case SHOW_LISTINGS_SUCCESS: {
      const listingIdFromPayload = payload.data.id;
      const {
        listingId,
        allExceptions,
        weeklyExceptionQueries,
        monthlyExceptionQueries,
      } = state;
      // If listing stays the same, we trust previously fetched exception data.
      return listingIdFromPayload?.uuid === state.listingId?.uuid
        ? {
            ...initialState,
            listingId,
            allExceptions,
            weeklyExceptionQueries,
            monthlyExceptionQueries,
          }
        : { ...initialState, listingId: listingIdFromPayload };
    }
    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        showListingsError: payload,
        redirectToListing: false,
        independentFields: [],
        productAttributes: [],
      };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedImages = {
        ...state.uploadedImages,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedImages,
        uploadedImagesOrder: state.uploadedImagesOrder.concat([
          payload.params.id,
        ]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
      return { ...state, uploadedImages };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(
        i => i !== id
      );
      const uploadedImages = omit(state.uploadedImages, id);
      return {
        ...state,
        uploadedImagesOrder,
        uploadedImages,
        uploadImageError: error,
      };
    }

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.uploadedImages[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const uploadedImages = omit(state.uploadedImages, id);
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(
        i => i !== id
      );

      return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
    }

    case SET_STOCK_REQUEST:
      return { ...state, setStockInProgress: true, setStockError: null };
    case SET_STOCK_SUCCESS:
      return { ...state, setStockInProgress: false };
    case SET_STOCK_ERROR:
      return { ...state, setStockInProgress: false, setStockError: payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        payoutDetailsSaveInProgress: false,
        payoutDetailsSaved: true,
      };

    case FETCH_PRODUCT_ATTRIBUTES_SUCCESS:
      const { independentFields, productAttributes } = payload;
      return { ...state, independentFields, productAttributes };

    case CHECK_UNIQUE_NAME_REQUEST:
      return {
        ...state,
        checkUniqueStoreNameInProgress: true,
        isStoreNameUnique: null,
        checkUniqueStoreNameError: null,
      };
    case CHECK_UNIQUE_NAME_ERROR:
      return {
        ...state,
        checkUniqueStoreNameInProgress: false,
        checkUniqueStoreNameError: payload.error,
      };
    case CHECK_UNIQUE_NAME_SUCCESS:
      return {
        ...state,
        checkUniqueStoreNameInProgress: false,
        isStoreNameUnique: payload,
        checkUniqueStoreNameError: null,
      };

    case CREATE_SELLER_ONBOARDING_SUCCESSFULLY:
      return {
        ...state,
        isCreateSellerOnboardingSuccessfully: !state.isCreateSellerOnboardingSuccessfully,
      };

    case VALIDATE_ADDRESS_REQUEST:
      return {
        ...state,
        validateAddressInProgress: true,
        validateAddressError: null,
        validateAddressResult: null,
      };
    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressResult: payload,
      };
    case VALIDATE_ADDRESS_ERROR:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressError: payload,
      };

    case UPDATE_USER_PRIVATE_DATA_REQUEST:
      return {
        ...state,
        updateUserPrivateDataInProgress: true,
        updateUserPrivateDataError: null,
      };
    case UPDATE_USER_PRIVATE_DATA_ERROR:
      return {
        ...state,
        updateUserPrivateDataInProgress: false,
        updateUserPrivateDataError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraftRequest = requestAction(
  CREATE_LISTING_DRAFT_REQUEST
);
export const createListingDraftSuccess = successAction(
  CREATE_LISTING_DRAFT_SUCCESS
);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListingRequest = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListingRequest = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListingsRequest = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImageRequest = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: stock.compareAndSet
export const setStockRequest = requestAction(SET_STOCK_REQUEST);
export const setStockSuccess = successAction(SET_STOCK_SUCCESS);
export const setStockError = errorAction(SET_STOCK_ERROR);

export const savePayoutDetailsRequest = requestAction(
  SAVE_PAYOUT_DETAILS_REQUEST
);
export const savePayoutDetailsSuccess = successAction(
  SAVE_PAYOUT_DETAILS_SUCCESS
);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

export const fetchProductAttributesSuccess = successAction(
  FETCH_PRODUCT_ATTRIBUTES_SUCCESS
);

export const checkUniqueNameRequest = requestAction(CHECK_UNIQUE_NAME_REQUEST);
export const checkUniqueNameSuccess = successAction(CHECK_UNIQUE_NAME_SUCCESS);
export const checkUniqueStoreNameError = errorAction(CHECK_UNIQUE_NAME_ERROR);

export const createSellerOnboardingSuccess = () => ({
  type: CREATE_SELLER_ONBOARDING_SUCCESSFULLY,
});

export const validateAddressRequest = requestAction(VALIDATE_ADDRESS_REQUEST);
export const validateAddressSuccess = successAction(VALIDATE_ADDRESS_SUCCESS);
export const validateAddressError = errorAction(VALIDATE_ADDRESS_ERROR);

export const updateUserPrivateDataRequest = requestAction(
  UPDATE_USER_PRIVATE_DATA_REQUEST
);
export const updateUserPrivateDataError = errorAction(
  UPDATE_USER_PRIVATE_DATA_ERROR
);
// ================ Thunk ================ //

export function requestShowListing(actionPayload, config) {
  return (dispatch, getState, sdk) => {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(showListingsRequest(actionPayload));
    return sdk.ownListings
      .show({ ...actionPayload, ...queryParams })
      .then(response => {
        // SellerOnboardingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.SellerOnboardingPage (user will be redirected away)
        dispatch(showListingsSuccess(response));
        return response;
      })
      .then(async response => {
        const [listingData] = denormalisedResponseEntities(response);
        if (
          listingData.attributes.publicData?.listingType ===
          JH_LISTING_TYPES.PRODUCT_LISTING
        ) {
          const productAttributesList = await listRecords();
          dispatch(
            fetchProductAttributesSuccess({
              data: {
                independentFields: productAttributesList.filter(
                  record => !record?.depend_on
                ),
                productAttributes: productAttributesList.filter(
                  record => record?.depend_on
                ),
              },
            })
          );
          return response;
        }
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

// Set stock if requested among listing update info
export function compareAndSetStock(listingId, oldTotal, newTotal) {
  return (dispatch, getState, sdk) => {
    dispatch(setStockRequest());

    return sdk.stock
      .compareAndSet({ listingId, oldTotal, newTotal }, { expand: true })
      .then(response => {
        // NOTE: compareAndSet returns the stock resource of the listing.
        // We update client app's internal state with these updated API entities.
        dispatch(addMarketplaceEntities(response));
        dispatch(setStockSuccess(response));
      })
      .catch(e => {
        log.error(e, 'update-stock-failed', { listingId, oldTotal, newTotal });
        return dispatch(setStockError(storableError(e)));
      });
  };
}

// Helper function to make compareAndSetStock call if stock update is needed.
const updateStockOfListingMaybe = (listingId, stockTotals, dispatch) => {
  const { oldTotal, newTotal } = stockTotals || {};
  // Note: newTotal and oldTotal must be given, but oldTotal can be null
  const hasStockTotals = newTotal >= 0 && typeof oldTotal !== 'undefined';

  if (listingId && hasStockTotals) {
    return dispatch(compareAndSetStock(listingId, oldTotal, newTotal));
  }
  return Promise.resolve();
};

// Create listing in draft state
// NOTE: we want to keep it possible to include stock management field to the first wizard form.
// this means that there needs to be a sequence of calls:
// create, set stock, show listing (to get updated currentStock entity)
export function requestCreateListingDraft(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraftRequest(data));
    const { stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    // Note: in this template, image upload is not happening at the same time as listing creation.
    const imageProperty =
      typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingValues = { ...imageProperty, ...rest };

    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    let createDraftResponse = null;
    return sdk.ownListings
      .createDraft(ownListingValues, queryParams)
      .then(response => {
        createDraftResponse = response;
        const listingId = response.data.data.id;
        // If stockUpdate info is passed through, update stock
        return updateStockOfListingMaybe(listingId, stockUpdate, dispatch);
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(createDraftResponse));
        return createDraftResponse;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
// NOTE: what comes to stock management, this follows the same pattern used in create listing call
export function requestUpdateListing(tab, data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListingRequest(data));
    const { id, stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    const imageProperty =
      typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id, ...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    const state = getState();
    const existingTimeZone =
      state.marketplaceData.entities.ownListing[id.uuid]?.attributes
        ?.availabilityPlan?.timezone;
    const includedTimeZone = rest?.availabilityPlan?.timezone;

    // Note: if update values include stockUpdate, we'll do that first
    // That way we get updated currentStock info among ownListings.update
    return updateStockOfListingMaybe(id, stockUpdate, dispatch)
      .then(() => sdk.ownListings.update(ownListingUpdateValues, queryParams))
      .then(async response => {
        const [currentListing] = denormalisedResponseEntities(response);
        const listingPublicData = currentListing.attributes?.publicData;
        const { listingType } = listingPublicData || {};

        const { currentUser } = getState().user;

        let responseEntities = response;

        if (
          listingType === JH_LISTING_TYPES.PRODUCT_LISTING &&
          listingPublicData?.product_type !== OTHER_OPTION &&
          currentListing.attributes.state === LISTING_STATE_PENDING_APPROVAL
        ) {
          responseEntities = await approveListing({
            listingId: id.uuid,
            userId: currentUser.id.uuid,
          });

          if (responseEntities.status >= 200 && responseEntities.status < 300) {
            responseEntities.data.data.type = 'ownListing';
          }
        }

        dispatch(updateListingSuccess(response));
        dispatch(addMarketplaceEntities(response));
        dispatch(markTabUpdated(tab));

        return response;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = (
  listingId,
  needToApproveListing = false
) => (dispatch, getState, sdk) => {
  dispatch(publishListingRequest(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(async response => {
      let responseEntities = response;
      if (needToApproveListing) {
        const { currentUser } = getState().user;

        responseEntities = await approveListing({
          listingId: listingId.uuid,
          userId: currentUser.id.uuid,
        });

        responseEntities.data.data.type = 'ownListing';
      }

      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(responseEntities));
      dispatch(publishListingSuccess(responseEntities));
      return responseEntities;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload, listingImageConfig) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const imageVariantInfo = getImageVariantInfo(listingImageConfig);
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };
    dispatch(uploadImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadImageSuccess({
            data: { ...img, id, imageId: img.id, file: actionPayload.file },
          })
        );
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const savePayoutDetails = (values, isUpdateCall) => (
  dispatch,
  getState,
  sdk
) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const verifyOnboardingSuccess = () => async (
  dispatch,
  getState,
  sdk
) => {
  await checkSellerOnboardingSuccess();
  await dispatch(fetchCurrentUser());
  // JH TO-DO: will be handled in creating noti pop up card
  dispatch(createSellerOnboardingSuccess());
};

export const updateDataInUserProfile = updateParams => (
  dispatch,
  getState,
  sdk
) => {
  if (isEmpty(updateParams)) return;
  return sdk.currentUser.updateProfile({
    ...updateParams,
  });
};

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';

export const checkUniqueName = ({ title, authorId }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(checkUniqueNameRequest());
  const parsedTitle = createSlug(title);

  const params = {
    pub_storeName: parsedTitle,
  };

  return findExistListing(authorId)(params)
    .then(response => {
      const listings = denormalisedResponseEntities(response);

      if (listings.length === 0) {
        dispatch(checkUniqueNameSuccess({ data: true }));
        return true;
      } else {
        dispatch(checkUniqueNameSuccess({ data: false }));
        return false;
      }
    })
    .catch(e =>
      dispatch(checkUniqueStoreNameError({ error: storableError(e) }))
    );
};

export const updateUserPrivateData = params => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(updateUserPrivateDataRequest());
    return sdk.currentUser.updateProfile({
      privateData: params,
    });
  } catch (error) {
    dispatch(updateUserPrivateDataError(storableError(error)));
  }
};

export const validateStoreAddress = (address, intl) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(validateAddressRequest());
    const validationResponse = await validateAddressByShippo({
      address,
    });

    const validationResult = validationResponse.data;

    dispatch(validateAddressSuccess({ data: validationResponse.data }));

    if (!validationResult.isValid) {
      return {
        [FORM_ERROR]: intl.formatMessage({
          id: 'EditShopDetailsForm.validateAddressNotValid',
        }),
      };
    }

    return null;
  } catch (error) {
    console.error('validate-address-by-shippo-failed', error);
    dispatch(validateAddressError(error));
    return {
      [FORM_ERROR]: intl.formatMessage({
        id: 'EditShopDetailsForm.validateAddressFailed',
      }),
    };
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(clearUpdatedTab());

  const id = getStoreId(getState().user.currentUser);

  const { returnURLType } = params;

  if (returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS) {
    dispatch(verifyOnboardingSuccess());
  }

  const payload = { id: new UUID(id) };

  return Promise.all([
    dispatch(requestShowListing(payload, config)),
    dispatch(fetchCurrentUser()),
  ])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      dispatch(setInitialValuesForPaymentMethods());
      dispatch(stripeCustomer());

      return response;
    })
    .catch(e => {
      throw e;
    });
};
