import { isEmpty, sortBy, groupBy, isEqual, pick, values } from 'lodash';
import { storableError } from '../../../../util/errors';
import {
  currentUserShowSuccess,
  fetchCurrentUser,
} from '../../../../ducks/user.duck';
import { createImageVariantConfig } from '../../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../../util/data';
import { getCartData, storeCart } from '../../utils/cart';
import { queryCartListing } from '../../api';
import { JH_LISTING_TYPES, LISTING_STATE_CLOSED } from '../../../../util/types';
import { validateProductInCart } from './ShoppingCartPage.helper';
import { pushAddToCartDataToDataLayer } from '../../../../util/dataLayer';

const MAX_ITEMS = 100;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ShoppingCartPage/SET_INITIAL_VALUES';

export const UPDATE_SHOPPING_CART = 'app/ShoppingCartPage/UPDATE_SHOPPING_CART';

export const FETCH_CART_DATA_REQUEST =
  'app/ShoppingCartPage/FETCH_CART_DATA_REQUEST';
export const FETCH_CART_DATA_SUCCESS =
  'app/ShoppingCartPage/FETCH_CART_DATA_SUCCESS';
export const FETCH_CART_DATA_ERROR =
  'app/ShoppingCartPage/FETCH_CART_DATA_ERROR';

export const ADD_ITEM_TO_CART_DATA_REQUEST =
  'app/ShoppingCartPage/ADD_ITEM_TO_CART_DATA_REQUEST';
export const ADD_ITEM_TO_CART_DATA_SUCCESS =
  'app/ShoppingCartPage/ADD_ITEM_TO_CART_DATA_SUCCESS';
export const ADD_ITEM_TO_CART_DATA_ERROR =
  'app/ShoppingCartPage/ADD_ITEM_TO_CART_DATA_ERROR';

export const REMOVE_ITEM_TO_CART_DATA_REQUEST =
  'app/ShoppingCartPage/REMOVE_ITEM_TO_CART_DATA_REQUEST';
export const REMOVE_ITEM_TO_CART_DATA_SUCCESS =
  'app/ShoppingCartPage/REMOVE_ITEM_TO_CART_DATA_SUCCESS';
export const REMOVE_ITEM_TO_CART_DATA_ERROR =
  'app/ShoppingCartPage/REMOVE_ITEM_TO_CART_DATA_ERROR';

export const UPDATE_ITEM_QUANTITY_REQUEST =
  'app/ShoppingCartPage/UPDATE_ITEM_QUANTITY_REQUEST';
export const UPDATE_ITEM_QUANTITY_SUCCESS =
  'app/ShoppingCartPage/UPDATE_ITEM_QUANTITY_SUCCESS';
export const UPDATE_ITEM_QUANTITY_ERROR =
  'app/ShoppingCartPage/UPDATE_ITEM_QUANTITY_ERROR';

export const UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST =
  'app/ShoppingCartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS =
  'app/ShoppingCartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR =
  'app/ShoppingCartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR';

// ================ Reducer ================ //

const initialState = {
  cartProducts: [],
  cartData: [],
  storeData: [],
  fetchCartDataInProgress: false,
  fetchCartDataError: null,
  addedItemId: null,
  addItemInProgress: false,
  addItemError: null,
  removeItemInProgress: false,
  removeItemError: null,
  updateItemQuantityInProgress: false,
  updateItemQuantityError: null,
  prepareItemBeforeCheckoutError: null,
};

export default function ShoppingCartPageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...state, cartProducts: payload };

    case UPDATE_SHOPPING_CART:
      return { ...state, cartProducts: payload };

    case FETCH_CART_DATA_REQUEST:
      return {
        ...state,
        fetchCartDataInProgress: true,
        fetchCartDataError: null,
      };
    case FETCH_CART_DATA_SUCCESS:
      const updateCartProductsMaybe = payload.newCartData
        ? { cartProducts: payload.newCartData }
        : {};
      return {
        ...state,
        fetchCartDataInProgress: false,
        cartData: payload.listingData,
        storeData: payload.storeListings,
        shippingProfiles: payload.shippingProfiles,
        ...updateCartProductsMaybe,
      };
    case FETCH_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchCartDataInProgress: false,
        fetchCartDataError: payload,
      };

    case ADD_ITEM_TO_CART_DATA_REQUEST:
      return { ...state, addItemInProgress: true, addItemError: null };
    case ADD_ITEM_TO_CART_DATA_SUCCESS: {
      return {
        ...state,
        addItemInProgress: false,
        addedItemId: payload.listingId,
        cartProducts: payload.newCartData,
      };
    }
    case ADD_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, addItemInProgress: false, addItemError: payload };

    case REMOVE_ITEM_TO_CART_DATA_REQUEST:
      return { ...state, removeItemInProgress: true, removeItemError: null };
    case REMOVE_ITEM_TO_CART_DATA_SUCCESS: {
      const { newCartData, newCartProducts, newStoreData } = payload;

      return {
        ...state,
        removeItemInProgress: false,
        cartData: newCartData,
        cartProducts: newCartProducts,
        storeData: newStoreData,
      };
    }
    case REMOVE_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        removeItemInProgress: false,
        removeItemError: payload,
      };

    case UPDATE_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
        updateItemQuantityInProgress: true,
        updateItemQuantityError: null,
      };
    case UPDATE_ITEM_QUANTITY_SUCCESS: {
      return {
        ...state,
        updateItemQuantityInProgress: false,
        cartData: payload.newCartData,
        cartProducts: payload.newCartProducts,
      };
    }
    case UPDATE_ITEM_QUANTITY_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateItemQuantityInProgress: false,
        updateItemQuantityError: payload,
      };

    case UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST:
      return {
        ...state,
        prepareItemBeforeCheckoutError: null,
      };
    case UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS: {
      return {
        ...state,
        cartData: payload.newCartData,
        cartProducts: payload.newCartProducts,
      };
    }
    case UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        prepareItemBeforeCheckoutError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const setInitialValues = payload => ({ type: SET_INITIAL_VALUES, payload });

const updateShoppingCartAction = payload => ({
  type: UPDATE_SHOPPING_CART,
  payload,
});

const fetchCartDataRequest = () => ({ type: FETCH_CART_DATA_REQUEST });
const fetchCartDataSuccess = payload => ({
  type: FETCH_CART_DATA_SUCCESS,
  payload,
});
const fetchCartDataError = e => ({
  type: FETCH_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const addItemToCartDataRequest = () => ({
  type: ADD_ITEM_TO_CART_DATA_REQUEST,
});
const addItemToCartDataSuccess = payload => ({
  type: ADD_ITEM_TO_CART_DATA_SUCCESS,
  payload,
});
const addItemToCartDataError = e => ({
  type: ADD_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const removeItemToCartDataRequest = () => ({
  type: REMOVE_ITEM_TO_CART_DATA_REQUEST,
});
const removeItemToCartDataSuccess = payload => ({
  type: REMOVE_ITEM_TO_CART_DATA_SUCCESS,
  payload,
});
const removeItemToCartDataError = e => ({
  type: REMOVE_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const updateItemQuantityRequest = () => ({
  type: UPDATE_ITEM_QUANTITY_REQUEST,
});
const updateItemQuantitySuccess = payload => ({
  type: UPDATE_ITEM_QUANTITY_SUCCESS,
  payload,
});
const updateItemQuantityError = e => ({
  type: UPDATE_ITEM_QUANTITY_ERROR,
  error: true,
  payload: e,
});

const updateCartDataAfterCheckoutRequest = () => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST,
});
const updateCartDataAfterCheckoutSuccess = payload => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS,
  payload,
});
const updateCartDataAfterCheckoutError = e => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const setInitialValuesForShoppingCart = () => async (
  dispatch,
  getState,
  sdk
) => {
  const { currentUser } = getState().user;

  if (!currentUser) {
    await dispatch(fetchCurrentUser());
  }

  const { currentUser: fetchedCurrentUser } = getState().user;

  const { cartData = [] } = getCartData(fetchedCurrentUser);

  dispatch(setInitialValues(cartData));
};

export const updateShoppingCart = newCartData => (dispatch, getState, sdk) => {
  dispatch(updateShoppingCartAction(newCartData));
};

export const addItemToCart = (
  listing,
  currentCartData,
  itemOption = {}
) => async (dispatch, getState, sdk) => {
  dispatch(addItemToCartDataRequest());

  const {
    id: { uuid: listingId },
    author: {
      id: { uuid: authorId },
    },
  } = listing;

  const { isAuthenticated } = getState().auth;

  if (currentCartData.length === MAX_ITEMS) {
    dispatch(addItemToCartDataError(storableError(new Error('Cart is full'))));
    return;
  }

  const newCartDataRaw = [
    ...currentCartData,
    { listingId, checked: true, authorId, ...itemOption },
  ];

  const groupedByItem = groupBy(newCartDataRaw, elem =>
    JSON.stringify(pick(elem, ['listingId', 'variationMaybe']))
  );

  const newCartData = values(groupedByItem).map(group => {
    const item = group[0];
    const quantity = group.reduce((total, elem) => total + elem.quantity, 0);

    return {
      ...item,
      quantity,
    };
  });

  if (!isAuthenticated) {
    storeCart(newCartData);
    dispatch(addItemToCartDataSuccess({ listingId, newCartData }));
    pushAddToCartDataToDataLayer(listing, itemOption);
    return true;
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartData } })
    .then(() => {
      dispatch(addItemToCartDataSuccess({ listingId, newCartData }));
      pushAddToCartDataToDataLayer(listing, itemOption);
      return true;
    })
    .catch(e => {
      console.error(e);
      dispatch(addItemToCartDataError(storableError(e)));
      return false;
    });
};

export const removeItemFromCart = ({ listingId, variationMaybe }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(removeItemToCartDataRequest());

  const { isAuthenticated } = getState().auth;

  const { cartData, cartProducts, storeData } = getState().ShoppingCartPage;

  const newCartProducts = cartProducts.filter(item => {
    const {
      listingId: itemListingId,
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['listingId', 'variationMaybe']);
    return !isEqual(
      { listingId: itemListingId, variationMaybe: itemVariationMaybe },
      {
        listingId,
        variationMaybe,
      }
    );
  });

  const newCartData = cartData.filter(item => {
    const {
      id: { uuid: itemListingId },
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['id.uuid', 'variationMaybe']);
    return !isEqual(
      { listingId: itemListingId, variationMaybe: itemVariationMaybe },
      {
        listingId,
        variationMaybe,
      }
    );
  });

  const cartGroupedByStore = groupBy(newCartData, 'author.id.uuid');
  const newStoreData = storeData.filter(
    store => cartGroupedByStore[store.author.id.uuid]
  );

  if (!isAuthenticated) {
    storeCart(newCartProducts);
    return dispatch(
      removeItemToCartDataSuccess({
        newCartData,
        newCartProducts,
        newStoreData,
      })
    );
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } })
    .then(() =>
      dispatch(
        removeItemToCartDataSuccess({
          newCartData,
          newCartProducts,
          newStoreData,
        })
      )
    )
    .catch(e => {
      console.error(e);
      dispatch(removeItemToCartDataError(storableError(e)));
    });
};

export const updateItemQuantity = ({
  listingId,
  newQuantity,
  variationMaybe,
}) => (dispatch, getState, sdk) => {
  dispatch(updateItemQuantityRequest());

  const { isAuthenticated } = getState().auth;

  const { cartData, cartProducts } = getState().ShoppingCartPage;

  const newCartProducts = cartProducts.map(item => {
    if (
      JSON.stringify(pick(item, ['listingId', 'variationMaybe'])) ===
      JSON.stringify({ listingId, variationMaybe })
    ) {
      return {
        ...item,
        quantity: newQuantity,
      };
    }
    return item;
  });

  const newCartData = cartData.map(item => {
    const {
      id: { uuid: itemListingId },
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['id.uuid', 'variationMaybe']);
    if (
      JSON.stringify({
        listingId: itemListingId,
        variationMaybe: itemVariationMaybe,
      }) === JSON.stringify({ listingId, variationMaybe })
    ) {
      return {
        ...item,
        quantity: newQuantity,
      };
    }

    return item;
  });

  if (!isAuthenticated) {
    storeCart(newCartProducts);
    return dispatch(
      updateItemQuantitySuccess({
        newCartData,
        newCartProducts,
      })
    );
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } })
    .then(() =>
      dispatch(
        updateItemQuantitySuccess({
          newCartData,
          newCartProducts,
        })
      )
    )
    .catch(e => {
      console.error(e);
      dispatch(updateItemQuantityError(storableError(e)));
    });
};

export const updateCartDataAfterCheckout = (selectedItems = []) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateCartDataAfterCheckoutRequest());

  const { cartData, cartProducts } = getState().ShoppingCartPage;

  const cartItemShouldBeRemoved = selectedItems.map(item => {
    const { listingId, variationValues = '' } = pick(item, [
      'listingId',
      'variationValues',
    ]);
    return JSON.stringify({
      listingId,
      variationValues,
    });
  });

  const newCartProducts = cartProducts.filter(item => {
    const { listingId, variationValues = '' } = pick(item, [
      'listingId',
      'variationValues',
    ]);

    return !cartItemShouldBeRemoved.includes(
      JSON.stringify({
        listingId,
        variationValues,
      })
    );
  });

  const newCartData = cartData.filter(item => {
    const {
      id: { uuid: listingId },
      variationValues = '',
    } = pick(item, ['id.uuid', 'variationValues']);

    return !cartItemShouldBeRemoved.includes(
      JSON.stringify({
        listingId,
        variationValues,
      })
    );
  });

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } }, queryParams)
    .then(response => {
      dispatch(
        updateCartDataAfterCheckoutSuccess({ newCartData, newCartProducts })
      );

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error(
          'Expected a resource in the sdk.currentUser.updateProfile response'
        );
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => dispatch(updateCartDataAfterCheckoutError(storableError(e))));
};

const getListingData = (config, { cartListingIds, cartData }) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ids: cartListingIds,
    include: ['author', 'images'],
    'fields.listing': ['title', 'state', 'price', 'publicData'],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData',
      'profile.metadata',
    ],
    'fields.image': [`variants.${variantPrefix}`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    'limit.images': 1,
  };

  return queryCartListing(JSON.stringify(params)).then(response => {
    if (!isEmpty(response.data.data)) {
      const listings = denormalisedResponseEntities(response);
      const listingsData = cartData
        .map(item => {
          const { authorId, listingId, ...rest } = item;
          const listingDetails = listings.find(
            listing => listing.id.uuid === item.listingId
          );

          if (!listingDetails) {
            return null;
          }

          return { ...rest, ...listingDetails };
        })
        .filter(Boolean);

      return sortBy(
        listingsData,
        o => o.attributes.state === LISTING_STATE_CLOSED
      );
    }

    return [];
  });
};

const getStoreListingData = (config, { storeListingIds }) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ids: storeListingIds,
    include: ['author', 'images'],
    'fields.image': [`variants.${variantPrefix}`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings.query(params).then(responses => {
    const storeListings = denormalisedResponseEntities(responses);
    return storeListings;
  });
};

const getShippingProfiles = async (shippingProfileIds, sdk) => {
  const params = {
    pub_shippingProfileId: `${shippingProfileIds.join(',')}`,
    pub_listingType: JH_LISTING_TYPES.SHIPPING_PROFILE_LISTING,
  };

  return sdk.listings.query(params).then(responses => {
    const shippingProfiles = denormalisedResponseEntities(responses);
    return shippingProfiles;
  });
};

export const loadData = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCartDataRequest());

  let listingDataResponse;
  let newCartData;

  try {
    const { isAuthenticated } = getState().auth;

    if (!isAuthenticated) {
      const { cartData = [] } = getCartData(null);
      const cartListingIds = cartData.map(item => item.listingId);
      const listingsData = await dispatch(
        getListingData(config, { cartListingIds, cartData })
      );

      if (listingsData.length !== cartData.length) {
        const fetchedListingIds = listingsData.map(item => item.id.uuid);
        newCartData = cartData.filter(item =>
          fetchedListingIds.includes(item.listingId)
        );
        storeCart(newCartData);
      }

      listingDataResponse = validateProductInCart(listingsData);
      const storeListingIds = listingsData.map(
        item => item.author.attributes.profile.metadata.storeId
      );
      const shippingProfileIds = [
        ...new Set(
          listingsData.map(
            listing => listing.attributes.publicData.shippingProfileId
          )
        ),
      ];
      const [storeListings, shippingProfiles] = await Promise.all([
        dispatch(getStoreListingData(config, { storeListingIds })),
        getShippingProfiles(shippingProfileIds, sdk),
      ]);

      dispatch(
        fetchCartDataSuccess({
          listingData: listingDataResponse,
          storeListings,
          newCartData,
          shippingProfiles,
        })
      );
      return;
    }

    const response = await sdk.currentUser.show();
    const currentUser = denormalisedResponseEntities(response)[0];
    const { cartData = [] } = getCartData(currentUser);
    const cartListingIds = cartData.map(item => item.listingId);

    let listingsData = await dispatch(
      getListingData(config, { cartListingIds, cartData })
    );

    if (listingsData.length !== cartData.length) {
      const fetchedListingIds = listingsData.map(item => item.id.uuid);
      newCartData = cartData.filter(item =>
        fetchedListingIds.includes(item.listingId)
      );

      await sdk.currentUser.updateProfile({
        privateData: { cartData: newCartData },
      });

      listingsData = await dispatch(
        getListingData(config, {
          cartListingIds: fetchedListingIds,
          cartData: newCartData,
        })
      );
    }

    listingDataResponse = validateProductInCart(listingsData);
    const storeListingIds = listingsData.map(
      item => item.author.attributes.profile.metadata.storeId
    );
    const storeListings = await dispatch(
      getStoreListingData(config, { storeListingIds })
    );

    const shippingProfileIds = [
      ...new Set(
        listingsData.map(
          listing => listing.attributes.publicData.shippingProfileId
        )
      ),
    ];
    const shippingProfiles = await getShippingProfiles(shippingProfileIds, sdk);

    dispatch(
      fetchCartDataSuccess({
        listingData: listingDataResponse,
        storeListings,
        shippingProfiles,
        newCartData,
      })
    );
  } catch (e) {
    dispatch(fetchCartDataError(storableError(e)));
  }
};
