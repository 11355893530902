import loadable from '@loadable/component';
import { loadData as ShoppingCartPageLoader } from './containers/ShoppingCartPage/ShoppingCartPage.duck';
import { setInitialValues as ShoppingCartCheckoutPageInitialValues } from './containers/ShoppingCartCheckoutPage/ShoppingCartCheckoutPage.duck';
import {
  loadData as ShoppingCartTransactionPageLoader,
  setInitialValues as ShoppingCartTransactionPageInitialValues,
} from './containers/ShoppingCartTransactionPage/ShoppingCartTransactionPage.duck';

const routes = [
  {
    path: '/shopping-cart',
    name: 'ShoppingCartPage',
    component: loadable(() =>
      import(
        /* webpackChunkName: "ShoppingCartPage" */ './containers/ShoppingCartPage/ShoppingCartPage'
      )
    ),
    loadData: ShoppingCartPageLoader,
  },
  {
    path: '/shopping-cart/checkout',
    name: 'ShoppingCartCheckoutPage',
    component: loadable(() =>
      import(
        /* webpackChunkName: "ShoppingCartCheckoutPage" */ './containers/ShoppingCartCheckoutPage/ShoppingCartCheckoutPage'
      )
    ),
    setInitialValues: ShoppingCartCheckoutPageInitialValues,
  },
  {
    path: '/account/orders/detail/:id',
    name: 'ShoppingCartOrderDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: loadable(() =>
      import(
        /* webpackChunkName: "ShoppingCartTransactionPage" */ './containers/ShoppingCartTransactionPage/ShoppingCartTransactionPage'
      )
    ),
    extraProps: { transactionRole: 'customer' },
    loadData: (params, ...rest) =>
      ShoppingCartTransactionPageLoader(
        { ...params, transactionRole: 'customer' },
        ...rest
      ),
    setInitialValues: ShoppingCartTransactionPageInitialValues,
  },
  {
    path: '/sellers/orders/detail/:id',
    name: 'ShoppingCartSaleDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: loadable(() =>
      import(
        /* webpackChunkName: "ShoppingCartTransactionPage" */ './containers/ShoppingCartTransactionPage/ShoppingCartTransactionPage'
      )
    ),
    extraProps: { transactionRole: 'provider' },
    loadData: ShoppingCartTransactionPageLoader,
  },
];

export default routes;
