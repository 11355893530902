import React, { useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';

import { addItemToCart } from '../../extensions/shoppingCartMultiVendors/containers/ShoppingCartPage/ShoppingCartPage.duck';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  SELLER_TYPE,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  checkUserHasFinishedSelectVariation,
  findProductVariationByKey,
} from '../../util/cart';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  GenericError,
  NamedLink,
  IconSpinner,
  AvatarMedium,
} from '../../components';

import css from './OrderPanel.module.css';
import {
  getRealProductAttributesValueFromAirtable,
  getVariationValues,
} from '../../util/dynamicFieldsHelpers';

const { Money } = sdkTypes;

const BookingTimeForm = loadable(() =>
  import(
    /* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm'
  )
);
const BookingDatesForm = loadable(() =>
  import(
    /* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm'
  )
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(
    /* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm'
  )
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const MAX_ITEMS = 100;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (
  isOwnListing,
  isClosed,
  history,
  location,
  setIsOrderOpen
) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    setIsOrderOpen(true);
    // const { pathname, search, state } = location;
    // const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    // history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  setIsOrderOpen
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () =>
        openOrderModal(
          isOwnListing,
          isClosed,
          history,
          location,
          setIsOrderOpen
        );
};

const dateFormattingOptions = {
  month: 'short',
  day: 'numeric',
  weekday: 'short',
};

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    isShowExaclyPrice = true,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  return (
    <div className={css.priceContainer}>
      <p className={css.price}>
        {/* {!isShowExaclyPrice && (
          <FormattedMessage id="OrderPanel.onlyFromText" />
        )} */}
        {intl.formatMessage(
          { id: 'OrderPanel.price' },
          { price: formatMoney(intl, price) }
        )}
      </p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const Review = ({ stars = 3, rating = 1102, sales = 683 }) => {
  return (
    <div className={css.reviewContainer}>
      <div className={css.starContainer}>
        {[1, 2, 3, 4, 5].map(star => (
          <span key={star} className={star <= stars ? css.active : ''}>
            &#9733;
          </span>
        ))}
      </div>
      <div>
        <span className={css.ratingNumber}>({rating})</span>-{' '}
        <span className={css.sales}>{sales} Sales</span>
      </div>
    </div>
  );
};

const QualityStatement = () => {
  return (
    <div className={css.qualitymessages}>
      <p>
        <strong>100% Happiness Guaranteed!</strong>
      </p>
      <p className={css.qualitymessage}>
        <span className={`${css.icon} material-symbols-outlined`}>lock</span>
        <span>Shop with Confidence</span>
      </p>
      <p className={css.qualitymessage}>
        <span className={`${css.icon} material-symbols-outlined`}>check</span>
        <span>Easy 30 day returns!</span>
      </p>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    productAttributes,
    customLinkProps,
    makerListing,
    fetchMakerListingInProgress,
    fetchMakerListingError,
    productVariationSelected,
    setProductVariationSelected,
  } = props;
  const [isOrderOpen, setIsOrderOpen] = useState(false);

  const { addItemInProgress, addItemError } = useSelector(
    state => state.ShoppingCartPage
  );
  const dispatch = useDispatch();

  const { cartProducts } = useSelector(state => state.ShoppingCartPage);

  const { storeListingInformation, productTaxCode } = useSelector(
    state => state.ListingPage
  );

  const handleAddItemToCart = (values = {}, valuesOption = {}) => {
    const { quantity = 1, ...restValues } = values;
    const { variations = [] } = valuesOption;
    const variationMaybe = isEmpty(restValues)
      ? {}
      : { variationMaybe: { ...restValues } };

    const variationValuesToDisplay = getVariationValues(variations, restValues);

    const { id: listingId } = listing;
    const { stock: defaultProductStock, variationOptions = [] } =
      listing.attributes.publicData || {};

    const selectedStock =
      cartProducts.find(product => {
        const {
          listingId: productListingId,
          variationMaybe = [],
          variationValues,
        } = product;
        if (isEmpty(variationMaybe)) {
          return productListingId === listingId.uuid;
        }

        return (
          productListingId === listingId.uuid &&
          variationValues === variationValuesToDisplay.join(', ')
        );
      })?.quantity || 0;

    const availableStockForVariation = isEmpty(variationMaybe)
      ? defaultProductStock
      : findProductVariationByKey(variationOptions, productVariationSelected)
          ?.stock || 0;

    const restStock = availableStockForVariation - selectedStock;

    if (restStock < quantity) {
      toast(
        <div className={css.toastContent}>
          <span className={`${css.iconWarning} material-symbols-outlined`}>
            production_quantity_limits
          </span>
          <FormattedMessage id="ProductOrderForm.maximumQuantity" />
        </div>,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        }
      );
      return;
    }

    dispatch(
      addItemToCart(listing, cartProducts, {
        ...variationMaybe,
        quantity: +quantity,
        variationValues: variationValuesToDisplay.join(', '),
        storeName: storeListingInformation.attributes.title,
        productTaxCode,
      })
    ).then(response => {
      if (response) {
        toast(
          <div className={css.toastContent}>
            <span
              className={`${css.iconAddedtoCart} material-symbols-outlined`}
            >
              shopping_cart
            </span>
            <FormattedMessage id="ListingPage.addedToCartModalTitle" />
          </div>,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
          }
        );

        if (isOrderOpen) {
          setIsOrderOpen(false);
        }
      }
    });
  };

  const handleChangeProductVariation = values => {
    const { variationOptions = [], variationKeys = [] } =
      listing.attributes.publicData || {};

    const isProductVariationAvailable =
      !isEmpty(variationKeys) && !isEmpty(variationOptions);

    const variationSelectKey = isProductVariationAvailable
      ? variationKeys.map(key => values[key]).join('-')
      : null;

    if (productVariationSelected !== variationSelectKey) {
      setProductVariationSelected(variationSelectKey);
    }
  };

  const publicData = listing?.attributes?.publicData || {};
  const {
    unitType,
    transactionProcessAlias = '',
    variationOptions = [],
    variationKeys = [],
    defaultPrice: defaultProductPrice,
    stock: defaultProductStock,
  } = publicData || {};
  const processName = resolveLatestProcessName(
    transactionProcessAlias.split('/')[0]
  );
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const isShowCheapestPrice = !productVariationSelected;

  const isShowExaclyPrice =
    (defaultProductPrice && isEmpty(variationOptions)) ||
    checkUserHasFinishedSelectVariation(
      variationKeys,
      productVariationSelected
    );

  const price = isShowCheapestPrice
    ? listing?.attributes?.price
    : new Money(
        findProductVariationByKey(variationOptions, productVariationSelected)
          ?.price || defaultProductPrice,
        marketplaceCurrency
      );

  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency =
    isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime =
    isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;

  const isAtLeastProductVariationHasStock = variationOptions.some(
    item => item.stock > 0 && item.isVisible
  );
  const isOutOfStock =
    isPurchase &&
    lineItemUnitType === LINE_ITEM_ITEM &&
    (defaultProductStock === 0 ||
      (!isEmpty(variationOptions) && !isAtLeastProductVariationHasStock));

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase;

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo
    .map(info => info.name)
    .includes(processName);

  const { pickupEnabled, shippingEnabled, ...restValues } =
    listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(
    price,
    marketplaceCurrency,
    intl
  );
  // const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  // const authorDisplayName = userDisplayNameAsString(author, '');

  // const { title: storeName } = storeListingInformation.attributes || {};

  // const storeImage = storeListingInformation.images?.[0];

  const shouldShowAddToCartButton = !isClosed && !isOwnListing;

  const isCartFullCapacity = cartProducts.length === MAX_ITEMS;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const handleSubmitOrderForm = values => {
    const variations = variationKeys.map(variationKey => {
      const rawOptions = restValues[`${variationKey}`];

      return getRealProductAttributesValueFromAirtable({
        keyValues: rawOptions,
        keyName: variationKey,
        productAttributes,
        productType: restValues.product_type,
      });
    });

    const variationOptions =
      listing.attributes?.publicData?.variationOptions || [];
    const selectedVariation = variationOptions.find(
      item => item.key === productVariationSelected
    );

    onSubmit(values, {
      variations,
      variationSKU: selectedVariation?.variationSKU,
    });
  };

  let makerInfo = null;
  if (fetchMakerListingInProgress) {
    makerInfo = <IconSpinner />;
  } else if (makerListing) {
    const makerName = makerListing?.attributes?.title;
    const makerImage = makerListing?.images?.[0];
    const makerListingId = makerListing?.id.uuid ?? '';
    const makerListingLinkProps = {
      name: 'MakerListingPage',
      params: {
        makerListingId,
      },
    };
    const linkProps = makerListingLinkProps;

    makerInfo = (
      <div className={css.author}>
        <AvatarMedium
          customImage={makerImage}
          className={css.providerAvatar}
          customLinkProps={linkProps}
        />
        <span className={css.providerNameLinked}>
          <NamedLink className={css.authorNameLink} {...linkProps}>
            {makerName}
          </NamedLink>
        </span>
        <span className={css.providerNamePlain}>
          <FormattedMessage
            id="OrderPanel.author"
            values={{ name: makerName }}
          />
        </span>
      </div>
    );
  }  

  const displayStoreInfo = storeListingInformation => {
    const {
      title: storeName,
      publicData: { storeName: storeSlug },
    } = storeListingInformation.attributes;
    const storeImage = storeListingInformation.images[0];
    const storeListingLinkProps = {
      name: 'StoreListingPage',
      params: {
        storeSlug,
      },
    };
    return (
      <div className={css.author}>
        <AvatarMedium
          customImage={storeImage}
          className={css.providerAvatar}
          customLinkProps={storeListingLinkProps}
        />
        <span className={css.providerNameLinked}>
          <NamedLink className={css.authorNameLink} {...storeListingLinkProps}>
            {storeName}
          </NamedLink>
        </span>
        <span className={css.providerNamePlain}>
          <FormattedMessage
            id="OrderPanel.author"
            values={{ name: storeName }}
          />
        </span>
      </div>
    );
  }

  const storeInfo = isEmpty(storeListingInformation) ? null : displayStoreInfo(storeListingInformation);

  const { userType = '' } = author?.attributes.profile.metadata || {};

  const isArtisanFacilitator = userType.includes(SELLER_TYPE.ARTISAN_FACILITATOR);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => setIsOrderOpen(false)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          isShowExaclyPrice={isShowExaclyPrice}
        />

        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? (
            titleDesktop
          ) : (
            <H2 className={titleClasses}>{title}</H2>
          )}
          {subTitleText ? (
            <div className={css.orderHelp}>{subTitleText}</div>
          ) : null}
        </div>

        {isArtisanFacilitator ? makerInfo : storeInfo}

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={handleSubmitOrderForm}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            listing={listing}
            shouldShowAddToCartButton={shouldShowAddToCartButton}
            handleAddItemToCart={handleAddItemToCart}
            addItemInProgress={addItemInProgress}
            cartProducts={cartProducts}
            productAttributes={productAttributes}
            onChangeProductVariation={handleChangeProductVariation}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm
            formId="OrderPanelInquiryForm"
            onSubmit={onSubmit}
          />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}

        <QualityStatement />
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div>
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <div className={css.submitButtonFooter}>
            <PrimaryButton
              rootClassName={`${css.addToCartButton} buttonPrimary button button-highlight1 button-animate button-animate-dark`}
              type="button"
              onClick={
                isEmpty(variationOptions)
                  ? () => handleAddItemToCart()
                  : handleSubmit(
                      isOwnListing,
                      isClosed,
                      showInquiryForm,
                      onSubmit,
                      history,
                      location,
                      setIsOrderOpen
                    )
              }
              inProgress={isEmpty(variationOptions) && addItemInProgress}
              disabled={isOutOfStock}
            >
              {isBooking ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </PrimaryButton>
          </div>
        )}
      </div>
      <GenericError
        show={addItemError}
        messageId={
          isCartFullCapacity
            ? 'OrderPanel.cartFullCapacity'
            : 'OrderPanel.addToCartError'
        }
      />
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  productAttributes: [],
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  productAttributes: array,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
