import { storableError } from '../../../../util/errors';
import * as log from '../../../../util/log';
import { denormalisedResponseEntities } from '../../../../util/data';
import { LISTING_TYPES, LISTING_STATE_PUBLISHED } from '../../../common/listing/types';
import pick from 'lodash/pick';
import { createShippingProfileListing } from '../../api';
import { generateId } from '../../common/helpers';

// ================ Action types ================ //
export const FETCH_SHIPPING_PROFILES_REQUEST =
  'app/ManageShippingProfilePage/FETCH_SHIPPING_PROFILES_REQUEST';
export const FETCH_SHIPPING_PROFILES_SUCCESS =
  'app/ManageShippingProfilePage/FETCH_SHIPPING_PROFILES_SUCCESS';
export const FETCH_SHIPPING_PROFILES_ERROR =
  'app/ManageShippingProfilePage/FETCH_SHIPPING_PROFILES_ERROR';

export const DUPLICATE_SHIPPING_PROFILE_REQUEST =
  'app/ManageShippingProfilePage/DUPLICATE_SHIPPING_PROFILE_REQUEST';
export const DUPLICATE_SHIPPING_PROFILE_SUCCESS =
  'app/ManageShippingProfilePage/DUPLICATE_SHIPPING_PROFILE_SUCCESS';
export const DUPLICATE_SHIPPING_PROFILE_ERROR =
  'app/ManageShippingProfilePage/DUPLICATE_SHIPPING_PROFILE_ERROR';

// ================ Reducer ================ //
const initialState = {
  shippingProfiles: [],
  activeListingWithShippingProfiles: {},
  fetchShippingProfilesInProgress: false,
  fetchShippingProfilesError: null,
  duplicateShippingProfileInProgress: false,
  duplicateShippingProfileError: null,
};

const ManageShippingProfilePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SHIPPING_PROFILES_REQUEST:
      return {
        ...state,
        fetchShippingProfilesInProgress: true,
        fetchShippingProfilesError: null,
      };
    case FETCH_SHIPPING_PROFILES_SUCCESS: {
      const {
        shippingProfiles,
        activeListingWithShippingProfiles
      } = payload;
      return {
        ...state,
        shippingProfiles,
        activeListingWithShippingProfiles,
        fetchShippingProfilesInProgress: false,
      };
    }
    case FETCH_SHIPPING_PROFILES_ERROR:
      return {
        ...state,
        fetchShippingProfilesError: payload,
        fetchShippingProfilesInProgress: false,
      };

    case DUPLICATE_SHIPPING_PROFILE_REQUEST:
      return {
        ...state,
        duplicateShippingProfileInProgress: true,
        duplicateShippingProfileError: null,
      };
    case DUPLICATE_SHIPPING_PROFILE_SUCCESS:
      return {
        ...state,
        shippingProfiles: payload,
        duplicateShippingProfileInProgress: false,
      };
    case DUPLICATE_SHIPPING_PROFILE_ERROR:
      return {
        ...state,
        duplicateShippingProfileError: payload,
        duplicateShippingProfileInProgress: false,
      };

    default:
      return state;
  }
};

export default ManageShippingProfilePageReducer;

// ================ Action creators ================ //
export const fetchShippingProfilesRequest = () => ({
  type: FETCH_SHIPPING_PROFILES_REQUEST,
});
export const fetchShippingProfilesSuccess = payload => ({
  type: FETCH_SHIPPING_PROFILES_SUCCESS,
  payload,
});
export const fetchShippingProfilesError = e => ({
  type: FETCH_SHIPPING_PROFILES_ERROR,
  payload: e,
});

export const duplicateShippingProfileRequest = () => ({
  type: DUPLICATE_SHIPPING_PROFILE_REQUEST,
});
export const duplicateShippingProfileSuccess = payload => ({
  type: DUPLICATE_SHIPPING_PROFILE_SUCCESS,
  payload,
});
export const duplicateShippingProfileError = e => ({
  type: DUPLICATE_SHIPPING_PROFILE_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const duplicateShippingProfile = shippingProfile => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(duplicateShippingProfileRequest());

    const { shippingProfiles } = getState().ManageShippingProfilePage;

    const { currentUser } = getState().user;

    const { title, description, publicData, metadata } = pick(
      shippingProfile.attributes,
      ['title', 'description', 'publicData', 'metadata']
    );

    const params = {
      authorId: currentUser.id.uuid,
      state: LISTING_STATE_PUBLISHED,
      title,
      description,
      publicData: {
        ...publicData,
        shippingProfileId: generateId(),
      },
      metadata,
    };
    
    const response = await createShippingProfileListing({ params });

    const newShippingProfile = denormalisedResponseEntities(response)[0];

    dispatch(
      duplicateShippingProfileSuccess([newShippingProfile, ...shippingProfiles])
    );

    return newShippingProfile;
  } catch (e) {
    log.error(e);
    dispatch(duplicateShippingProfileError(storableError(e)));
    throw e;
  }
};

const getAllShippingProfileListings = async (
  sdk,
  currentUserId,
  accumulatedListings = [],
  page = 1
) => {
  const listingResponse = await sdk.listings.query({
    authorId: currentUserId,
    pub_listingType: LISTING_TYPES.SHIPPING_PROFILE_LISTING,
    page,
  });

  const meta = listingResponse.data.meta;
  const { totalPages } = meta;

  const listings = denormalisedResponseEntities(listingResponse);
  const currentResult = accumulatedListings.concat(listings);

  if (totalPages <= page) {
    return currentResult;
  }

  return getAllShippingProfileListings(
    sdk,
    currentUserId,
    currentResult,
    page + 1
  );
};

const queryActiveListingWithShippingProfileId = async (
  sdk,
  shippingProfileId
) => {
  const response = await sdk.listings.query({
    pub_listingType: LISTING_TYPES.PRODUCT_LISTING,
    pub_shippingProfileId: shippingProfileId,
  });

  const { totalItems } = response.data.meta;

  return {
    shippingProfileId,
    totalItems,
  };
};

export const loadData = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchShippingProfilesRequest());
    const {
      currentUser: { id: currentUserId },
    } = getState().user;
    const shippingProfiles = await getAllShippingProfileListings(
      sdk,
      currentUserId
    );
    const shippingProfileIds = shippingProfiles.map(
      item => item.attributes.publicData.shippingProfileId
    );

    const results = await Promise.all(
      shippingProfileIds.map(id =>
        queryActiveListingWithShippingProfileId(sdk, id)
      )
    );

    const activeListingWithShippingProfiles = results.reduce(
      (acc, item) => ({ ...acc, [item.shippingProfileId]: item.totalItems }),
      {}
    );

    dispatch(
      fetchShippingProfilesSuccess({
        shippingProfiles,
        activeListingWithShippingProfiles,
      })
    );
  } catch (e) {
    log.error(e);
    dispatch(fetchShippingProfilesError(storableError(e)));
  }
};
