import { get, post } from '../common/api';

export const queryCartListing = params => {
  return get(`/api/listings/query`, { params });
};

export const validateCoupon = body => {
  return post('/api/v2/coupon/validate', body);
};

export const checkEmailExists = email => {
  return get(`/api/email/check-exist`, { email });
};

export const verifyEmail = () => {
  return get(`/api/email/verify`);
};

export const transactionLineItems = body => {
  return post('/api/cart/calculate-line-items', body);
};

export const initiateTransaction = body => {
  return post('/api/cart/initiate-transaction', body);
};

export const requestTransactionPayment = body => {
  return post('/api/cart/request-transaction-payment', body);
};

export const confirmTransactionPayment = body => {
  return post('/api/cart/confirm-transaction-payment', body);
};

export const initiateChildTransaction = body => {
  return post('/api/cart/initiate-child-transaction', body);
};

export const updateDeliveryInformation = body => {
  return post('/api/cart/transaction/delivery-information', body);
};

export const addTrackingNumberApi = body => {
  return post(
    '/api/cart/transaction/delivery-information/tracking-number',
    body
  );
};

export const requestToCancelOrderPartially = body => {
  return post('/api/cart/transaction/partial-order-cancellation', body);
};

export const getCarrierParcelTemplatesApi = query => {
  return get('/api/cart/transaction/carrier-parcel-templates', query);
};

export const validateShippoShipment = body => {
  return post('/api/cart/transaction/shipments/validation', body, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const createShippoLabel = body => {
  return post('/api/cart/transaction/labels', body);
};

export const createShippoRefundLabel = (transactionId, body) => {
  return post(`/api/shippo/${transactionId}/refund-label`, body);
};

export const retrieveShippoRefundLabels = (transactionId, params) => {
  return get(`/api/shippo/${transactionId}/refund-labels`, params);
};
