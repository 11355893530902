export const SHIPPING_FEE_TYPES = {
  BUYER_PAY_SHIPPING: 'buyerPayShipping',
  FREE_SHIPPING: 'freeShipping',
  DEFAULT_SHIPPING: 'defaultShipping',
};

export const SHIPPING_FEE_URL_TYPES = {
  [SHIPPING_FEE_TYPES.BUYER_PAY_SHIPPING]: 'buyer-pay-shipping',
  [SHIPPING_FEE_TYPES.FREE_SHIPPING]: 'free-shipping',
  [SHIPPING_FEE_TYPES.DEFAULT_SHIPPING]: 'default-shipping',
};

export const MEANSUREMENT_UNITS = [
  { key: 'cm', label: 'cm' },
  { key: 'in', label: 'in' },
  { key: 'mm', label: 'mm' },
];

export const WEIGHT_UNITS = [
  { key: 'kg', label: 'kg' },
  { key: 'g', label: 'g' },
  { key: 'lb', label: 'lb' },
  { key: 'oz', label: 'oz' },
];

const SERVICE_LEVELS_ENUM = {
  USPS_GROUND_ADVANTAGE: 'usps_ground_advantage',
  USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE: 'usps_first_class_package_international_service',
  USPS_PRIORITY: 'usps_priority',
  USPS_PRIORITY_MAIL_INTERNATIONAL: 'usps_priority_mail_international',
  USPS_PRIORITY_EXPRESS: 'usps_priority_express',
  USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL: 'usps_priority_mail_express_international',
};

export const SHIPPING_SERVICES = [
  {
    key: SERVICE_LEVELS_ENUM.USPS_GROUND_ADVANTAGE,
    name: 'Ground Advantage',
    eta: '~5 business days',
  },
  {
    key: SERVICE_LEVELS_ENUM.USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE,
    name: 'First Class Package International',
    eta: '~15 business days',
  },
  {
    key: SERVICE_LEVELS_ENUM.USPS_PRIORITY,
    name: 'Priority Mail',
    eta: '~2 business days',
  },
  {
    key: SERVICE_LEVELS_ENUM.USPS_PRIORITY_MAIL_INTERNATIONAL,
    name: 'Priority Mail International',
    eta: '~8 business days',
  },
  {
    key: SERVICE_LEVELS_ENUM.USPS_PRIORITY_EXPRESS,
    name: 'Priority Mail Express',
    eta: '~1 business days',
  },
  {
    key: SERVICE_LEVELS_ENUM.USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL,
    name: 'Priority Mail Express International',
    eta: '~5 business days',
  }
];

export const CUSTOM_DIMENSION_KEY = 'custom';

export const US_COUNTRY_NAME = 'United States';

export const US_COUNTRY_CODE = 'US';

export const ZIP_CODE_HAWAII = '96701';

export const ZIP_CODE_FLORIDA = '33126';

export const DEFAULT_CARRIER = 'USPS';