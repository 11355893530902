import { addMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import { queryOwnListingByAuthorId } from '../../../../util/api';
import {
  updatedEntities,
  denormalisedEntities,
  getImageVariantInfo,
  denormalisedResponseEntities,
} from '../../../../util/data';
import { storableError } from '../../../../util/errors';
import { createImageVariantConfig } from '../../../../util/sdkLoader';
import { JH_LISTING_TYPES } from '../../../../util/types';
import { parse } from '../../../../util/urlHelpers';

import { syncProductToRebelMouse } from '../../../RebelMouse/api';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST =
  'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS =
  'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR =
  'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST =
  'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS =
  'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST =
  'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS =
  'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const LOAD_SEARCH_LISTING_REQUEST =
  'app/ManageArtisanPage/LOAD_SEARCH_LISTING_REQUEST';
export const LOAD_SEARCH_LISTING_SUCCESS =
  'app/ManageArtisanPage/LOAD_SEARCH_LISTING_SUCCESS';
export const LOAD_SEARCH_LISTING_ERROR =
  'app/ManageArtisanPage/LOAD_SEARCH_LISTING_ERROR';
export const START_SEARCH_LISTING_REQUEST =
  'app/ManageArtisanPage/START_SEARCH_LISTING_REQUEST';

export const FETCH_ADDED_LISTING_REQUEST =
  'app/ManageArtisanPage/FETCH_ADDED_LISTING_REQUEST';
export const FETCH_ADDED_LISTING_SUCCESS =
  'app/ManageArtisanPage/FETCH_ADDED_LISTING_SUCCESS';
export const FETCH_ADDED_LISTING_ERROR =
  'app/ManageArtisanPage/FETCH_ADDED_LISTING_ERROR';

export const UPDATE_ARTISAN_ID_IN_PRODUCTS_REQUEST =
  'app/ManageArtisanPage/UPDATE_ARTISAN_ID_IN_PRODUCTS_REQUEST';
export const UPDATE_ARTISAN_ID_IN_PRODUCTS_SUCCESS =
  'app/ManageArtisanPage/UPDATE_ARTISAN_ID_IN_PRODUCTS_SUCCESS';
export const UPDATE_ARTISAN_ID_IN_PRODUCTS_ERROR =
  'app/ManageArtisanPage/UPDATE_ARTISAN_ID_IN_PRODUCTS_ERROR';
export const CLEAR_RESULT_UPDATE_ARTISAN =
  'app/ManageArtisanPage/CLEAR_RESULT_UPDATE_ARTISAN';

export const CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_REQUEST =
  'app/ManageArtisanPage/CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_REQUEST';
export const CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_SUCCESS =
  'app/ManageArtisanPage/CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_SUCCESS';
export const CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_ERROR =
  'app/ManageArtisanPage/CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_ERROR';

export const SAVE_ARTISAN_ID_FOR_NEW_PRODUCT =
  'app/ManageArtisanPage/SAVE_ARTISAN_ID_FOR_NEW_PRODUCT';
export const CLEAR_ARTISAN_ID_FOR_NEW_PRODUCT =
  'app/ManageArtisanPage/CLEAR_ARTISAN_ID_FOR_NEW_PRODUCT';
// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  searchListings: [],
  searchListingMetaResult: null,
  currentSearchPage: null,
  loadSearchListingInProgress: false,
  loadSearchListingError: null,
  fetchAddedListingInProgress: false,
  fetchAddedListingError: null,
  addedListing: [],
  updateArtisanIdInProductsInprogress: false,
  updateArtisanIdInProductsError: null,
  updateArtisanIdInProductsSuccess: false,
  checkAvailableProductInprogress: false,
  checkAvailableProductError: null,
  availableProductToAdd: false,
  artisanIdForNewProduct: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  if (!state.ownEntities.ownListing) {
    return state;
  }
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = {
    ...oldListing,
    attributes: listingEntity.attributes,
  };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const ManageArtisansPage = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case START_SEARCH_LISTING_REQUEST:
      return { ...state, searchListings: [] };

    case LOAD_SEARCH_LISTING_REQUEST:
      return {
        ...state,
        loadSearchListingInProgress: true,
      };
    case LOAD_SEARCH_LISTING_SUCCESS:
      const { meta } = payload;
      return {
        ...state,
        loadSearchListingInProgress: false,
        searchListings: [...state.searchListings, ...payload.products],
        searchListingMetaResult: meta,
        currentSearchPage: payload.page,
      };
    case LOAD_SEARCH_LISTING_ERROR:
      return {
        ...state,
        loadSearchListingInProgress: false,
        loadSearchListingError: payload,
      };
    case FETCH_ADDED_LISTING_REQUEST:
      return {
        ...state,
        fetchAddedListingInProgress: true,
      };
    case FETCH_ADDED_LISTING_SUCCESS:
      return {
        ...state,
        fetchAddedListingInProgress: false,
        addedListing: payload,
      };
    case FETCH_ADDED_LISTING_ERROR:
      return {
        ...state,
        fetchAddedListingError: payload,
        fetchAddedListingInProgress: false,
      };
    case UPDATE_ARTISAN_ID_IN_PRODUCTS_REQUEST:
      return {
        ...state,
        updateArtisanIdInProductsInprogress: true,
      };
    case UPDATE_ARTISAN_ID_IN_PRODUCTS_SUCCESS:
      return {
        ...state,
        updateArtisanIdInProductsInprogress: false,
        updateArtisanIdInProductsSuccess: true,
      };
    case UPDATE_ARTISAN_ID_IN_PRODUCTS_ERROR:
      return {
        ...state,
        updateArtisanIdInProductsError: payload,
        updateArtisanIdInProductsInprogress: false,
      };
    case CLEAR_RESULT_UPDATE_ARTISAN:
      return {
        ...state,
        updateArtisanIdInProductsInprogress: false,
        updateArtisanIdInProductsSuccess: false,
        updateArtisanIdInProductsError: null,
      };
    case CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_REQUEST:
      return {
        ...state,
        checkAvailableProductInprogress: true,
      };
    case CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_SUCCESS:
      return {
        ...state,
        checkAvailableProductInprogress: false,
        availableProductToAdd: payload,
      };
    case CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_ERROR:
      return {
        ...state,
        checkAvailableProductError: payload,
      };
    case SAVE_ARTISAN_ID_FOR_NEW_PRODUCT:
      return {
        ...state,
        artisanIdForNewProduct: payload,
      };
    case CLEAR_ARTISAN_ID_FOR_NEW_PRODUCT: {
      return {
        ...state,
        artisanIdForNewProduct: null,
      };
    }
    default:
      return state;
  }
};

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});
const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => error => ({
  type: actionType,
  payload: error,
  error: true,
});

export default ManageArtisansPage;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Fetch search listing

export const startSearchListingRequest = requestAction(
  START_SEARCH_LISTING_REQUEST
);

export const loadSearchListingRequest = requestAction(
  LOAD_SEARCH_LISTING_REQUEST
);
export const loadSearchListingSuccess = successAction(
  LOAD_SEARCH_LISTING_SUCCESS
);
export const loadSearchListingError = errorAction(LOAD_SEARCH_LISTING_ERROR);

// Fetch added listing

export const fetchAddedListingRequest = () => ({
  type: FETCH_ADDED_LISTING_REQUEST,
});
export const fetchAddedListingSuccess = e => ({
  type: FETCH_ADDED_LISTING_SUCCESS,
  payload: e,
});
export const fetchAddedListingError = e => ({
  type: FETCH_ADDED_LISTING_ERROR,
  payload: e,
});

// Update Sharetribe artisan id in product

export const updateArtisanIdInProductsRequest = () => ({
  type: UPDATE_ARTISAN_ID_IN_PRODUCTS_REQUEST,
});
export const updateArtisanIdInProductsSuccess = () => ({
  type: UPDATE_ARTISAN_ID_IN_PRODUCTS_SUCCESS,
});
export const updateArtisanIdInProductsError = e => ({
  type: UPDATE_ARTISAN_ID_IN_PRODUCTS_ERROR,
  payload: e,
});

export const clearResultUpdateArtisanIdInProduct = () => ({
  type: CLEAR_RESULT_UPDATE_ARTISAN,
});

// Check available product to add

export const checkAvailableProductRequest = () => ({
  type: CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_REQUEST,
});
export const checkAvailableProductSuccess = e => ({
  type: CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_SUCCESS,
  payload: e,
});
export const checkAvailableProductError = () => ({
  type: CHECK_AVAILABLE_PRODUCT_FOR_ARTISANS_ERROR,
});

// Save SharetribeArtisanId for new product
export const saveArtisanIdForNewProduct = e => ({
  type: SAVE_ARTISAN_ID_FOR_NEW_PRODUCT,
  payload: e,
});

export const clearArtisanIdForNewProduct = () => ({
  type: CLEAR_ARTISAN_ID_FOR_NEW_PRODUCT,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  return sdk.currentUser
    .show()
    .then(res => {
      const authorId = res.data.data.id.uuid;
      return queryOwnListingByAuthorId(authorId)(queryParams);
    })
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const loadSearchListing = params => async (dispatch, getState, sdk) => {
  const { loadSearchListingInProgress } = getState().ManageListingsPage;
  if (loadSearchListingInProgress) return;
  const { startSearch, artisanId, ...restParams } = params;

  if (startSearch) {
    // set searchListings to be []
    dispatch(startSearchListingRequest());
  }

  dispatch(loadSearchListingRequest());

  const { currentUser } = getState().user;
  const imageVariantInfo = getImageVariantInfo({
    variantPrefix: 'listing-card',
  });
  const queryParams = {
    ...restParams,
    authorId: currentUser.id,
    include: ['images'],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
    'limit.images': 1,
    pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
    pub_sharetribeArtisanId: ['null', artisanId],
  };

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const products = denormalisedResponseEntities(response);
      dispatch(
        loadSearchListingSuccess({
          data: {
            products,
            page: params.page,
            meta: response.data.meta,
          },
        })
      );
      return products;
    })
    .catch(e => {
      dispatch(loadSearchListingError(storableError(e)));
      throw e;
    });
};

export const fetchAddedListing = artisanId => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchAddedListingRequest());
  try {
    const { currentUser } = getState().user;
    const imageVariantInfo = getImageVariantInfo({
      variantPrefix: 'listing-card',
    });
    const queryParams = {
      authorId: currentUser.id,
      pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
      pub_sharetribeArtisanId: artisanId,
      include: ['images'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
      'limit.images': 1,
    };

    const res = await sdk.listings.query(queryParams);

    const listings = denormalisedResponseEntities(res);

    dispatch(fetchAddedListingSuccess(listings));
    return listings;
  } catch (error) {
    console.error('Failed to fetch added listings', error);
    dispatch(fetchAddedListingError(error));
  }
};

export const updateArtisanIdInProduct = (listing, artisanId, isRemove) => (
  dispatch,
  getState,
  sdk
) => {
  const params = {
    id: listing.id,
    publicData: {
      sharetribeArtisanId: isRemove ? null : artisanId,
    },
  };
  return sdk.ownListings
    .update(params)
    .then(response => {
      // sync listing to RebelMouse
      if(response.status === 200 && listing.id.uuid) {
        syncProductToRebelMouse(listing.id.uuid, 'artist');
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const updateArtisanIdInProducts = (
  artisanId,
  addedProducts,
  removedProducts
) => (dispatch, getState, sdk) => {
  dispatch(updateArtisanIdInProductsRequest());

  const existArtisanIdInProduct = addedProducts.filter(p => {
    const { sharetribeArtisanId } = p?.attributes.publicData || {};
    return sharetribeArtisanId && sharetribeArtisanId !== artisanId;
  });

  if (existArtisanIdInProduct.length > 0) {
    dispatch(
      updateArtisanIdInProductsError(
        'Some products already have a Sharetribe Artisan ID and cannot be updated.'
      )
    );
    return;
  }
  return Promise.all([
    ...addedProducts.map(product => {
      return dispatch(updateArtisanIdInProduct(product, artisanId, false));
    }),
    ...removedProducts.map(product => {
      return dispatch(updateArtisanIdInProduct(product, artisanId, true));
    }),
  ])
    .then(() => {
      dispatch(updateArtisanIdInProductsSuccess());
      dispatch(checkAvailableProduct());
    })
    .catch(error => {
      console.error(
        'Failed to update a Sharetribe Artisan ID in product',
        error
      );
      dispatch(updateArtisanIdInProductsError(error));
    });
};

export const checkAvailableProduct = () => async (dispatch, getState, sdk) => {
  dispatch(checkAvailableProductRequest());
  try {
    const { currentUser } = getState().user;
    const queryParams = {
      authorId: currentUser.id,
      pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
      pub_sharetribeArtisanId: 'null',
    };
    const listingResponse = await sdk.listings.query(queryParams);

    const listing = denormalisedResponseEntities(listingResponse);
    dispatch(checkAvailableProductSuccess(listing.length !== 0));
  } catch (error) {
    console.error('Error in checkAvailableProduct:', error);
    dispatch(checkAvailableProductError(error));
  }
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([
    dispatch(
      queryOwnListings({
        ...queryParams,
        page,
        pub_listingType: JH_LISTING_TYPES.ARTISAN_LISTING,
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'currentStock', 'author'],
        'fields.image': [
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })
    ),
    dispatch(checkAvailableProduct()),
  ]);
};
