import classNames from 'classnames';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { H3, Modal, PrimaryButton } from '../../../../components';
import useBoolean from '../../../common/hooks/UI/useBoolean';
import { getSellerType } from '../../../common/user/utils';
import { ARTISAN_FACILITATOR } from '../../../common/config/configUser';
import { useSelector, useDispatch } from 'react-redux';
import { agreeArtisanFacilitatorCongratulation } from '../../../../ducks/user.duck';

import css from './ArtisansFacilitatorCongratulationModal.module.css';

const ArtisansFacilitatorCongratulationModal = props => {
  const { rootClassName, className, ...rest } = props;
  const [isCongratulationModalOpen, setIsCongratulationModal] = useBoolean(
    false
  );

  const { currentUser, updateProfileInProgress } = useSelector(
    state => state.user
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const { isArtisanFacilitatorConfirm } =
      currentUser.attributes.profile?.metadata || {};
    const sellerType = getSellerType(currentUser);
    if (sellerType === ARTISAN_FACILITATOR && !isArtisanFacilitatorConfirm) {
      setIsCongratulationModal.setTrue();
    }
  }, [currentUser]);

  const handleSubmitArtisansCongratulation = async () => {
    try {
      await dispatch(agreeArtisanFacilitatorCongratulation());
      setIsCongratulationModal.setFalse();
    } catch (error) {
      // Do nothing and retry the next time the user reloads the page
      setIsCongratulationModal.setFalse();
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      {...rest}
      isOpen={isCongratulationModalOpen}
      onClose={setIsCongratulationModal.setFalse}
      containerClassName={classes}
      usePortal
    >
      <H3 className={css.modalTitle}>
        <FormattedMessage id="ArtisansFacilitatorCongratulationModal.title" />
        <p className={css.role}>
          <FormattedMessage id="ArtisansFacilitatorCongratulationModal.userRole" />
        </p>
      </H3>
      <PrimaryButton
        className={css.button}
        inProgress={updateProfileInProgress}
        onClick={handleSubmitArtisansCongratulation}
      >
        <FormattedMessage id="ArtisansFacilitatorCongratulationModal.agreeButton" />
      </PrimaryButton>
    </Modal>
  );
};

export default ArtisansFacilitatorCongratulationModal;
