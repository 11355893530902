import adminRoutes from './admin/routes';
import ShoppingCartRoutes from './shoppingCartMultiVendors/routes';
import AdminManageUserTypeRoutes from './AdminManageUserType/routes';
import shippingProfileRoutes from './shippingProfiles/routes';
import ManageArtisanPageRoutes from './Artisans/routes';

export default [
  ...adminRoutes,
  ...ShoppingCartRoutes,
  ...AdminManageUserTypeRoutes,
  ...shippingProfileRoutes,
  ...ManageArtisanPageRoutes
];
