const PROCESS_NAME = 'cart-purchase-process';
const PROCESS_ALIAS = `${PROCESS_NAME}/release-1`;
const UNIT_TYPES = ['item'];

const transitions = ({
  CREATE_CART_ORDER: 'transition/create-cart-order',
  CANCEL_CART_ORDER: 'transition/cancel-cart-order',
  EXPIRE_CART_ORDER: 'transition/expire-cart-order',
  REQUEST_PAYMENT: 'transition/request-payment',
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  EXPIRE_PAYMENT: 'transition/expire-payment',
  UPDATE_CHILD_CART_ORDER: 'transition/update-child-cart-order',
});

const states = ({
  INITIAL: 'initial',
  CART_ORDER_CREATED: 'cart-order-created',
  CART_ORDER_EXPIRED: 'cart-order-expired',
  INVALID_CART_ORDER: 'invalid-cart-order',
  PENDING_PAYMENT: 'pending-payment',
  PURCHASED: 'purchased',
  PAYMENT_EXPIRED: 'payment-expired',
  COMPLETED: 'completed',
});

const graph = ({
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.CREATE_CART_ORDER]: states.CART_ORDER_CREATED,
      },
    },
    [states.CART_ORDER_CREATED]: {
      on: {
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.CANCEL_CART_ORDER]: states.INVALID_CART_ORDER,
        [transitions.EXPIRE_CART_ORDER]: states.CART_ORDER_EXPIRED,
      }
    },
    [states.CART_ORDER_EXPIRED]: {},
    [states.INVALID_CART_ORDER]: {},
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
      },
    },
    [states.PAYMENT_EXPIRED]: {},
    [states.PURCHASED]: {
      on: {
        [transitions.UPDATE_CHILD_CART_ORDER]: states.COMPLETED,
      },
    },
    [states.COMPLETED]: {
      type: 'final',
    },
  },
});

const isPrivileged = transition => {
  return [
    transitions.CREATE_CART_ORDER,
    transitions.REQUEST_PAYMENT,
    transitions.CONFIRM_PAYMENT,
  ].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.UPDATE_CHILD_CART_ORDER,
  ];
  return txCompletedTransitions.includes(transition);
};

const isConfirmPayment = transition => {
  const txCompletedTransitions = [transitions.CONFIRM_PAYMENT];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
  ];
  return txRefundedTransitions.includes(transition);
};

const statesNeedingProviderAttention = [];

const isRelevantPastTransition = transition => {
  return [
    transitions.EXPIRE_PAYMENT,
    transitions.UPDATE_CHILD_CART_ORDER,
  ].includes(transition);
};

const isCustomerReview = transition => null;

const excludeTransitions = [
  transitions.EXPIRE_CART_ORDER,
  transitions.CANCEL_CART_ORDER,
  transitions.REQUEST_PAYMENT,
  transitions.CONFIRM_PAYMENT,
  transitions.EXPIRE_PAYMENT,
];

export {
  PROCESS_NAME,
  PROCESS_ALIAS,
  UNIT_TYPES,
  transitions,
  states,
  graph,
  isPrivileged,
  isCompleted,
  isConfirmPayment,
  isRefunded,
  statesNeedingProviderAttention,
  isRelevantPastTransition,
  isCustomerReview,
  excludeTransitions
};