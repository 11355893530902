const SHOPPING_CART_TRANSACTION_TYPES = {
  MAIN: 'SHOPPING_CART_MAIN_TRANSACTION',
  CHILD: 'SHOPPING_CART_CHILD_TRANSACTION'
};

const TRANSITION_CANCEL_ORDER_PARTIALLY = 'transition/cancel-order-partially';
const TRANSITION_CANCEL_BY_ADMIN = 'transition/cancel-by-admin';
const TRANSITION_CANCEL = 'transition/cancel';
const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';
const TRANSITION_MARK_DELIVERED_BY_ADMIN = 'transition/mark-delivered-by-admin';
const TRANSITION_REVIEW_BY_CUSTOMER = 'transition/review-by-customer';

const REFUND_TRANSITION_CUSTOM = 'refund-transition-custom';

const SHIPPING_OPTIONS = {
  SHIP_WITH_SHIPPO: 'SHIP_WITH_SHIPPO',
  DEFAULT_SHIPPING: 'DEFAULT_SHIPPING'
};

const WEIGHT_UNITS = [
  { key: 'kg', label: 'kg' },
  { key: 'g', label: 'g' },
  { key: 'lb', label: 'lb' },
  { key: 'oz', label: 'oz' },
];

const MEANSUREMENT_UNITS = [
  { key: 'cm', label: 'cm' },
  { key: 'in', label: 'in' },
  { key: 'mm', label: 'mm' },
];

const CUSTOM_DIMENSION_KEY = 'custom';

const DEFAULT_CARRIER = 'usps';

const SHIPPO_SERVICE = 'Shippo';

const FREE_SHIPPING_OPTION = 'freeShipping';

const ORDER_STATES = {
  CANCELLED: 'cancelled',
  SHIP_CONFIRMED: 'ship_confirmed',
  REVIEWED: 'reviewed',
};

export {
  SHOPPING_CART_TRANSACTION_TYPES,
  TRANSITION_CANCEL_ORDER_PARTIALLY,
  TRANSITION_CANCEL_BY_ADMIN,
  TRANSITION_CANCEL,
  TRANSITION_MARK_DELIVERED,
  TRANSITION_MARK_DELIVERED_BY_ADMIN,
  TRANSITION_REVIEW_BY_CUSTOMER,
  SHIPPING_OPTIONS,
  WEIGHT_UNITS,
  MEANSUREMENT_UNITS,
  CUSTOM_DIMENSION_KEY,
  DEFAULT_CARRIER,
  SHIPPO_SERVICE,
  FREE_SHIPPING_OPTION,
  REFUND_TRANSITION_CUSTOM,
  ORDER_STATES,
};