import shoppingCartMessages from './shoppingCartMultiVendors/translations/en.json';
import adminManageUserTypeMessages from './AdminManageUserType/translations/en.json';
import shippingProfileMessages from './shippingProfiles/translations/en.json';
import EditListingMessage from './EditListingPage/translations/en.json';
import ManageArtisansMessage from './Artisans/translations/en.json';

export default {
  ...shoppingCartMessages,
  ...adminManageUserTypeMessages,
  ...shippingProfileMessages,
  ...EditListingMessage,
  ...ManageArtisansMessage,
};
