import { io } from 'socket.io-client';
import { SOCKET_NAMESPACES } from './common/types';

const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return process.env.REACT_APP_MARKETPLACE_ROOT_URL;
};

export const userSocket = io(`${apiBaseUrl()}/${SOCKET_NAMESPACES.USERS}`, {
  withCredentials: true,
  autoConnect: false,
});
