import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';

import defaultConfig from '../../../../config/configDefault';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { LISTING_STATE_CLOSED } from '../../../../util/types';
import { findProductVariationByKey } from '../../utils/cart';

const { Money } = sdkTypes;

/**
 * This function will calculate the total price of the cart.
 *
 * @param {array} item
 * @returns {Money} total
 */
export const calcCartTotal = item => {
  if (item.length === 0) return new Money(0, defaultConfig.currency);
  const { currency } = item[0].price;
  const total = item.reduce(
    (acc, elem) => acc + elem.price.amount * elem.quantity,
    0
  );
  return new Money(total, currency);
};

/**
 * This function will check if the product is still valid (instock, available).
 * Otherwise it will mark this product is not valid.
 *
 * @param {array} cartData
 * @returns {array} updatedCartData
 */
export const validateProductInCart = (cartData = []) => {
  return cartData.map(item => {
    const {
      variationMaybe,
      attributes: { publicData },
      quantity,
    } = item;
    const {
      variationOptions = [],
      variationKeys = [],
      stock: productDefaultStock = 0,
    } = publicData;

    const variationSelectKey =
      variationMaybe && !isEmpty(variationKeys)
        ? variationKeys.map(key => variationMaybe[key]).join('-')
        : null;

    const selectedProductVariant = !!variationSelectKey
      ? findProductVariationByKey(variationOptions, variationSelectKey)
      : {};

    const { stock: productVariantStock = 0, variationSKU } =
      selectedProductVariant || {};

    const isOutOfStock = !!variationSelectKey
      ? productVariantStock === 0
      : productDefaultStock === 0;

    const isNotEnoughStock = !!variationSelectKey
      ? productVariantStock > 0 && productVariantStock < quantity
      : productDefaultStock > 0 && productDefaultStock < quantity;

    const isRawProductNotAvailable =
      isEmpty(variationMaybe) &&
      !isEmpty(variationOptions) &&
      !isEmpty(variationKeys);
    const isVariantProductNotAvailable =
      !isEmpty(variationMaybe) &&
      isEmpty(variationOptions) &&
      isEmpty(variationKeys);

    if (isRawProductNotAvailable || isVariantProductNotAvailable) {
      return {
        ...item,
        isProductNotAvailable: true,
        isOutOfStock,
        isNotEnoughStock,
        variationSKU,
      };
    }
    return { ...item, isOutOfStock, isNotEnoughStock, variationSKU };
  });
};

export const groupCartItemByStore = (cartItems = [], stores = []) => {
  return stores
    .map(store => {
      const storeItems = cartItems.filter(
        item => item?.author.id.uuid === store?.author.id.uuid
      );
      if (storeItems.length === 0) return null;
      return { store, storeItems };
    })
    .filter(Boolean);
};

export const getInitialValues = (cartData = []) => {
  return {
    cartSelected: cartData
      .map((item, index) => {
        return item.checked &&
          item.attributes.state !== LISTING_STATE_CLOSED &&
          !item.isOutOfStock &&
          !item.isProductNotAvailable &&
          !item.isNotEnoughStock
          ? `${item.id.uuid}${index}`
          : null;
      })
      .filter(Boolean),
  };
};

export const getCartItemListings = (cartData = []) => {
  return sortBy(
    cartData.map(item =>
      omit(item, [
        'checked',
        'isProductNotAvailable',
        'isOutOfStock',
        'isNotEnoughStock',
      ])
    ),
    'storeName'
  );
};

export const getCartItemDetails = (cartData = []) => {
  return cartData.map(item => {
    const {
      id: { uuid: listingId },
      author: {
        id: { uuid: authorId },
      },
      attributes: {
        title,
        publicData: { shippingProfileId },
      },
      ...otherAttributes
    } = pick(item, [
      'id.uuid',
      'author.id.uuid',
      'quantity',
      'variationMaybe',
      'productTaxCode',
      'variationValues',
      'variationSKU',
      'attributes.publicData.shippingProfileId',
      'attributes.title'
    ]);

    return {
      listingId,
      authorId,
      shippingProfileId,
      title,
      ...otherAttributes,
    };
  });
};
