import { get } from '../common/api';

export const syncProductToRebelMouse = (lisingid, type) => {
  const url = `/api/dataimport/rebelMouseDataSync?listingID=${lisingid}&updatetype=${type}`;

  return get(url);
};

export const syncArtisanToRebelMouse = (artisanid, type) => {
  const url = `/api/dataimport/artisanUpdate?artisanID=${artisanid}&artisanType=${type}`;

  return get(url);
};