import React from 'react';

import classNames from 'classnames';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';

import { NamedLink } from '..';

import css from './AboutNavBar.module.css';

const AboutNavBar = props => {
  const intl = useIntl();
  const { className, rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LinkButton
        name="TheMovementStaticPage"
        id={css['section-movement']}
        text={intl.formatMessage({ id: 'AboutNavBar.theMovement' })}
      />
      <LinkButton
        name="OurCommitmentStaticPage"
        id={css['section-commitment']}
        text={intl.formatMessage({ id: 'AboutNavBar.ourCommitment' })}
      />
      <LinkButton
        name="VillageCouncilStaticPage"
        id={css['section-villagecouncil']}
        text={intl.formatMessage({ id: 'AboutNavBar.villageCouncil' })}
      />
      <LinkButton
        name="OurStoryStaticPage"
        id={css['section-ourstory']}
        text={intl.formatMessage({ id: 'AboutNavBar.ourStory' })}
      />
    </div>
  );
};

function LinkButton({ name, id, text }) {
  return (
    <NamedLink name={name} id={id} className={css.staticLink}>
      {text}
    </NamedLink>
  );
}

AboutNavBar.defaultProps = {
  rootClassName: null,
  className: null,
};

AboutNavBar.propTypes = {
  rootClassName: string,
  className: string,
};

export default AboutNavBar;
