/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedText, ensureCurrentUser } from '../../../util/data';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  IconArrowHead,
  Avatar,
  AvatarLarge,
  Button,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import CategoryBarContainer from '../../../containers/CategoryBarContainer/CategoryBarContainer';
import AboutNavBar from '../../../components/AboutNavBar/AboutNavBar';
import SidebarNav from '../../../components/SidebarNav/SidebarNav';

import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { isAdminUser } from '../../../extensions/AdminManageUserType/common/helpers';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUser,
    notificationCount,
    onLogout,
    handleMobileShipToOpen,
    isSellerPage,
  } = props;

  const intl = useIntl();
  const user = ensureCurrentUser(currentUser);

  const [isShopMenuOpen, setShopMenuOpen] = useState(true);
  const [isAboutMenuOpen, setAboutMenuOpen] = useState(false);
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const [isAccountSettingsOpen, setAccountSettingsOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isUserLoadedOnClient =
    authenticatedOnClientSide && !isEmpty(currentUser);

  const isSellerApproved = get(
    currentUser,
    'attributes.profile.metadata.isApprovedSeller',
    null
  );
  const hasFinishedOnboarding = get(
    currentUser,
    'attributes.profile.metadata.hasFinishedOnboarding',
    null
  );
  const isSellerAgreeWithEthicalPledge = get(
    currentUser,
    'attributes.profile.metadata.isSellerAgreeWithEthicalPledge',
    null
  );

  const toggleShopMenu = () => {
    setShopMenuOpen(!isShopMenuOpen);
  };
  const toggleAboutMenu = () => {
    setAboutMenuOpen(!isAboutMenuOpen);
  };
  const toggleAccountMenu = () => {
    setAccountMenuOpen(!isAccountMenuOpen);
  };
  const toggleAccountSettings = () => {
    setAccountSettingsOpen(!isAccountSettingsOpen);
  };

  const shipToLink = (
    <a onClick={handleMobileShipToOpen} className={css.userpref}>
      <span className={css['userpref-shipto-label']}>Ship to</span>
      <span className={css['userpref-shipto-country-flag']}>
        <img
          src="https://assets3.novica.net/2015/assets/images/flags/flag-US.png"
          width="30"
          height="17"
          className={classNames(css.flag, css['flag-small-us'])}
        />
      </span>
      <span className={css['userpref-currency']}>USD</span>
      <span className={css['userpref-lang']}>EN</span>
    </a>
  );

  const sellerLandingPage = hasFinishedOnboarding
    ? 'SellerDashboardPage'
    : 'SellerOnboardingStartPage';

  const createListingLink = (
    <div className={css.menuNavItem}>
      <NamedLink className={css.menuNavLink} name={sellerLandingPage}>
        <StorefrontIcon />
        <FormattedMessage id="Topbar.manageYourStore" />
      </NamedLink>
    </div>
  );

  const becomeSellerLink = (
    <div className={css.menuNavItem}>
      <NamedLink
        className={classNames(css.menuNavLink, css.becomeASellerLink)}
        name="BecomeASellerStaticPage"
      >
        <StorefrontIcon />
        <FormattedText id="Topbar.becomeASeller" />
      </NamedLink>
    </div>
  );

  const sellerCtaButton = isUserLoadedOnClient
    ? isSellerApproved
      ? createListingLink
      : becomeSellerLink
    : becomeSellerLink;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <span className={css.login}>
          <PersonOutlineOutlinedIcon />
          <FormattedMessage id="Topbar.loginOrSignup" />
        </span>
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.signupOrLogin"
          values={{ signup, login }}
        />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <span className={css.authenticationLinks}>
              {login}
              {!isSellerPage && shipToLink}
            </span>
          </div>
          <div className={css.mobileCategoryBar}>
            <div
              className={classNames(css.shopMenuLinks, {
                [css.open]: isShopMenuOpen,
              })}
            >
              <InlineTextButton
                rootClassName={css.shopByButton}
                onClick={toggleShopMenu}
              >
                <ShoppingBagOutlinedIcon />
                <span>Shop</span>
                <span className={css.arrowButton}>
                  <IconArrowHead
                    direction="right"
                    size="small"
                    className={css.icon}
                  />
                </span>
              </InlineTextButton>
              {isShopMenuOpen && <CategoryBarContainer />}
            </div>
            {sellerCtaButton}
            <div
              className={classNames(css.aboutMenuLinks, {
                [css.open]: isAboutMenuOpen,
              })}
            >
              <InlineTextButton
                rootClassName={css.aboutButton}
                onClick={toggleAboutMenu}
              >
                <InfoOutlinedIcon />
                <span>About</span>
                <span className={css.arrowButton}>
                  <IconArrowHead
                    direction="right"
                    size="small"
                    className={css.icon}
                  />
                </span>
              </InlineTextButton>
              {isAboutMenuOpen && <AboutNavBar className={css.aboutNavBar} />}
            </div>
          </div>
        </div>
        {/*<div className={css.footer}>
          <NamedLink
            className={classNames(css.createNewListingLink, 'button button-highlight1 button-animate button-animate-dark')}
            name="BecomeASellerStaticPage"
          >
            <FormattedText id="Topbar.becomeASeller" />
          </NamedLink>
        </div>*/}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge
        className={css.notificationBadge}
        count={notificationCount}
      />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  const isAdmin = isAdminUser(currentUser);

  const accountMenu = (
    <div className={css.accountNavBar}>
      {isAdmin ? (
        <NamedLink
          className={classNames(
            css.accountNavLink
          )}
          name="AdminManageUserTypePage"
        >
          <FormattedMessage id="TopbarMobileMenu.adminManageUserType" />
        </NamedLink>
      ) : null}
      <div
        className={classNames(css.accountSettingsNav, {
          [css.open]: isAccountSettingsOpen,
        })}
      >
        <InlineTextButton
          rootClassName={css.accountNavButton}
          onClick={toggleAccountSettings}
        >
          <span>Account settings</span>
          <span className={css.arrowButton}>
            <IconArrowHead
              direction="right"
              size="small"
              className={css.icon}
            />
          </span>
        </InlineTextButton>
        {isAccountSettingsOpen && (
          <div className={css.accountSettingsLinks}>
            <NamedLink
              className={classNames(
                css.accountNavLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              About you
            </NamedLink>
            <NamedLink
              className={classNames(
                css.accountNavLink,
                currentPageClass('ContactDetailsPage')
              )}
              name="ContactDetailsPage"
            >
              Contact details
            </NamedLink>
            <NamedLink
              className={classNames(
                css.accountNavLink,
                currentPageClass('PasswordChangePage')
              )}
              name="PasswordChangePage"
            >
              Password
            </NamedLink>
            <NamedLink
              className={classNames(
                css.accountNavLink,
                currentPageClass('PaymentMethodsPage')
              )}
              name="PaymentMethodsPage"
            >
              Payment methods
            </NamedLink>
          </div>
        )}
      </div>
      <NamedLink
        className={classNames(
          css.accountNavLink,
          currentPageClass('InboxPage')
        )}
        name="BuyerOrdersPage"
      >
        <FormattedMessage id="TopbarMobileMenu.inboxLink" />
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.userProfile}>
          <Avatar className={css.avatar} user={currentUser} />
          <span className={css.greetingWrap}>
            <span className={css.greeting}>
              <FormattedMessage
                id="TopbarMobileMenu.greeting"
                values={{ displayName }}
              />
            </span>
            <InlineTextButton
              rootClassName={css.logoutButton}
              onClick={onLogout}
            >
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </span>
          <div className={css.shipToLink}>{shipToLink}</div>
        </div>

        <div className={css.mobileCategoryBar}>
          {!isSellerPage && (
            <>
              <div
                className={classNames(css.shopMenuLinks, {
                  [css.open]: isShopMenuOpen,
                })}
              >
                <InlineTextButton
                  rootClassName={css.shopByButton}
                  onClick={toggleShopMenu}
                >
                  <ShoppingBagOutlinedIcon />
                  <span>Shop</span>
                  <span className={css.arrowButton}>
                    <IconArrowHead
                      direction="right"
                      size="small"
                      className={css.icon}
                    />
                  </span>
                </InlineTextButton>
                {isShopMenuOpen && <CategoryBarContainer />}
              </div>
              <div
                className={classNames(css.accountMenuLinks, {
                  [css.open]: isAccountMenuOpen,
                })}
              >
                <InlineTextButton
                  rootClassName={css.accountButton}
                  onClick={toggleAccountMenu}
                >
                  <PersonOutlineOutlinedIcon />
                  <span>Your account</span>
                  <span className={css.arrowButton}>
                    <IconArrowHead
                      direction="right"
                      size="small"
                      className={css.icon}
                    />
                  </span>
                </InlineTextButton>
                {isAccountMenuOpen && accountMenu}
              </div>
              {sellerCtaButton}
            </>
          )}
          {isSellerApproved && isSellerPage && (
            <>
              <SidebarNav isOnMobile />
              {authenticatedOnClientSide && (
                <div className={css.menuNavItem}>
                  <NamedLink
                    name="ProfileSettingsPage"
                    className={css.menuNavLink}
                  >
                    <PersonOutlineOutlinedIcon />
                    <FormattedMessage id="Topbar.yourAccount" />
                  </NamedLink>
                </div>
              )}
              <div className={css.menuNavItem}>
                <NamedLink name="LandingPage" className={css.menuNavLink}>
                  <ShoppingBagOutlinedIcon />
                  <FormattedMessage id="Topbar.shop" />
                </NamedLink>
              </div>
            </>
          )}
          <div
            className={classNames(css.aboutMenuLinks, {
              [css.open]: isAboutMenuOpen,
            })}
          >
            <InlineTextButton
              rootClassName={css.aboutButton}
              onClick={toggleAboutMenu}
            >
              <InfoOutlinedIcon />
              <span>About</span>
              <span className={css.arrowButton}>
                <IconArrowHead
                  direction="right"
                  size="small"
                  className={css.icon}
                />
              </span>
            </InlineTextButton>
            {isAboutMenuOpen && <AboutNavBar className={css.aboutNavBar} />}
          </div>
        </div>
      </div>
      {/*<div className={css.footer}>
        {isSellerApproved ? (
          <NamedLink
            className={`${css.createNewListingLink} button button-highlight1 button-animate button-animate-dark`}
            name="NewListingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        ) : (
          <NamedLink
            className={`${css.createNewListingLink} button button-highlight1 button-animate button-animate-dark`}
            name="BecomeASellerStaticPage"
          >
            <FormattedText id="Topbar.becomeASeller" />
          </NamedLink>
        )}
      </div>*/}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  isSellerPage: false,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  isSellerPage: bool,
};

export default TopbarMobileMenu;
