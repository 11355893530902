import qs from 'qs';
import { apiBaseUrl, handleResponse, methods, serialize } from './api.helper';

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody
    ? { body: serialize(body) }
    : { body: JSON.stringify(body) };

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };
  return window.fetch(url, fetchOptions).then(handleResponse);
};

export const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

export const get = (path, query) => {
  const requestOptions = {
    method: methods.GET,
  };

  return request(
    `${path}${
      query ? `?${qs.stringify(query, { encodeValuesOnly: true })}` : ''
    }`,
    requestOptions
  );
};