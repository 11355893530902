import { get, post } from '../common/api/internalApi';

export const createShippingProfileListing = body =>
  post('/api/shipping-profile', body);

export const validateAddress = address => get('/api/shipping-profile/addresses/validate', address);

export const getCarrierParcelTemplates = () => get('/api/shipping-profile/carrier-parcel-templates');

export const createShipment = params =>
  post('/api/shipping-profile/estimated-shipping-cost', { params }, {
    headers: { 'Content-Type': 'application/json' },
  });