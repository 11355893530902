export const LISTING_TYPES = {
  USER_LISTING: 'userListing',
  PRODUCT_LISTING: 'productListing',
  STORE_LISTING: 'storeListing',
  MAKER_LISTING: 'makerListing',
  SHIPPING_PROFILE_LISTING: 'shippingProfileListing',
};

export const LISTING_STATE_DRAFT = 'draft';
export const LISTING_STATE_PENDING_APPROVAL = 'pendingApproval';
export const LISTING_STATE_PUBLISHED = 'published';
export const LISTING_STATE_CLOSED = 'closed';