import Decimal from 'decimal.js';
import appSettings from '../../../config/settings';
import { types as sdkTypes, transit } from '../../../util/sdkLoader';

export const apiBaseUrl = (marketplaceRootURL) => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: (v) => new sdkTypes.BigDecimal(v.toString()),
    reader: (v) => new Decimal(v.value),
  },
];

export const serialize = (data) => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

export const deserialize = (str) => {
  return transit.read(str, { typeHandlers });
};

export const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const handleResponse = (res) => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

  if (res.status >= 400) {
    return res.json().then((data) => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  }
  if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};
