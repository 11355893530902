const PROCESS_NAME = 'cart-child-process';
const PROCESS_ALIAS = `${PROCESS_NAME}/release-1`;
const UNIT_TYPES = ['item'];

const transitions = ({
  CREATE_STORE_ORDER: 'transition/create-store-order',
  CANCEL_ORDER_PARTIALLY: 'transition/cancel-order-partially',
  MARK_DELIVERED: 'transition/mark-delivered',
  MARK_DELIVERED_BY_ADMIN: 'transition/mark-delivered-by-admin',
  CANCEL_BY_ADMIN: 'transition/cancel-by-admin',
  CANCEL: 'transition/cancel',
  REVIEW_BY_CUSTOMER: 'transition/review-by-customer',
});

const states = ({
  INITIAL: 'initial',
  STORE_ORDER_CREATED: 'store-order-created',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  REVIEWED: 'reviewed',
});

const graph = ({
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.CREATE_STORE_ORDER]: states.STORE_ORDER_CREATED,
      },
    },
    [states.STORE_ORDER_CREATED]: {
      on: {
        [transitions.CANCEL_ORDER_PARTIALLY]: states.STORE_ORDER_CREATED,
        [transitions.MARK_DELIVERED]: states.DELIVERED,
        [transitions.MARK_DELIVERED_BY_ADMIN]: states.DELIVERED,
        [transitions.CANCEL_BY_ADMIN]: states.CANCELED,
        [transitions.CANCEL]: states.CANCELED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.REVIEW_BY_CUSTOMER]: states.REVIEWED,
      },
    },
    [states.CANCELED]: {},
    [states.REVIEWED]: {
      type: 'final',
    },
  },
});

const isPrivileged = transition => {
  return [
    transitions.CREATE_STORE_ORDER,
  ].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.MARK_DELIVERED,
    transitions.MARK_DELIVERED_BY_ADMIN,
    transitions.REVIEW_BY_CUSTOMER,
  ];
  return txCompletedTransitions.includes(transition);
};


// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.CANCEL,
    transitions.CANCEL_BY_ADMIN,
  ];
  return txRefundedTransitions.includes(transition);
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
const isRelevantPastTransition = transition => {
  return [
    transitions.CREATE_STORE_ORDER,
    transitions.CANCEL_ORDER_PARTIALLY,
    transitions.CANCEL_BY_ADMIN,
    transitions.CANCEL,
    transitions.MARK_DELIVERED,
    transitions.MARK_DELIVERED_BY_ADMIN,
  ].includes(transition);
};

const isCustomerReview = transition => {
  return [
    transitions.REVIEW_BY_CUSTOMER,
  ].includes(transition);
};

const statesNeedingProviderAttention = [states.STORE_ORDER_CREATED];

export {
  PROCESS_NAME,
  PROCESS_ALIAS,
  UNIT_TYPES,
  graph,
  isPrivileged,
  isCompleted,
  isRefunded,
  transitions,
  states,
  statesNeedingProviderAttention,
  isRelevantPastTransition,
  isCustomerReview,
};