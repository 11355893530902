import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import {
  types as sdkTypes,
  createImageVariantConfig,
} from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getTaxCodeByProductType,
  queryOwnListingByAuthorId,
  queryProductReviews,
  transactionLineItems,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  getProcess,
  isBookingProcessAlias,
} from '../../transactions/transaction';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
} from '../../ducks/user.duck';
import {
  JH_LISTING_TYPES,
  NOT_FOUND_ERROR_CODE,
  PRODUCT_NOT_FOUND,
} from '../../util/types';
import { listRecords } from '../../util/airtableHelpers/list';
import { PRODUCT } from '../../config/constants/listing.constants';
import { pushViewProductDataToDataLayer } from '../../util/dataLayer';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST =
  'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS =
  'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST =
  'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
  'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_PRODUCT_ATTRIBUTES_SUCCESS =
  'app/ListingPage/FETCH_PRODUCT_ATTRIBUTES_SUCCESS';

export const FETCH_STORE_LISTING_SUCCESS =
  'app/ListingPage/FETCH_STORE_LISTING_SUCCESS';

export const FETCH_TAX_CODE_REQUEST = 'app/ListingPage/FETCH_TAX_CODE_REQUEST';
export const FETCH_TAX_CODE_SUCCESS = 'app/ListingPage/FETCH_TAX_CODE_SUCCESS';
export const FETCH_TAX_CODE_ERROR = 'app/ListingPage/FETCH_TAX_CODE_ERROR';

export const FETCH_MAKER_LISTING_REQUEST =
  'app/ListingPage/FETCH_MAKER_LISTING_REQUEST';
export const FETCH_MAKER_LISTING_SUCCESS =
  'app/ListingPage/FETCH_MAKER_LISTING_SUCCESS';
export const FETCH_MAKER_LISTING_ERROR =
  'app/ListingPage/FETCH_MAKER_LISTING_ERROR';
export const SET_PRODUCT_ID = 'app/ListingPage/SET_PRODUCT_ID';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  fetchReviewsInprogress: false,
  offset: null,
  productID: null,

  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  productAttributes: [],
  storeListingInformation: {},
  productTaxCode: null,
  productTaxCodeError: null,
  makerListing: null,
  fetchMakerListingError: null,
  fetchMakerListingInProgress: true,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        id: null,
        showListingError: null,
        productAttributes: [],
        storeListingInformation: {},
      };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        id: payload.listingId,
        showListingError: null,
      };
    case SHOW_LISTING_ERROR:
      return {
        ...state,
        id: null,
        showListingError: payload,
        productAttributes: [],
        storeListingInformation: {},
      };

    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchReviewsInProgress: true,
        fetchReviewsError: null,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        fetchReviewsInProgress: false,
        reviews: uniqBy([...state.reviews, ...payload.reviews], 'recordId'),
        offset: payload.offset,
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        fetchReviewsInProgress: false,
        fetchReviewsError: payload,
      };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return {
        ...state,
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null,
      };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        fetchLineItemsError: payload,
      };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return {
        ...state,
        sendInquiryInProgress: false,
        sendInquiryError: payload,
      };

    case FETCH_PRODUCT_ATTRIBUTES_SUCCESS:
      const { productAttributes } = payload;
      return { ...state, productAttributes };

    case FETCH_STORE_LISTING_SUCCESS:
      return { ...state, storeListingInformation: payload };

    case FETCH_TAX_CODE_REQUEST:
      return { ...state, productTaxCode: null };
    case FETCH_TAX_CODE_SUCCESS:
      return { ...state, productTaxCode: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, productTaxCodeError: payload };

    case FETCH_MAKER_LISTING_REQUEST:
      return {
        ...state,
        fetchMakerListingError: null,
        fetchMakerListingInProgress: true,
      };
    case FETCH_MAKER_LISTING_SUCCESS:
      return {
        ...state,
        makerListing: payload,
        fetchMakerListingInProgress: false,
      };
    case FETCH_MAKER_LISTING_ERROR:
      return {
        ...state,
        makerListing: null,
        fetchMakerListingError: payload,
        fetchMakerListingInProgress: false,
      };
    case SET_PRODUCT_ID:
      return {
        ...state,
        productID: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST,
});
export const showListingSuccess = payload => ({
  type: SHOW_LISTING_SUCCESS,
  payload,
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = data => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: data,
});
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({
  type: SEND_INQUIRY_ERROR,
  error: true,
  payload: e,
});

export const fetchProductAttributesSuccess = payload => ({
  type: FETCH_PRODUCT_ATTRIBUTES_SUCCESS,
  payload,
});

export const fetchStoreListingSuccess = payload => ({
  type: FETCH_STORE_LISTING_SUCCESS,
  payload,
});

export const fetchTaxCodeRequest = () => ({ type: FETCH_TAX_CODE_REQUEST });
export const fetchTaxCodeSuccess = payload => ({
  type: FETCH_TAX_CODE_SUCCESS,
  payload,
});
export const fetchTaxCodeError = e => ({
  type: FETCH_TAX_CODE_ERROR,
  error: true,
  payload: e,
});

export const fetchMakerListingRequest = () => ({
  type: FETCH_MAKER_LISTING_REQUEST,
});
export const fetchMakerListingSuccess = payload => ({
  type: FETCH_MAKER_LISTING_SUCCESS,
  payload,
});
export const fetchMakerListingError = e => ({
  type: FETCH_MAKER_LISTING_ERROR,
  error: true,
  payload: e,
});

export const setProductID = productId => ({
  type: SET_PRODUCT_ID,
  payload: productId,
});

// ================ Thunks ================ //

export const showListing = ({
  config,
  isOwn = false,
  productSlug,
  listingId,
  currentUserId,
  flag = 0,
}) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest());
  const params = {
    ids: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn
    ? queryOwnListingByAuthorId(currentUserId)
    : sdk.listings.query;

  return show(params)
    .then(response => {
      const listingsData = denormalisedResponseEntities(response);

      const currentListing = isEmpty(productSlug)
        ? listingsData[0]
        : listingsData.find(
            listing => listing.attributes.publicData.productName === productSlug
          );

      if (!currentListing && flag < 5) {
        return dispatch(
          showListing({
            config,
            isOwn,
            productSlug,
            listingId,
            currentUserId,
            flag: ++flag,
          })
        );
      }

      if (!currentListing) {
        const error = new Error(PRODUCT_NOT_FOUND);
        error.status = NOT_FOUND_ERROR_CODE;
        throw error;
      }

      if (currentListing) {
        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };
        const filterResponse = response?.data?.data.filter(
          l => l.id.uuid === currentListing.id.uuid
        );
        response.data.data = filterResponse;
        dispatch(showListingSuccess({ listingId: currentListing.id.uuid }));
        pushViewProductDataToDataLayer(currentListing);
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
      }
      return currentListing;
    })
    .then(async currentListing => {
      if (!currentListing) {
        return currentListing;
      }

      const storeListingId =
        currentListing.author.attributes.profile.metadata.storeId;

      if (
        currentListing.attributes.publicData?.listingType ===
        JH_LISTING_TYPES.PRODUCT_LISTING
      ) {
        await Promise.all([
          dispatch(fetchProductAttributesList(config)),
          dispatch(fetchStoreListing({ listingId: storeListingId })),
          dispatch(fetchMakerListing(currentListing)),
          dispatch(
            fetchTaxCode(currentListing.attributes.publicData?.product_type)
          ),
        ]);
      }
      return currentListing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchProductAttributesList = config => (
  dispatch,
  getState,
  sdk
) => {
  return listRecords().then(response => {
    if (response) {
      dispatch(
        fetchProductAttributesSuccess({
          productAttributes: response.filter(record => record?.depend_on),
        })
      );
    }
  });
};

export const fetchStoreListing = ({ listingId }) => (
  dispatch,
  getState,
  sdk
) => {
  const params = {
    id: listingId,
    include: ['images'],
    'fields.image': [
      // Avatars
      'variants.square-small',
    ],
  };

  return sdk.listings.show(params).then(data => {
    const listingEntities = denormalisedResponseEntities(data)[0];
    dispatch(fetchStoreListingSuccess(listingEntities));
  });
};

export const fetchTaxCode = productType => (dispatch, getState, sdk) => {
  dispatch(fetchTaxCodeRequest());
  return getTaxCodeByProductType(productType)
    .then(response => {
      const { taxCode } = response;
      dispatch(fetchTaxCodeSuccess(taxCode));
    })
    .catch(e => {
      dispatch(fetchTaxCodeError(storableError(e)));
    });
};

export const fetchReviews = () => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  const { productID, offset } = getState().ListingPage;

  const params = {
    productID,
    offset,
    type: PRODUCT,
  };
  try {
    // Note: Currently, response will be null on server side rendering
    // So until we update the GET function,
    // these data will only be fetched successfully on the client side
    const response = await queryProductReviews(params);

    const {
      data: { reviews = [], authorsDataResponse } = {},
      meta: { offset = null } = {},
    } = response ?? {};

    if (authorsDataResponse) {
      dispatch(addMarketplaceEntities(authorsDataResponse));
    }

    dispatch(fetchReviewsSuccess({ reviews, offset }));
  } catch (error) {
    log.error(error, 'fetching-reviews-failed');
    dispatch(fetchReviewsError(storableError(error)));
  }
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (
  dispatch,
  getState,
  sdk
) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes &&
    attributes.availabilityPlan &&
    attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({
  orderData,
  listingId,
  isOwnListing,
}) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

const fetchMakerListing = productListing => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchMakerListingRequest());
    if (!productListing) {
      dispatch(
        fetchMakerListingError(
          storableError(new Error('Product listing is not provided'))
        )
      );
      return Promise.resolve(null);
    }
    const { sharetribeArtisanId = '' } =
      productListing?.attributes.publicData || {};
    if (!sharetribeArtisanId) {
      dispatch(
        fetchMakerListingError(
          storableError(new Error('Maker ID is not provided'))
        )
      );
      return Promise.resolve(null);
    }
    const params = {
      id: new UUID(sharetribeArtisanId),
      include: ['images'],
      'fields.image': [
        // Avatars
        'variants.square-small',
      ],
    };

    const res = await sdk.listings.show(params);
    const [makerListing] = denormalisedResponseEntities(res);
    dispatch(fetchMakerListingSuccess(makerListing));
  } catch (e) {
    dispatch(fetchMakerListingError(storableError(e)));
  }
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  const listingId = params.id;
  const { productSlug } = params;

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  ];

  return dispatch(fetchCurrentUser()).then(res => {
    if (ownListingVariants.includes(params.variant)) {
      const authorId = res.id.uuid;
      return dispatch(
        showListing({
          config,
          isOwn: true,
          productSlug,
          listingId,
          currentUserId: authorId,
        })
      );
    }

    return dispatch(showListing({ config, productSlug, listingId })).then(
      listing => {
        if (!listing) {
          return null;
        }
        const productID = listing.id.uuid;

        if (productID) {
          dispatch(setProductID(productID));
          dispatch(fetchReviews());
        }

        const transactionProcessAlias =
          listing?.attributes?.publicData?.transactionProcessAlias || '';
        if (isBookingProcessAlias(transactionProcessAlias)) {
          // Fetch timeSlots.
          // This can happen parallel to loadData.
          // We are not interested to return them from loadData call.
          fetchMonthlyTimeSlots(dispatch, listing);
        }
        return listing;
      }
    );
  });
};
