import loadable from '@loadable/component';
import { loadData as ManageShippingProfilePageLoader } from './containers/ManageShippingProfilePage/ManageShippingProfilePage.duck';
import { loadData as EditShippingProfilePageLoader } from './containers/EditShippingProfilePage/EditShippingProfilePage.duck';
import { SELLER } from '../common/config/configUser';
import { NamedRedirect } from '../../components';

const draftId = '00000000-0000-0000-0000-000000000000';

const ManageShippingProfilePage = loadable(() =>
  import(
    /* webpackChunkName: 'ManageShippingProfilePage' */ './containers/ManageShippingProfilePage/ManageShippingProfilePage'
  )
);

const EditShippingProfilePage = loadable(() =>
  import(
    /* webpackChunkName: 'EditShippingProfilePage' */ './containers/EditShippingProfilePage/EditShippingProfilePage'
  )
);

const routes = [
  {
    path: '/sellers/shipping-profiles',
    name: 'ManageShippingProfilePage',
    auth: true,
    authorizeFor: [SELLER],
    component: ManageShippingProfilePage,
    loadData: ManageShippingProfilePageLoader,
  },
  {
    path: '/sellers/shipping-profiles/:shippingFeeType/new',
    name: 'NewShippingProfilePage',
    auth: true,
    component: props => {
      const { shippingFeeType } = props.params;
      return (
        <NamedRedirect
          name="EditShippingProfilePage"
          params={{ shippingFeeType, id: draftId, type: 'new' }}
        />
      );
    },
  },
  {
    path: '/sellers/shipping-profiles/:shippingFeeType/:id/:type',
    name: 'EditShippingProfilePage',
    auth: true,
    authorizeFor: [SELLER],
    component: EditShippingProfilePage,
    loadData: EditShippingProfilePageLoader,
  },
];

export default routes;