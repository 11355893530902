import { storableError } from '../../../../util/errors';
import * as log from '../../../../util/log';
import {
  types as sdkTypes,
} from '../../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../../util/data';
import { LISTING_TYPES, LISTING_STATE_PUBLISHED } from '../../../common/listing/types';
import {
  createShipment,
  createShippingProfileListing,
  getCarrierParcelTemplates,
} from '../../api';
import { generateId } from '../../common/helpers';
import { LISTING_PAGE_PARAM_TYPE_NEW } from '../../../../util/urlHelpers';
import { DEFAULT_CARRIER } from '../../common/types';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 5;

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/EditShippingProfilePage/SET_INITIAL_VALUES';

export const SHOW_SHIPPING_PROFILE_REQUEST =
  'app/EditShippingProfilePage/SHOW_SHIPPING_PROFILE_REQUEST';
export const SHOW_SHIPPING_PROFILE_SUCCESS =
  'app/EditShippingProfilePage/SHOW_SHIPPING_PROFILE_SUCCESS';
export const SHOW_SHIPPING_PROFILE_ERROR = 'app/EditShippingProfilePage/SHOW_SHIPPING_PROFILE_ERROR';

export const FETCH_STORE_LISTING_REQUEST =
  'app/EditShippingProfilePage/FETCH_STORE_LISTING_REQUEST';
export const FETCH_STORE_LISTING_SUCCESS =
  'app/EditShippingProfilePage/FETCH_STORE_LISTING_SUCCESS';
export const FETCH_STORE_LISTING_ERROR =
  'app/EditShippingProfilePage/FETCH_STORE_LISTING_ERROR';

export const FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_REQUEST =
  'app/EditShippingProfilePage/FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_REQUEST';
export const FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_SUCCESS =
  'app/EditShippingProfilePage/FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_SUCCESS';
export const FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_ERROR =
  'app/EditShippingProfilePage/FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_ERROR';

export const FETCH_CARRIER_PARCEL_TEMPLATES_REQUEST =
  'app/EditShippingProfilePage/FETCH_CARRIER_PARCEL_TEMPLATES_REQUEST';
export const FETCH_CARRIER_PARCEL_TEMPLATES_SUCCESS =
  'app/EditShippingProfilePage/FETCH_CARRIER_PARCEL_TEMPLATES_SUCCESS';
export const FETCH_CARRIER_PARCEL_TEMPLATES_ERROR =
  'app/EditShippingProfilePage/FETCH_CARRIER_PARCEL_TEMPLATES_ERROR';

export const QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_REQUEST =
  'app/EditShippingProfilePage/QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_REQUEST';
export const QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_SUCCESS =
  'app/EditShippingProfilePage/QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_SUCCESS';
export const QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_ERROR =
  'app/EditShippingProfilePage/QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_ERROR';

export const FETCH_MORE_APPLIED_LISTINGS = 'app/EditShippingProfilePage/FETCH_MORE_APPLIED_LISTINGS';
export const FETCH_MORE_APPLIED_LISTINGS_SUCCESS =
  'app/EditShippingProfilePage/FETCH_MORE_APPLIED_LISTINGS_SUCCESS';
export const FETCH_MORE_APPLIED_LISTINGS_ERROR =
  'app/EditShippingProfilePage/FETCH_MORE_APPLIED_LISTINGS_ERROR';

export const CREATE_SHIPPING_PROFILE_LISTING_REQUEST =
  'app/EditShippingProfilePage/CREATE_SHIPPING_PROFILE_LISTING_REQUEST';
export const CREATE_SHIPPING_PROFILE_LISTING_SUCCESS =
  'app/EditShippingProfilePage/CREATE_SHIPPING_PROFILE_LISTING_SUCCESS';
export const CREATE_SHIPPING_PROFILE_LISTING_ERROR =
  'app/EditShippingProfilePage/CREATE_SHIPPING_PROFILE_LISTING_ERROR';

export const UPDATE_SHIPPING_PROFILE_LISTING_REQUEST =
  'app/EditShippingProfilePage/UPDATE_SHIPPING_PROFILE_LISTING_REQUEST';
export const UPDATE_SHIPPING_PROFILE_LISTING_SUCCESS =
  'app/EditShippingProfilePage/UPDATE_SHIPPING_PROFILE_LISTING_SUCCESS';
export const UPDATE_SHIPPING_PROFILE_LISTING_ERROR =
  'app/EditShippingProfilePage/UPDATE_SHIPPING_PROFILE_LISTING_ERROR';

export const FETCH_SHIPPING_PROFILES_REQUEST =
  'app/EditShippingProfilePage/FETCH_SHIPPING_PROFILES_REQUEST';
export const FETCH_SHIPPING_PROFILES_SUCCESS =
  'app/EditShippingProfilePage/FETCH_SHIPPING_PROFILES_SUCCESS';
export const FETCH_SHIPPING_PROFILES_ERROR =
  'app/EditShippingProfilePage/FETCH_SHIPPING_PROFILES_ERROR';

export const EDIT_PRODUCT_DETAILS_REQUEST =
  'app/EditShippingProfilePage/EDIT_PRODUCT_DETAILS_REQUEST';
export const EDIT_PRODUCT_DETAILS_SUCCESS =
  'app/EditShippingProfilePage/EDIT_PRODUCT_DETAILS_SUCCESS';
export const EDIT_PRODUCT_DETAILS_ERROR =
  'app/EditShippingProfilePage/EDIT_PRODUCT_DETAILS_ERROR';

export const ESTIMATE_SHIPPING_COST_REQUEST =
  'app/EditShippingProfilePage/ESTIMATE_SHIPPING_COST_REQUEST';
export const ESTIMATE_SHIPPING_COST_SUCCESS =
  'app/EditShippingProfilePage/ESTIMATE_SHIPPING_COST_SUCCESS';
export const ESTIMATE_SHIPPING_COST_ERROR =
  'app/EditShippingProfilePage/ESTIMATE_SHIPPING_COST_ERROR';

// ================ Reducer ================ //
const initialState = {
  from: null,
  shippingProfileToDuplicate: null,
  shippingProfile: null,
  appliedListings: [],
  appliedListingsPagination: null,
  fetchAppliedListingsInProgress: false,
  fetchAppliedListingsError: null,
  showShippingProfileInProgress: false,
  showShippingProfileError: null,
  storeListing: null,
  fetchStoreListingInProgress: false,
  fetchStoreListingError: null,
  listingWithoutShippingProfile: [],
  listingWithoutShippingProfilePagination: null,
  fetchListingWithoutShippingProfileInProgress: false,
  fetchListingWithoutShippingProfileError: null,
  carrierParcelTemplates: [],
  fetchCarrierParcelTemplatesInProgress: false,
  fetchCarrierParcelTemplatesError: null,
  queryProductsWithoutShippingProfileInProgress: false,
  queryProductsWithoutShippingProfileError: null,
  createShippingProfileListingInProgress: false,
  createShippingProfileListingError: null,
  updateShippingProfileListingInProgress: false,
  updateShippingProfileListingError: null,
  shippingProfiles: [],
  fetchShippingProfilesInProgress: false,
  fetchShippingProfilesError: null,
  editProductDetailsInProgress: false,
  editProductDetailsError: null,
  estimateShippingCost: null,
  uspsErrorMessages: [],
  shippingServiceOptions: [],
  estimateShippingCostInProgress: false,
  estimateShippingCostError: null,
};

const EditShippingProfilePageReducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return {
        ...initialState,
        ...payload,
      };

    case SHOW_SHIPPING_PROFILE_REQUEST:
      return {
        ...state,
        showShippingProfileInProgress: true,
        showShippingProfileError: null,
      };
    case SHOW_SHIPPING_PROFILE_SUCCESS: {
      const {
        shippingProfile,
        appliedListings,
        appliedListingsPagination,
      } = payload;
      return {
        ...state,
        showShippingProfileInProgress: false,
        shippingProfile,
        appliedListings,
        appliedListingsPagination,
      };
    }
    case SHOW_SHIPPING_PROFILE_ERROR:
      return {
        ...state,
        showShippingProfileInProgress: false,
        showShippingProfileError: payload,
      };

    case FETCH_STORE_LISTING_REQUEST:
      return {
        ...state,
        fetchStoreListingInProgress: true,
        fetchStoreListingError: null,
      };
    case FETCH_STORE_LISTING_SUCCESS:
      return {
        ...state,
        fetchStoreListingInProgress: false,
        storeListing: payload,
      };
    case FETCH_STORE_LISTING_ERROR:
      return {
        ...state,
        fetchStoreListingInProgress: false,
        fetchStoreListingError: payload,
      };

    case FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_REQUEST:
      return {
        ...state,
        fetchListingWithoutShippingProfileInProgress: true,
        fetchListingWithoutShippingProfileError: null,
      };
    case FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_SUCCESS: {
      const {
        listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination,
      } = payload;
      return {
        ...state,
        fetchListingWithoutShippingProfileInProgress: false,
        listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination,
      };
    }
    case FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_ERROR:
      return {
        ...state,
        fetchListingWithoutShippingProfileInProgress: false,
        fetchListingWithoutShippingProfileError: payload,
      };

    case FETCH_CARRIER_PARCEL_TEMPLATES_REQUEST:
      return {
        ...state,
        fetchCarrierParcelTemplatesInProgress: true,
        fetchCarrierParcelTemplatesError: null,
      };
    case FETCH_CARRIER_PARCEL_TEMPLATES_SUCCESS:
      return {
        ...state,
        fetchCarrierParcelTemplatesInProgress: false,
        carrierParcelTemplates: payload,
      };
    case FETCH_CARRIER_PARCEL_TEMPLATES_ERROR:
      return {
        ...state,
        fetchCarrierParcelTemplatesInProgress: false,
        fetchCarrierParcelTemplatesError: payload,
      };

    case QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_REQUEST:
      return {
        ...state,
        queryProductsWithoutShippingProfileInProgress: true,
        queryProductsWithoutShippingProfileError: null,
      };
    case QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_SUCCESS: {
      const {
        listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination,
      } = payload;

      return {
        ...state,
        queryProductsWithoutShippingProfileInProgress: false,
        listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination,
      };
    }
    case QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_ERROR:
      return {
        ...state,
        queryProductsWithoutShippingProfileInProgress: false,
        queryProductsWithoutShippingProfileError: payload,
      };

    case FETCH_MORE_APPLIED_LISTINGS:
      return {
        ...state,
        fetchAppliedListingsInProgress: true,
        fetchAppliedListingsError: null
      };
    case FETCH_MORE_APPLIED_LISTINGS_SUCCESS: {
      const {
        appliedListings,
        appliedListingsPagination,
      } = payload;

      return {
        ...state,
        fetchAppliedListingsInProgress: false,
        appliedListings,
        appliedListingsPagination,
      };
    }
    case FETCH_MORE_APPLIED_LISTINGS_ERROR:
      return {
        ...state,
        fetchAppliedListingsInProgress: false,
        fetchAppliedListingsError: payload,
      };

    case CREATE_SHIPPING_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        createShippingProfileListingInProgress: true,
        createShippingProfileListingError: null,
      };
    case CREATE_SHIPPING_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        createShippingProfileListingInProgress: false,
        shippingProfileToDuplicate: null,
      };
    case CREATE_SHIPPING_PROFILE_LISTING_ERROR:
      return {
        ...state,
        createShippingProfileListingInProgress: false,
        createShippingProfileListingError: payload,
      };

    case UPDATE_SHIPPING_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        updateShippingProfileListingInProgress: true,
        updateShippingProfileListingError: null,
      };
    case UPDATE_SHIPPING_PROFILE_LISTING_SUCCESS: {
      const {
        shippingProfile,
        appliedListings,
        appliedListingsPagination,
      } = payload;

      return {
        ...state,
        shippingProfileToDuplicate: null,
        updateShippingProfileListingInProgress: false,
        shippingProfile,
        appliedListings,
        appliedListingsPagination,
      };
    }
    case UPDATE_SHIPPING_PROFILE_LISTING_ERROR:
      return {
        ...state,
        updateShippingProfileListingInProgress: false,
        updateShippingProfileListingError: payload,
      };

    case FETCH_SHIPPING_PROFILES_REQUEST:
      return {
        ...state,
        fetchShippingProfilesInProgress: true,
        fetchShippingProfilesError: null,
      };
    case FETCH_SHIPPING_PROFILES_SUCCESS:
      return {
        ...state,
        shippingProfiles: payload,
        fetchShippingProfilesInProgress: false,
      };
    case FETCH_SHIPPING_PROFILES_ERROR:
      return {
        ...state,
        fetchShippingProfilesError: payload,
        fetchShippingProfilesInProgress: false,
      };

    case EDIT_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        editProductDetailsInProgress: true,
        editProductDetailsError: null,
      };
    case EDIT_PRODUCT_DETAILS_SUCCESS: {
      const {
        appliedListings,
        appliedListingsPagination,
      } = payload;

      return {
        ...state,
        editProductDetailsInProgress: false,
        appliedListings,
        appliedListingsPagination,
      };
    }
    case EDIT_PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        editProductDetailsInProgress: false,
        editProductDetailsError: payload,
      };

    case ESTIMATE_SHIPPING_COST_REQUEST:
      return {
        ...state,
        estimateShippingCostInProgress: true,
        estimateShippingCostError: null,
      };
    case ESTIMATE_SHIPPING_COST_SUCCESS: {
      const {
        maximumShippingCost,
        uspsErrorMessages,
        shippingServiceOptions,
      } = payload;

      return {
        ...state,
        estimateShippingCostInProgress: false,
        estimateShippingCost: maximumShippingCost,
        uspsErrorMessages,
        shippingServiceOptions,
      };
    }
    case ESTIMATE_SHIPPING_COST_ERROR:
      return {
        ...state,
        estimateShippingCostInProgress: false,
        estimateShippingCostError: payload,
      };

    default:
      return state;
  }
};

export default EditShippingProfilePageReducer;

// ================ Action creators ================ //
export const setInitialValues = payload => ({
  type: SET_INITIAL_VALUES,
  payload,
});

export const showShippingProfileRequest = () => ({
  type: SHOW_SHIPPING_PROFILE_REQUEST,
});
export const showShippingProfileSuccess = payload => ({
  type: SHOW_SHIPPING_PROFILE_SUCCESS,
  payload,
});
export const showShippingProfileError = e => ({
  type: SHOW_SHIPPING_PROFILE_ERROR,
  payload: e,
});

export const fetchStoreListingRequest = () => ({
  type: FETCH_STORE_LISTING_REQUEST
});
export const fetchStoreListingSuccess = payload => ({
  type: FETCH_STORE_LISTING_SUCCESS,
  payload,
});
export const fetchStoreListingError = e => ({
  type: FETCH_STORE_LISTING_ERROR,
  payload: e,
});

export const fetchListingWithoutShippingProfileRequest = () => ({
  type: FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_REQUEST,
});
export const fetchListingWithoutShippingProfileSuccess = payload => ({
  type: FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_SUCCESS,
  payload,
});
export const fetchListingWithoutShippingProfileError = e => ({
  type: FETCH_LISTING_WITHOUT_SHIPPING_PROFILE_ERROR,
  payload: e,
});

export const fetchCarrierParcelTemplatesRequest = () => ({
  type: FETCH_CARRIER_PARCEL_TEMPLATES_REQUEST,
});
export const fetchCarrierParcelTemplatesSuccess = payload => ({
  type: FETCH_CARRIER_PARCEL_TEMPLATES_SUCCESS,
  payload,
});
export const fetchCarrierParcelTemplatesError = e => ({
  type: FETCH_CARRIER_PARCEL_TEMPLATES_ERROR,
  payload: e,
});

export const queryProductsWithoutShippingProfileRequest = () => ({
  type: QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_REQUEST,
});
export const queryProductsWithoutShippingProfileSuccess = payload => ({
  type: QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_SUCCESS,
  payload,
});
export const queryProductsWithoutShippingProfileError = e => ({
  type: QUERY_PRODUCTS_WITHOUT_SHIPPING_PROFILE_ERROR,
  payload: e,
});

export const fetchMoreAppliedListingsRequest = () => ({
  type: FETCH_MORE_APPLIED_LISTINGS,
});
export const fetchMoreAppliedListingsSuccess = payload => ({
  type: FETCH_MORE_APPLIED_LISTINGS_SUCCESS,
  payload,
});
export const fetchMoreAppliedListingsError = e => ({
  type: FETCH_MORE_APPLIED_LISTINGS_ERROR,
  payload: e,
});

export const createShippingProfileListingRequest = () => ({
  type: CREATE_SHIPPING_PROFILE_LISTING_REQUEST,
});
export const createShippingProfileListingSuccess = () => ({
  type: CREATE_SHIPPING_PROFILE_LISTING_SUCCESS,
});
export const createShippingProfileListingError = e => ({
  type: CREATE_SHIPPING_PROFILE_LISTING_ERROR,
  payload: e,
});

export const updateShippingProfileListingRequest = () => ({
  type: UPDATE_SHIPPING_PROFILE_LISTING_REQUEST,
});
export const updateShippingProfileListingSuccess = payload => ({
  type: UPDATE_SHIPPING_PROFILE_LISTING_SUCCESS,
  payload,
});
export const updateShippingProfileListingError = e => ({
  type: UPDATE_SHIPPING_PROFILE_LISTING_ERROR,
  payload: e,
});

export const fetchShippingProfilesRequest = () => ({
  type: FETCH_SHIPPING_PROFILES_REQUEST,
});
export const fetchShippingProfilesSuccess = payload => ({
  type: FETCH_SHIPPING_PROFILES_SUCCESS,
  payload,
});
export const fetchShippingProfilesError = e => ({
  type: FETCH_SHIPPING_PROFILES_ERROR,
  payload: e,
});

export const editProductDetailsRequest = () => ({
  type: EDIT_PRODUCT_DETAILS_REQUEST,
});
export const editProductDetailsSuccess = payload => ({
  type: EDIT_PRODUCT_DETAILS_SUCCESS,
  payload,
});
export const editProductDetailsError = e => ({
  type: EDIT_PRODUCT_DETAILS_ERROR,
  payload: e,
});

export const estimateShippingCostRequest = () => ({
  type: ESTIMATE_SHIPPING_COST_REQUEST,
});
export const estimateShippingCostSuccess = payload => ({
  type: ESTIMATE_SHIPPING_COST_SUCCESS,
  payload,
});
export const estimateShippingCostError = e => ({
  type: ESTIMATE_SHIPPING_COST_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const calculateEstimatedShippingCost = params => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(estimateShippingCostRequest());

  try {
    const response = await createShipment(params);
    const {
      messages,
      rates_list: ratesList,
    } = response.data;

    const { maximumShippingCost, shippingServiceOptions } = ratesList.reduce(
      (acc, rate) => {
        const { amount, provider, servicelevel_token: serviceLevelToken } = rate;

        const { maximumShippingCost, shippingServiceOptions } = acc;

        if (provider !== DEFAULT_CARRIER) {
          return acc;
        }
        const amountValue = parseFloat(amount);
        return {
          maximumShippingCost:
            amountValue > maximumShippingCost
              ? amountValue
              : maximumShippingCost,
          shippingServiceOptions: [
            ...shippingServiceOptions,
            serviceLevelToken,
          ],
        };
      },
      {
        maximumShippingCost: 0,
        shippingServiceOptions: [],
      }
    );

    const uspsErrorMessages = messages.filter(
      message => message.source === DEFAULT_CARRIER
    );

    dispatch(
      estimateShippingCostSuccess({
        maximumShippingCost,
        uspsErrorMessages,
        shippingServiceOptions,
      })
    );

    return {
      hasRates: ratesList.length > 0,
    }
  } catch (e) {
    log.error(e);
    dispatch(estimateShippingCostError(storableError(e)));
    throw e;
  }
};

export const editProductDetails = values => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(editProductDetailsRequest());

  const { productId, params } = values;

  const { shippingProfile } = getState().EditShippingProfilePage;

  const {
    shippingProfileId: currentShippingProfileId,
  } = shippingProfile.attributes.publicData;

  try {
    await sdk.ownListings.update({
      id: productId,
      ...params,
    });

    // Wait for the changes to propagate
    await new Promise(resolve => setTimeout(resolve, 1000));

    const appliedListingResponse = await queryAppliedListings(
      sdk,
      currentShippingProfileId
    );

    const appliedListings = denormalisedResponseEntities(
      appliedListingResponse
    );

    dispatch(
      editProductDetailsSuccess({
        appliedListings,
        appliedListingsPagination: appliedListingResponse.data.meta,
      })
    );

    return appliedListings;
  } catch (e) {
    log.error(e);
    dispatch(editProductDetailsError(storableError(e)));
    throw e;
  }
};

const getAllShippingProfileListings = async (
  sdk,
  currentUserId,
  accumulatedListings = [],
  page = 1
) => {
  const listingResponse = await sdk.listings.query({
    authorId: currentUserId,
    pub_listingType: LISTING_TYPES.SHIPPING_PROFILE_LISTING,
    page,
  });

  const meta = listingResponse.data.meta;
  const { totalPages } = meta;

  const listings = denormalisedResponseEntities(listingResponse);
  const currentResult = accumulatedListings.concat(listings);

  if (totalPages <= page) {
    return currentResult;
  }

  return getAllShippingProfileListings(
    sdk,
    currentUserId,
    currentResult,
    page + 1
  );
};

const fetchAllShippingProfiles = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchShippingProfilesRequest());

    const { currentUser } = getState().user;

    const currentUserId = currentUser.id.uuid;

    const shippingProfiles = await getAllShippingProfileListings(sdk, currentUserId);

    dispatch(fetchShippingProfilesSuccess(shippingProfiles));
  } catch (e) {
    log.error(e);
    dispatch(fetchShippingProfilesError(storableError(e)));
  }
};

export const updateShippingProfile = values => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateShippingProfileListingRequest());

  const { params, selectedProducts } = values;

  try {
    const response = await sdk.ownListings.update(params, {
      expand: true,
    });

    const shippingProfile = denormalisedResponseEntities(response)[0];

    const { shippingProfileId } = shippingProfile.attributes.publicData;

    await Promise.all(
      selectedProducts.map(listing =>
        sdk.ownListings.update(
          {
            id: listing.id,
            publicData: {
              shippingProfileId,
            },
          },
          {
            expand: true,
          }
        )
      )
    );

    await new Promise(resolve => setTimeout(resolve, 1000));

    dispatch(queryListingWithoutShippingProfile());

    const appliedListingResponse = await queryAppliedListings(
      sdk,
      shippingProfileId
    );

    const appliedListings = denormalisedResponseEntities(appliedListingResponse);

    dispatch(
      updateShippingProfileListingSuccess({
        shippingProfile,
        appliedListings,
        appliedListingsPagination: appliedListingResponse.data.meta,
      })
    );

    return response;
  } catch (e) {
    log.error(e);
    dispatch(updateShippingProfileListingError(storableError(e)));
  }
};

export const createShippingProfile = values => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createShippingProfileListingRequest());

  const { currentUser } = getState().user;

  const { params, selectedProducts } = values;

  try {
    const response = await createShippingProfileListing({
      params: {
        authorId: currentUser.id.uuid,
        state: LISTING_STATE_PUBLISHED,
        ...params,
        publicData: {
          ...params.publicData,
          shippingProfileId: generateId(),
          listingType: LISTING_TYPES.SHIPPING_PROFILE_LISTING,
        },
      },
    });

    const newShippingProfile = denormalisedResponseEntities(response)[0];

    const { shippingProfileId } = newShippingProfile.attributes.publicData;

    await Promise.all(
      selectedProducts.map(listing =>
        sdk.ownListings.update({
          id: listing.id,
          publicData: {
            shippingProfileId,
          },
        })
      )
    );

    // Wait for the changes to propagate
    await new Promise(resolve => setTimeout(resolve, 1000));

    dispatch(createShippingProfileListingSuccess());

    return newShippingProfile;
  } catch (e) {
    log.error(e);
    dispatch(createShippingProfileListingError(storableError(e)));
    throw e;
  }
};

export const queryProductsWithoutShippingProfile = keywords => async (
  dispatch,
  getState,
  sdk
) => {
  const {
    fetchMoreListingWithoutShippingProfile,
  } = getState().EditShippingProfilePage;

  if (fetchMoreListingWithoutShippingProfile) {
    return;
  }

  dispatch(queryProductsWithoutShippingProfileRequest());

  const { currentUser } = getState().user;

  const currentUserId = currentUser.id.uuid;
  try {
    const response = await sdk.listings.query({
      authorId: currentUserId,
      keywords,
      perPage: RESULT_PAGE_SIZE,
      pub_listingType: LISTING_TYPES.PRODUCT_LISTING,
      pub_shippingProfileId: 'null',
      include: ['images'],
      'fields.image': ['variants.square-small'],
      'limit.images': 1,
    });

    const listingWithoutShippingProfile = denormalisedResponseEntities(response);

    dispatch(
      queryProductsWithoutShippingProfileSuccess({
        listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination: response.data.meta,
      })
    );

    return listingWithoutShippingProfile;
  } catch (e) {
    log.error(e);
    dispatch(queryProductsWithoutShippingProfileError(storableError(e)));
  }
};

export const fetchMoreListingWithoutShippingProfile = (
  keywords = null
) => async (dispatch, getState, sdk) => {
  const {
    listingWithoutShippingProfile,
    listingWithoutShippingProfilePagination,
    queryProductsWithoutShippingProfileInProgress,
  } = getState().EditShippingProfilePage;

  if (queryProductsWithoutShippingProfileInProgress) {
    return;
  }

  const { page, totalItems = 0 } =
    listingWithoutShippingProfilePagination || {};

  if (totalItems > listingWithoutShippingProfile.length) {
    const nextPage = page + 1;

    return dispatch(
      queryListingWithoutShippingProfile({
        page: nextPage,
        isLoadMore: true,
        keywords,
      })
    );
  }
};

const fetchCarrierParcelTemplates = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchCarrierParcelTemplatesRequest());
    const response = await getCarrierParcelTemplates();
    dispatch(fetchCarrierParcelTemplatesSuccess(response.data.results));
  } catch (e) {
    log.error(e);
    dispatch(fetchCarrierParcelTemplatesError(storableError(e)));
  }
};

const fetchStoreListing = storeId => (dispatch, getState, sdk) => {
  dispatch(fetchStoreListingRequest());

  return sdk.listings
    .show({
      id: storeId,
    })
    .then(data => {
      const storeListing = denormalisedResponseEntities(data)[0];
      dispatch(fetchStoreListingSuccess(storeListing));
    })
    .catch(e => {
      log.error(e);
      dispatch(fetchStoreListingError(storableError(e)));
    });
};

export const fetchMoreAppliedListings = () => async (
  dispatch,
  getState,
  sdk
) => {
  const {
    shippingProfile,
    appliedListings,
    appliedListingsPagination,
  } = getState().EditShippingProfilePage;

  const {
    shippingProfileId
  } = shippingProfile.attributes.publicData;

  const { page, totalItems = 0 } = appliedListingsPagination || {};

  if (totalItems > appliedListings.length) {
    const nextPage = page + 1;

    dispatch(fetchMoreAppliedListingsRequest());

    try {
      const response = await queryAppliedListings(
        sdk,
        shippingProfileId,
        nextPage
      );

      const newAppliedListings = denormalisedResponseEntities(response);

      dispatch(
        fetchMoreAppliedListingsSuccess({
          appliedListings: [...appliedListings, ...newAppliedListings],
          appliedListingsPagination: response.data.meta,
        })
      );
    } catch (e) {
      log.error(e);
      dispatch(fetchMoreAppliedListingsError(storableError(e)));
    }
  }
};

const queryAppliedListings = async (
  sdk,
  shippingProfileId,
  page = 1
) => {
  const response = await sdk.listings.query({
    perPage: RESULT_PAGE_SIZE,
    page,
    pub_listingType: LISTING_TYPES.PRODUCT_LISTING,
    pub_shippingProfileId: shippingProfileId,
    include: ['images'],
    'fields.image': ['variants.square-small'],
    'limit.images': 1,
  });

  return response;
};

const queryListingWithoutShippingProfile = params => async (
  dispatch,
  getState,
  sdk
) => {
  const {
    page = 1,
    isLoadMore = false,
    keywords = null
  } = params || {};
  const { currentUser } = getState().user;

  const {
    listingWithoutShippingProfile: savedData,
  } = getState().EditShippingProfilePage;

  const currentUserId = currentUser.id.uuid;
  try {
    dispatch(fetchListingWithoutShippingProfileRequest());
    const response = await sdk.listings.query({
      keywords,
      authorId: currentUserId,
      pub_listingType: LISTING_TYPES.PRODUCT_LISTING,
      pub_shippingProfileId: 'null',
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['images'],
      'fields.image': ['variants.square-small'],
      'limit.images': 1,
    });

    const listingWithoutShippingProfile = denormalisedResponseEntities(
      response
    );

    dispatch(
      fetchListingWithoutShippingProfileSuccess({
        listingWithoutShippingProfile: isLoadMore
          ? [...savedData, ...listingWithoutShippingProfile]
          : listingWithoutShippingProfile,
        listingWithoutShippingProfilePagination: response.data.meta,
      })
    );

    return listingWithoutShippingProfile;
  } catch (e) {
    log.error(e);
    dispatch(fetchListingWithoutShippingProfileError(storableError(e)));
  }
};

const requestShowShippingProfile = actionPayload => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(showShippingProfileRequest());

    const response = await sdk.ownListings.show(actionPayload);

    const shippingProfile = denormalisedResponseEntities(response)[0];

    const { shippingProfileId } = shippingProfile.attributes.publicData;

    const appliedListingResponse = await queryAppliedListings(
      sdk,
      shippingProfileId
    );

    const appliedListings = denormalisedResponseEntities(appliedListingResponse);

    dispatch(
      showShippingProfileSuccess({
        shippingProfile,
        appliedListings,
        appliedListingsPagination: appliedListingResponse.data.meta,
      })
    );

    return response;
  } catch (e) {
    log.error(e);
    dispatch(showShippingProfileError(storableError(e)));
  }
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  try {
    const { id, type } = params;

    const { currentUser } = getState().user;

    const storeListingId = currentUser.attributes.profile.metadata.storeId;

    if (type === LISTING_PAGE_PARAM_TYPE_NEW) {
      return Promise.all([
        dispatch(fetchStoreListing(storeListingId)),
        dispatch(queryListingWithoutShippingProfile()),
        dispatch(fetchCarrierParcelTemplates()),
        dispatch(fetchAllShippingProfiles()),
      ]);
    }

    return Promise.all([
      dispatch(requestShowShippingProfile({ id: new UUID(id) })),
      dispatch(fetchStoreListing(storeListingId)),
      dispatch(queryListingWithoutShippingProfile()),
      dispatch(fetchCarrierParcelTemplates()),
      dispatch(fetchAllShippingProfiles()),
    ]);
  } catch (e) {
    throw e;
  }
};
