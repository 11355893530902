import { useEffect } from 'react';
import { userSocket } from '../instance';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser } from '../../../ducks/user.duck';
import { SOCKET_EVENT_NAMES } from '../common/types';

const SocketConnectionManager = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);

  const onFetchCurrentUser = () => dispatch(fetchCurrentUser());

  useEffect(() => {
    if (isAuthenticated) {
      userSocket.connect();
      userSocket.emit(SOCKET_EVENT_NAMES.USER_LOGGED_IN);

      userSocket.on(SOCKET_EVENT_NAMES.USER_UPDATED, () => {
        return onFetchCurrentUser();
      });
    }
  }, [isAuthenticated]);

  return null;
};

export default SocketConnectionManager;
