import { ADMIN } from './types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Check if the currentUser is admin
export const isAdminUser = currentUser => {
  if (isEmpty(currentUser)) return false;
  const adminRoles = get(
    currentUser,
    'attributes.profile.metadata.adminRoles',
    ''
  );
  return adminRoles.split(',').includes(ADMIN);
};
