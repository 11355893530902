import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setInitialValuesForShoppingCart } from '../containers/CartPage/CartPage.duck';
import { setInitialValuesForShoppingCart as setInitialValuesForShoppingCartMultiVendors } from '../extensions/shoppingCartMultiVendors/containers/ShoppingCartPage/ShoppingCartPage.duck';

export const PrepareDataForApp = () => {
  const dispatch = useDispatch();
  const setInitialValuesForCart = () =>
    dispatch(setInitialValuesForShoppingCart());
  const setInitialValuesForCartMultiVendors = () =>
    dispatch(setInitialValuesForShoppingCartMultiVendors());

  useEffect(() => {
    setInitialValuesForCart();
    setInitialValuesForCartMultiVendors();
  }, []);
  return null;
};
