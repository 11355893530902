import loadable from '@loadable/component';
import { loadData as AdminManageUserTypePageLoader } from './containers/AdminManageUserTypePage.duck';
import { ADMIN } from './common/types';

const AdminManageUserTypePage = loadable(() =>
  import(
    /* webpackChunkName: 'AdminManageUserTypePage' */ './containers/AdminManageUserTypePage'
  )
);

const routes = [
  {
    path: '/admin/manage-user-type',
    name: 'AdminManageUserTypePage',
    auth: true,
    authorizeFor: [ADMIN],
    component: AdminManageUserTypePage,
    loadData: AdminManageUserTypePageLoader,
  },
];

export default routes;