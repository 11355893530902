import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import { createTheme, styled, ThemeProvider } from '@mui/material/styles';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { USER_TYPE } from '../../extensions/Artisans/constansts';

import css from './SidebarNav.module.css';

// Define your custom theme
const customtheme = createTheme({
  typography: {
    fontFamily: ['Epilogue', 'Roboto', 'sans-serif'].join(','),
    fontSize: 13,
    fontWeight: 300,
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: ['Epilogue', 'Roboto', 'sans-serif'].join(','),
          fontSize: 14,
          fontWeight: 300,
        },
      },
    },
  },
});

const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'ProfileSettingsPage',
  'PaymentMethodsPage',
];

const LISTINGS_PAGES = [
  'ManageListingsPage',
  'NewListingPage',
  'ImportListingsPage',
];

const FINANCES_PAGES = ['StripePayoutPage', 'PaymentMethodsPage'];
const ARTISAN_PAGES = ['NewArtisanPage', 'ManageArtisansPage'];

const SidebarNav = props => {
  const { className, rootClassName, currentPage, isOnMobile } = props;

  const history = useHistory();
  const location = useLocation();

  const { currentUser } = useSelector(state => state.user);
  const isSellerPage = location.pathname.includes('/sellers') ? true : false;

  const classes = classNames(rootClassName || css.root, className);

  const [openAccounts, setOpenAccounts] = useState(false);
  const [openFinances, setOpenFinances] = useState(false);
  const [openListings, setOpenListings] = useState(false);
  const [openArtisans, setOpenArtisans] = useState(false);

  const toggleListings = () => {
    setOpenListings(!openListings);
  };

  const toggleFinances = () => {
    setOpenFinances(!openFinances);
  };

  const toggleAccounts = () => {
    setOpenAccounts(!openAccounts);
  };

  const toggleArtisans = () => {
    setOpenArtisans(!openArtisans);
  };

  useEffect(() => {
    if (ACCOUNT_SETTINGS_PAGES.includes(currentPage)) {
      setOpenAccounts(true);
    }
    if (FINANCES_PAGES.includes(currentPage)) {
      setOpenFinances(true);
    }
    if (LISTINGS_PAGES.includes(currentPage)) {
      setOpenListings(true);
    }
    if (ARTISAN_PAGES.includes(currentPage)) {
      setOpenArtisans(true);
    }
  }, [currentPage]);

  const currentPageClass = page => {
    return currentPage === page ? css.selected : null;
  };

  const isApprovedSeller =
    currentUser?.attributes?.profile?.metadata?.isApprovedSeller ?? false;

  const { userType } = currentUser?.attributes.profile.metadata || {};
  const isArtisanFacilitator = userType === USER_TYPE.ARTISAN_FACILITATORS;

  return (
    <ThemeProvider theme={customtheme}>
      {isSellerPage ? (
        <List
          className={classNames(css.sidebarMenu, {
            [css.onmobile]: isOnMobile,
          })}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={css.subheader}
            >
              Manage your store
            </ListSubheader>
          }
        >
          <ListItem
            component={NamedLink}
            name="SellerDashboardPage"
            className={classNames(
              css.navItem,
              currentPageClass('SellerDashboardPage')
            )}
          >
            <ListItemIcon className={css.navItemIcon}>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" className={css.navItemText} />
          </ListItem>
          <ListItem
            component={NamedLink}
            name="EditShopPage"
            className={classNames(
              css.navItem,
              currentPageClass('EditShopPage')
            )}
          >
            <ListItemIcon className={css.navItemIcon}>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Store details" className={css.navItemText} />
          </ListItem>
          <ListItem
            component={NamedLink}
            name="ManageShippingProfilePage"
            className={classNames(
              css.navItem,
              currentPageClass('ManageShippingProfilePage')
            )}
          >
            <ListItemIcon className={css.navItemIcon}>
              <LocalShippingOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Shipping profiles"
              className={css.navItemText}
            />
          </ListItem>
          <ListItemButton
            onClick={toggleListings}
            className={classNames(css.navItemButton, {
              [css.selected]: openListings,
            })}
          >
            <ListItemIcon className={css.navItemIcon}>
              <SellOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Listings" />
            {openListings ? <ExpandMore /> : <ChevronRightRoundedIcon />}
          </ListItemButton>
          <Collapse
            in={openListings}
            timeout="auto"
            unmountOnExit
            className={classNames(css.itemCollapse, {
              [css.open]: openListings,
            })}
          >
            <List component="div" disablePadding>
              <ListItem
                component={NamedLink}
                name="ManageListingsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('ManageListingsPage')
                )}
              >
                <ListItemText
                  primary="View all listing"
                  className={css.navItemText}
                />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="NewListingPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('NewListingPage')
                )}
              >
                <ListItemText
                  primary="Add a new product"
                  className={css.navItemText}
                />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="ImportListingsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('ImportListingsPage')
                )}
              >
                <ListItemText
                  primary="Import new products"
                  className={css.navItemText}
                />
              </ListItem>
            </List>
          </Collapse>
          {isArtisanFacilitator && (
            <>
              <ListItemButton
                onClick={toggleArtisans}
                className={classNames(css.navItemButton, {
                  [css.selected]: openArtisans,
                })}
              >
                <ListItemIcon className={css.navItemIcon}>
                  <GroupAddOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Artisans" />
                {openArtisans ? <ExpandMore /> : <ChevronRightRoundedIcon />}
              </ListItemButton>
              <Collapse
                in={openArtisans}
                timeout="auto"
                unmountOnExit
                className={classNames(css.itemCollapse, {
                  [css.open]: openArtisans,
                })}
              >
                <List component="div" disablePadding>
                  <ListItem
                    component={NamedLink}
                    name="ManageArtisansPage"
                    className={classNames(
                      css.navItem,
                      currentPageClass('ManageArtisansPage')
                    )}
                  >
                    <ListItemText
                      primary="View all artisans"
                      className={css.navItemText}
                    />
                  </ListItem>
                  <ListItem
                    component={NamedLink}
                    name="NewArtisanPage"
                    className={classNames(
                      css.navItem,
                      currentPageClass('NewArtisanPage')
                    )}
                  >
                    <ListItemText
                      primary="Add a new artisan"
                      className={css.navItemText}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          <ListItem
            component={NamedLink}
            name="SellerOrdersPage"
            className={classNames(css.navItem, currentPageClass('InboxPage'))}
          >
            <ListItemIcon className={css.navItemIcon}>
              <ReceiptLongOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" className={css.navItemText} />
          </ListItem>
          <ListItemButton
            onClick={toggleFinances}
            className={classNames(css.navItemButton, {
              [css.selected]: openFinances,
            })}
          >
            <ListItemIcon className={css.navItemIcon}>
              <PaymentsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Finances" />
            {openFinances ? <ExpandMore /> : <ChevronRightRoundedIcon />}
          </ListItemButton>
          <Collapse
            in={openFinances}
            timeout="auto"
            unmountOnExit
            className={classNames(css.itemCollapse, {
              [css.open]: openFinances,
            })}
          >
            <List component="div" disablePadding>
              <ListItem
                component={NamedLink}
                name="StripePayoutPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('StripePayoutPage')
                )}
              >
                <ListItemText
                  primary="Payout account"
                  className={css.navItemText}
                />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="SellerPaymentMethodsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('PaymentMethodsPage')
                )}
              >
                <ListItemText
                  primary="Payment methods"
                  className={css.navItemText}
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
      ) : (
        <List
          className={classNames(css.sidebarMenu, {
            [css.onmobile]: isOnMobile,
          })}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={css.subheader}
            >
              Your account
            </ListSubheader>
          }
        >
          <ListItemButton
            onClick={toggleAccounts}
            className={classNames(css.navItemButton, {
              [css.selected]: openAccounts,
            })}
          >
            <ListItemIcon className={css.navItemIcon}>
              <SellOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Account settings" />
            {openAccounts ? <ExpandMore /> : <ChevronRightRoundedIcon />}
          </ListItemButton>
          <Collapse
            in={openAccounts}
            timeout="auto"
            unmountOnExit
            className={classNames(css.itemCollapse, {
              [css.open]: openAccounts,
            })}
          >
            <List component="div" disablePadding>
              <ListItem
                component={NamedLink}
                name="ProfileSettingsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('ProfileSettingsPage')
                )}
              >
                <ListItemText primary="About you" className={css.navItemText} />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="ContactDetailsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('ContactDetailsPage')
                )}
              >
                <ListItemText
                  primary="Contact details"
                  className={css.navItemText}
                />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="PasswordChangePage"
                className={classNames(
                  css.navItem,
                  currentPageClass('PasswordChangePage')
                )}
              >
                <ListItemText primary="Password" className={css.navItemText} />
              </ListItem>
              <ListItem
                component={NamedLink}
                name="PaymentMethodsPage"
                className={classNames(
                  css.navItem,
                  currentPageClass('PaymentMethodsPage')
                )}
              >
                <ListItemText
                  primary="Payment methods"
                  className={css.navItemText}
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            component={NamedLink}
            name="BuyerOrdersPage"
            className={classNames(css.navItem, currentPageClass('InboxPage'))}
          >
            <ListItemIcon className={css.navItemIcon}>
              <ReceiptLongOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Purchases" className={css.navItemText} />
          </ListItem>
        </List>
      )}
    </ThemeProvider>
  );
};

SidebarNav.defaultProps = {
  className: null,
  rootClassName: null,
  isOnMobile: false,
};

const { string, bool } = PropTypes;

SidebarNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string,
  isOnMobile: bool,
};

export default SidebarNav;
