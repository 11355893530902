import { SHIPPING_FEE_TYPES, SHIPPING_FEE_URL_TYPES, ZIP_CODE_FLORIDA, ZIP_CODE_HAWAII } from "./types";

const dec2hex = dec => {
  return dec.toString(16).padStart(2, "0");
}

const containsLetter = str => {
  return /[a-f]/i.test(str);
}

export const generateId = (len = 10) => {
  let id;
  
  do {
    const arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    id = Array.from(arr, dec2hex).join('');
  } while (!containsLetter(id));

  return id;
};

export const getShippingFeeType = (shippingProfile, shippingFeeTypeParam) => {
  if (!shippingProfile) {
    for (const [key, value] of Object.entries(SHIPPING_FEE_URL_TYPES)) {
      if (value === shippingFeeTypeParam) {
        return key;
      }
    }

    return SHIPPING_FEE_TYPES.BUYER_PAY_SHIPPING;
  }

  return shippingProfile.attributes.publicData.shippingFeeType;
};

export const getTheFurthestPostalCode = fromPostalCode => {
  if (!fromPostalCode) {
    return null;
  }

  const firstTwoDigitsOfPostalCode = Number(fromPostalCode.substring(0, 2));

  if (firstTwoDigitsOfPostalCode > 95 && firstTwoDigitsOfPostalCode < 100) {
    return ZIP_CODE_FLORIDA;
  }

  return ZIP_CODE_HAWAII;
};