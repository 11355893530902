import { unitDivisor } from '../../../util/currency';
import { DEFAULT_CURRENCY } from '../../../util/types';

export const calculateTotalRefundForPartiallyRefundedOrder = (
  currentTaxData,
  orderedTaxData
) => {
  const {
    totalAmountExcludingShipping,
    salesTaxAmount,
    shippingTotalAmount,
  } = currentTaxData;

  const {
    totalAmountExcludingShipping: orderedTotalAmountExcludingShipping,
    salesTaxAmount: orderedSalesTaxAmount,
    shippingTotalAmount: orderedShippingTotalAmount,
  } = orderedTaxData;

  const totalRefundShippingFee =
    orderedShippingTotalAmount - shippingTotalAmount;

  const totalRefundShippingFeeAmount =
    totalRefundShippingFee * unitDivisor(DEFAULT_CURRENCY);

  const totalRefundAmount =
    orderedTotalAmountExcludingShipping -
    totalAmountExcludingShipping +
    orderedSalesTaxAmount -
    salesTaxAmount +
    totalRefundShippingFeeAmount;

  return totalRefundAmount;
};

export const getViewTrackingDetailsTransaction = (
  transactions,
  transactionId
) => transactions.find(item => item.id.uuid === transactionId);

const dec2hex = dec => {
  return dec?.toString(16).padStart(2, "0")
}

export const generateId = (len = 10) => {
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};
