import get from 'lodash/get';
import { SELLER } from '../config/configUser';

// Check if the User is a seller
export const isSeller = user => {
  return (
    get(user, 'attributes.profile.metadata.isApprovedSeller', false) &&
    get(user, 'attributes.profile.metadata.userType', '').includes(SELLER)
  );
};

// Check if the User has finished onboarding as a seller
export const hasSellerFinishedOnboarding = user => {
  return (
    isSeller(user) &&
    get(user, 'attributes.profile.metadata.hasFinishedOnboarding', false) &&
    get(user, 'attributes.profile.metadata.storeId', null)
  );
};

export const getStoreId = user => {
  if (!isSeller(user)) return null;
  return (
    get(user, 'attributes.profile.metadata.storeId', null) ||
    get(user, 'attributes.profile.publicData.userListingId', null)
  );
};

export const getSellerType = user => {
  const userType = user.attributes.profile.metadata?.userType ?? '';
  return userType.split('-').pop();
};
