import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { convertMoneyToNumber } from './currency';
import { COUPON_LINE_ITEMS, DEFAULT_CURRENCY } from './types';
import { subUnitDivisors } from '../config/settingsCurrency';

// Function to create a SHA-1 hash of an email address using crypto.subtle
export const sha1Hash = async email => {
  // Return an empty string if the email is empty
  // Because SHA1 always returns a 40-character hexadecimal string
  // even if the input is empty ''
  if (isEmpty(email)) return '';

  // Encode the email address as UTF-8
  const encoder = new TextEncoder();
  const data = encoder.encode(email);

  // Create a hash buffer
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);

  // Convert the hash buffer to a hexadecimal string
  const hashedEmail = Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashedEmail;
};

const roundToTwoDecimals = num => Math.round(num * 100) / 100;

const getDataLayerOfMultiVendorTransaction = (transaction, childTransactions) => {
  const orderTotalExcludingDiscount = childTransactions.reduce((acc, item) => {
    return acc + item.attributes.metadata.finalPayoutToDisplay.amount;
  }, 0);

  const {
    lineItems,
    metadata: { discountCodeData, orderDetails },
  } = transaction.attributes;

  const couponLineTotalMaybe =
    lineItems.find(item => LINE_ITEM_COUPON_DISCOUNT === item.code)?.lineTotal;

  const { amount: couponAmount = 0, currency } = couponLineTotalMaybe || {};

  const orderPromoData = discountCodeData
    ? {
        orderPromoCode: discountCodeData.code,
        orderDiscount: roundToTwoDecimals(
          (-1 * couponAmount) / subUnitDivisors[currency]
        ),
      }
    : {};

    const items = orderDetails.productOrder.map(item => {
      const matchedListing = listings.find(item => item.id.uuid === item.listingId);
  
      const {
        title,
        publicData: {
          top_level_category: itemTopCategory,
          parent_category: itemParentCategory,
          product_type: itemProductType,
          sku
        },
      } = matchedListing.attributes;
  
      const itemCategory =
        itemProductType === itemParentCategory
          ? itemTopCategory
          : itemParentCategory;
      return {
        subTotal: roundToTwoDecimals(item.price.amount / subUnitDivisors[currency]),
        category: itemCategory,
        sku: item.variationSKU || sku,
        quantity: item.quantity,
        name: title,
      };
    });

  return {
    orderTotalExcludingDiscount,
    orderPromoData,
    items
  }
};

const getDataLayerOfSingleVendorTransaction = transaction => {
  const {
    lineItems,
    metadata: {
      finalPayoutToDisplay,
      discountCodeData,
      quantityAndProductVariation,
    },
  } = transaction.attributes;
  // We use finalPayoutToDisplay.amount because it already has the decimal point
  const orderTotalExcludingDiscount = finalPayoutToDisplay.amount;

  const couponLineTotalMaybe =
    lineItems.find(item => COUPON_LINE_ITEMS.includes(item.code))?.lineTotal;

  const {
    amount: couponAmount = 0,
    currency,
  } = couponLineTotalMaybe || {};

  const orderPromoData = discountCodeData
    ? {
        orderPromoCode: discountCodeData.code,
        orderDiscount: roundToTwoDecimals((-1 * couponAmount) / subUnitDivisors[currency]),
      }
    : {};

  const items = quantityAndProductVariation.map(item => {
    const matchedListing = listings.find(item => item.id.uuid === item.listingId);

    const {
      title,
      publicData: {
        top_level_category: itemTopCategory,
        parent_category: itemParentCategory,
        product_type: itemProductType,
        sku
      },
    } = matchedListing.attributes;

    const itemCategory =
      itemProductType === itemParentCategory
        ? itemTopCategory
        : itemParentCategory;
    return {
      subTotal: roundToTwoDecimals(item.price.amount / subUnitDivisors[currency]),
      category: itemCategory,
      sku: item.variationSKU || sku,
      quantity: item.quantity,
      name: title,
    };
  });

  return {
    orderTotalExcludingDiscount,
    orderPromoData,
    items,
  }
};

export const prepareDataForOnlineSaleEvent = async (
  transaction,
  listings,
  currentUser,
  options = {}
) => {
  if (isEmpty(transaction) || isEmpty(listings) || isEmpty(currentUser)) {
    return null;
  }

  const { isMultiVendorTransaction, childTransactions } = options;

  const {
    orderTotalExcludingDiscount,
    orderPromoData,
    items,
  } = isMultiVendorTransaction
    ? getDataLayerOfMultiVendorTransaction(transaction, childTransactions)
    : getDataLayerOfSingleVendorTransaction(transaction);

  const txId = transaction.id.uuid;
  
  const customerID = currentUser.id.uuid;
  const customerEmaiSHA1lHash = await sha1Hash(currentUser?.attributes.email);
  const currency = DEFAULT_CURRENCY;

  return {
    orderId: txId,
    orderTotalExcludingDiscount,
    customerID,
    customerEmail: customerEmaiSHA1lHash,
    customerStatus: '',
    currencyCode: currency,
    items,
    ...orderPromoData,
  };
};

export const prepareDataForAddToCartEvent = (listing, itemOption) => {
  if (isEmpty(listing) || isEmpty(itemOption)) return null;
  const currency = 'USD';
  const { quantity: listingQuantity } = itemOption;
  const totalAmount =
    convertMoneyToNumber(listing.attributes.price) * listingQuantity;
  const listingPrice = convertMoneyToNumber(listing.attributes.price);
  const listingTopCategory = listing.attributes.publicData.top_level_category;
  const listingParentCategory = listing.attributes.publicData.parent_category;
  const listingProductType = listing.attributes.publicData.product_type;
  const listingCategory =
    listingProductType === listingParentCategory
      ? listingTopCategory
      : listingParentCategory;
  const listingSku = listing.attributes.publicData.sku;
  const listingName = listing.attributes.title;
  const listingAddedToCartInfo = [
    {
      subTotal: listingPrice,
      category: listingCategory,
      sku: listingSku,
      quantity: listingQuantity,
      name: listingName,
    },
  ];

  return {
    totalAmount,
    currencyCode: currency,
    items: listingAddedToCartInfo,
  };
};

export const prepareDataForViewProductEvent = listing => {
  if (isEmpty(listing)) return {};
  const eventValue = convertMoneyToNumber(listing.attributes.price);
  const currency = 'USD';
  const item_id = get(listing, 'attributes.publicData.sku', '');
  const item_name = get(listing, 'attributes.title', '');
  const item_category = get(
    listing,
    'attributes.publicData.top_level_category',
    ''
  );
  const item_category2 = get(
    listing,
    'attributes.publicData.parent_category',
    ''
  );
  const item_category3 = get(listing, 'attributes.publicData.product_type', '');
  const listingPrice = convertMoneyToNumber(listing.attributes.price);
  return {
    currency,
    value: eventValue,
    items: [
      {
        item_id,
        item_name,
        item_category,
        item_category2,
        item_category3,
        price: listingPrice,
      },
    ],
  };
};
