import loadable from '@loadable/component';
import { loadData as ManageArtisanPageLoader } from './container/ManageArtisansPage/ManageArtisansPage.duck';
import { SELLER } from '../common/config/configUser';
const ManageArtisanPage = loadable(() =>
  import('./container/ManageArtisansPage/ManageArtisansPage')
);

const routes = [
  {
    path: '/sellers/artisans',
    name: 'ManageArtisansPage',
    auth: true,
    authorizeFor: [SELLER],
    authPage: 'LoginPage',
    component: ManageArtisanPage,
    loadData: ManageArtisanPageLoader,
  },
];

export default routes;
