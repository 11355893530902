import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import CategoryBarContainer from '../../../containers/CategoryBarContainer/CategoryBarContainer';
import AboutNavBar from '../../../components/AboutNavBar/AboutNavBar';
import {
  Avatar,
  IconCart,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../../components';
import { FormattedText } from '../../../util/data';
import { isAdminUser } from '../../../extensions/admin/common/helpers';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    cartProducts,
    handleDesktopShipToModalOpen,
    isSellerPage,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isUserLoadedOnClient =
    authenticatedOnClientSide && !isEmpty(currentUser);

  const classes = classNames(rootClassName || css.root, className);

  const isSellerApproved = get(
    currentUser,
    'attributes.profile.metadata.isApprovedSeller',
    null
  );
  const hasFinishedOnboarding = get(
    currentUser,
    'attributes.profile.metadata.hasFinishedOnboarding',
    null
  );
  const isSellerAgreeWithEthicalPledge = get(
    currentUser,
    'attributes.profile.metadata.isSellerAgreeWithEthicalPledge',
    null
  );
  const isAdmin = isAdminUser(currentUser);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: isSellerApproved ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  const cartQuantity = cartProducts.reduce(
    (total, product) => total + product.quantity,
    0
  );

  const cartLink = (
    <NamedLink className={css.cartLink} name="ShoppingCartPage">
      <IconCart quantity={cartQuantity} />
    </NamedLink>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        {/*<Avatar className={css.avatar} user={currentUser} disableProfileLink />*/}
        <span className={`${css.avatar} icon material-symbols-outlined`}>
          person
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isAdmin && (
          <MenuItem key="AdminDashboardPage">
            <NamedLink
              className={classNames(
                css.youtrInboxLink,
                currentPageClass('AdminDashboardPage')
              )}
              name="AdminDashboardPage"
            >
              <span className={css.menuItemBorder} />
              <span className={css.yourInbox}>
                <FormattedMessage id="TopbarDesktop.adminDashboard" />
              </span>
            </NamedLink>
          </MenuItem>
        )}
        {isAdmin ? (
          <MenuItem key="AdminManageUserTypePage">
            <NamedLink
              className={classNames(css.youtrInboxLink)}
              name="AdminManageUserTypePage"
            >
              <span className={css.menuItemBorder} />
              <span className={css.yourInbox}>
                <FormattedMessage id="TopbarDesktop.adminManageUserType" />
              </span>
            </NamedLink>
          </MenuItem>
        ) : null}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(
              css.youtrInboxLink,
              currentPageClass('InboxPage')
            )}
            name="BuyerOrdersPage"
          >
            <span className={css.menuItemBorder} />
            <span className={css.yourInbox}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <span className={`${css.icon} material-symbols-outlined`}>person</span>
        <FormattedMessage id="Topbar.loginOrSignup" />
      </span>
    </NamedLink>
  );

  const createListingMenu = (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.sellAProduct" />
        </span>
      </MenuLabel>
      <MenuContent className={css.sellProductMenuContent}>
        <MenuItem key="NewListingPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('NewListingPage')
            )}
            name="NewListingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.createListing" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ImportListingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ImportListingsPage')
            )}
            name="ImportListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.importNewProducts" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  const onShipToLinkClick = e => {
    e.preventDefault();
    handleDesktopShipToModalOpen();
  };

  const shipToLink = (
    <a onClick={onShipToLinkClick} className={css.userpref}>
      <span className={css['userpref-shipto-label']}>Ship to</span>
      <span className={css['userpref-shipto-country-flag']}>
        <img
          src="https://assets3.novica.net/2015/assets/images/flags/flag-US.png"
          width="30"
          height="17"
          className={`${css.flag} ${css['flag-small-us']}`}
        />
      </span>
      <span className={css['userpref-currency']}>USD</span>
      <span className={css['userpref-lang']}>EN</span>
    </a>
  );

  const sellerLandingPage = hasFinishedOnboarding
    ? 'SellerDashboardPage'
    : 'SellerOnboardingStartPage';

  const becomeSellerLink = (
    <NamedLink
      className={classNames(css.createListingLink, css.becomeaseller)}
      name="BecomeASellerStaticPage"
    >
      <StorefrontIcon className={css.iconspace} />
      <FormattedText
        className={css.topbarDesktopLabel}
        id="Topbar.becomeASeller"
      />
    </NamedLink>
  );

  const createListingLink = (
    <>
      <NamedLink
        className={classNames(css.createListingLink, {
          [css.alignRight]: isSellerPage,
        })}
        name={sellerLandingPage}
      >
        <span className={css.createListing}>
          <StorefrontIcon className={css.iconspace} />
          <FormattedMessage id="TopbarDesktop.manageYourStore" />
        </span>
      </NamedLink>
    </>
  );

  const sellerCtaButton = isUserLoadedOnClient
    ? isSellerApproved
      ? createListingLink
      : becomeSellerLink
    : becomeSellerLink;

  return (
    <>
      {isSellerPage ? (
        <>
          <nav className={classes}>
            <LinkedLogo
              className={css.logoLink}
              format="desktop"
              alt={intl.formatMessage(
                { id: 'TopbarDesktop.logo' },
                { marketplaceName }
              )}
            />
            <NamedLink
              className={classNames(css.listingLink, css.alignRight)}
              name="ProfileSettingsPage"
            >
              <span className={css.listingLabel}>
                <PersonOutlineOutlinedIcon className={css.iconspace} />
                <FormattedMessage id="TopbarDesktop.yourAccount" />
              </span>
            </NamedLink>
            <NamedLink className={css.listingLink} name="LandingPage">
              <span className={css.listingLabel}>
                <ShoppingBagOutlinedIcon className={css.iconspace} />
                <FormattedMessage id="Topbar.shop" />
              </span>
            </NamedLink>
          </nav>
        </>
      ) : (
        <>
          <nav className={classes}>
            <LinkedLogo
              className={css.logoLink}
              format="desktop"
              alt={intl.formatMessage(
                { id: 'TopbarDesktop.logo' },
                { marketplaceName }
              )}
            />
            {!isSellerPage && search}
            {sellerCtaButton}
            {!isSellerPage && cartLink}
            {/*inboxLink*/}
            {profileMenu}
            {/*signupLink*/}
            {loginLink}
            {!isSellerPage && shipToLink}
          </nav>
          {!isSellerPage ? (
            <div className={css.topMenuBarContainer}>
              <CategoryBarContainer className={css.categoryNavBar} />
              <AboutNavBar className={css.aboutNavBar} />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
