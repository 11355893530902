import Decimal from 'decimal.js';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import { createProductReview } from '../../../../util/api';
import { denormalisedResponseEntities } from '../../../../util/data';
import {
  isTransactionsTransitionInvalidTransition,
  storableError,
} from '../../../../util/errors';
import * as log from '../../../../util/log';
import {
  createImageVariantConfig,
  types as sdkTypes,
} from '../../../../util/sdkLoader';

import { DEFAULT_CURRENCY, JH_LISTING_TYPES } from '../../../../util/types';
import { createSlug } from '../../../../util/urlHelpers';

import { addMarketplaceEntities } from '../../../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../../../ducks/user.duck';

import {
  addTrackingNumberApi,
  createShippoLabel,
  createShippoRefundLabel,
  getCarrierParcelTemplatesApi,
  requestToCancelOrderPartially,
  retrieveShippoRefundLabels,
  updateDeliveryInformation,
  validateShippoShipment,
} from '../../api';
import {
  DEFAULT_CARRIER,
  SHOPPING_CART_TRANSACTION_TYPES,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_BY_ADMIN,
  TRANSITION_CANCEL_ORDER_PARTIALLY,
  TRANSITION_REVIEW_BY_CUSTOMER,
} from '../../config/constants';
import {
  calculateTotalRefundForPartiallyRefundedOrder,
  generateId,
} from '../../utils/transaction.helpers';

const { UUID, Money } = sdkTypes;

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];

// ================ Action types ================ //

export const SET_INITIAL_VALUES =
  'app/ShoppingCartTransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST =
  'app/ShoppingCartTransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS =
  'app/ShoppingCartTransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR =
  'app/ShoppingCartTransactionPage/FETCH_TRANSACTION_ERROR';

export const TRANSITION_REQUEST =
  'app/ShoppingCartTransactionPage/MARK_RECEIVED_REQUEST';
export const TRANSITION_SUCCESS =
  'app/ShoppingCartTransactionPage/TRANSITION_SUCCESS';
export const TRANSITION_ERROR =
  'app/ShoppingCartTransactionPage/TRANSITION_ERROR';

export const SEND_REVIEW_REQUEST =
  'app/ShoppingCartTransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS =
  'app/ShoppingCartTransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR =
  'app/ShoppingCartTransactionPage/SEND_REVIEW_ERROR';

export const UPDATE_DELIVERY_INFORMATION_REQUEST =
  'app/ShoppingCartTransactionPage/UPDATE_DELIVERY_INFORMATION_REQUEST';
export const UPDATE_DELIVERY_INFORMATION_SUCCESS =
  'app/ShoppingCartTransactionPage/UPDATE_DELIVERY_INFORMATION_SUCCESS';
export const UPDATE_DELIVERY_INFORMATION_ERROR =
  'app/ShoppingCartTransactionPage/UPDATE_DELIVERY_INFORMATION_ERROR';

export const CANCEL_ORDER_PARTIALLY_REQUEST =
  'app/ShoppingCartTransactionPage/CANCEL_ORDER_PARTIALLY_REQUEST';
export const CANCEL_ORDER_PARTIALLY_SUCCESS =
  'app/ShoppingCartTransactionPage/CANCEL_ORDER_PARTIALLY_SUCCESS';
export const CANCEL_ORDER_PARTIALLY_ERROR =
  'app/ShoppingCartTransactionPage/CANCEL_ORDER_PARTIALLY_ERROR';

export const GET_CARRIER_PARCEL_TEMPLATES_REQUEST =
  'app/ShoppingCartTransactionPage/GET_CARRIER_PARCEL_TEMPLATES_REQUEST';
export const GET_CARRIER_PARCEL_TEMPLATES_SUCCESS =
  'app/ShoppingCartTransactionPage/GET_CARRIER_PARCEL_TEMPLATES_SUCCESS';
export const GET_CARRIER_PARCEL_TEMPLATES_ERROR =
  'app/ShoppingCartTransactionPage/GET_CARRIER_PARCEL_TEMPLATES_ERROR';

export const ADD_TRACKING_NUMBER_REQUEST =
  'app/ShoppingCartTransactionPage/ADD_TRACKING_NUMBER_REQUEST';
export const ADD_TRACKING_NUMBER_SUCCESS =
  'app/ShoppingCartTransactionPage/ADD_TRACKING_NUMBER_SUCCESS';
export const ADD_TRACKING_NUMBER_ERROR =
  'app/ShoppingCartTransactionPage/ADD_TRACKING_NUMBER_ERROR';

export const SPECULATE_SHIPMENT_REQUEST =
  'app/ShoppingCartTransactionPage/SPECULATE_SHIPMENT_REQUEST';
export const SPECULATE_SHIPMENT_SUCCESS =
  'app/ShoppingCartTransactionPage/SPECULATE_SHIPMENT_SUCCESS';
export const SPECULATE_SHIPMENT_ERROR =
  'app/ShoppingCartTransactionPage/SPECULATE_SHIPMENT_ERROR';

export const CREATE_SHIPPING_LABEL_REQUEST =
  'app/ShoppingCartTransactionPage/CREATE_SHIPPING_LABEL_REQUEST';
export const CREATE_SHIPPING_LABEL_SUCCESS =
  'app/ShoppingCartTransactionPage/CREATE_SHIPPING_LABEL_SUCCESS';
export const CREATE_SHIPPING_LABEL_ERROR =
  'app/ShoppingCartTransactionPage/CREATE_SHIPPING_LABEL_ERROR';

export const CREATE_REFUND_LABEL_REQUEST =
  'app/ShoppingCartTransactionPage/CREATE_REFUND_LABEL_REQUEST';
export const CREATE_REFUND_LABEL_SUCCESS =
  'app/ShoppingCartTransactionPage/CREATE_REFUND_LABEL_SUCCESS';
export const CREATE_REFUND_LABEL_ERROR =
  'app/ShoppingCartTransactionPage/CREATE_REFUND_LABEL_ERROR';

export const RETRIEVE_REFUND_LABELS_REQUEST =
  'app/ShoppingCartTransactionPage/RETRIEVE_REFUND_LABELS_REQUEST';
export const RETRIEVE_REFUND_LABELS_SUCCESS =
  'app/ShoppingCartTransactionPage/RETRIEVE_REFUND_LABELS_SUCCESS';
export const RETRIEVE_REFUND_LABELS_ERROR =
  'app/ShoppingCartTransactionPage/RETRIEVE_REFUND_LABELS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  childTransactionRefs: [],
  transitionInProgress: null,
  transitionError: null,
  savePaymentMethodFailed: false,
  sendReviewInProgress: false,
  sendReviewError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  itemListings: [],
  shippingProfiles: [],
  updateDeliveryInformationInProgress: false,
  updateDeliveryInformationError: null,
  cancelOrderPartiallyInProgress: false,
  cancelOrderPartiallyError: null,
  carrierParcelTemplates: [],
  getCarrierParcelTemplatesInProgress: false,
  getCarrierParcelTemplatesError: null,
  addTrackingNumberInProgress: false,
  addTrackingNumberError: null,
  speculatedShipment: null,
  speculateShipmentInProgress: false,
  speculateShipmentError: null,
  createShippingLabelInProgress: false,
  createShippingLabelError: null,

  createRefundLabelInProgress: false,
  createRefundLabelError: null,
  refundLabelStatuses: [],

  fetchRefundLabelInProgress: false,
  fetchRefundLabelError: null,
};

export default function transactionPageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionInProgress: true,
        fetchTransactionError: null,
      };
    case FETCH_TRANSACTION_SUCCESS: {
      const {
        transactionResponse,
        childTransactionResponses,
        itemListings,
        shippingProfiles,
      } = payload;
      const transactionRef = {
        id: transactionResponse.data.data.id,
        type: 'transaction',
      };
      const childTransactionRefs = childTransactionResponses.map(
        txResponse => ({
          id: txResponse.data.data.id,
          type: 'transaction',
        })
      );
      return {
        ...state,
        fetchTransactionInProgress: false,
        transactionRef,
        childTransactionRefs,
        itemListings,
        shippingProfiles,
      };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchTransactionInProgress: false,
        fetchTransactionError: payload,
      };

    case TRANSITION_REQUEST:
      return {
        ...state,
        transitionInProgress: payload,
        transitionError: null,
      };
    case TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: null };
    case TRANSITION_ERROR:
      return {
        ...state,
        transitionInProgress: null,
        transitionError: payload,
      };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return {
        ...state,
        sendReviewInProgress: false,
        sendReviewError: payload,
      };

    case UPDATE_DELIVERY_INFORMATION_REQUEST:
      return {
        ...state,
        updateDeliveryInformationInProgress: true,
        updateDeliveryInformationError: null,
      };
    case UPDATE_DELIVERY_INFORMATION_SUCCESS:
      return { ...state, updateDeliveryInformationInProgress: false };
    case UPDATE_DELIVERY_INFORMATION_ERROR:
      return {
        ...state,
        updateDeliveryInformationInProgress: false,
        updateDeliveryInformationError: payload,
      };

    case CANCEL_ORDER_PARTIALLY_REQUEST:
      return {
        ...state,
        cancelOrderPartiallyInProgress: true,
        cancelOrderPartiallyError: null,
      };
    case CANCEL_ORDER_PARTIALLY_SUCCESS:
      return { ...state, cancelOrderPartiallyInProgress: false };
    case CANCEL_ORDER_PARTIALLY_ERROR:
      return {
        ...state,
        cancelOrderPartiallyInProgress: false,
        cancelOrderPartiallyError: payload,
      };

    case GET_CARRIER_PARCEL_TEMPLATES_REQUEST:
      return {
        ...state,
        getCarrierParcelTemplatesInProgress: true,
        getCarrierParcelTemplatesError: null,
      };
    case GET_CARRIER_PARCEL_TEMPLATES_SUCCESS:
      return {
        ...state,
        getCarrierParcelTemplatesInProgress: false,
        carrierParcelTemplates: payload,
      };
    case GET_CARRIER_PARCEL_TEMPLATES_ERROR:
      return {
        ...state,
        getCarrierParcelTemplatesInProgress: false,
        getCarrierParcelTemplatesError: payload,
      };

    case ADD_TRACKING_NUMBER_REQUEST:
      return {
        ...state,
        addTrackingNumberInProgress: true,
        addTrackingNumberError: null,
      };
    case ADD_TRACKING_NUMBER_SUCCESS:
      return {
        ...state,
        addTrackingNumberInProgress: false,
      };
    case ADD_TRACKING_NUMBER_ERROR:
      return {
        ...state,
        addTrackingNumberInProgress: false,
        addTrackingNumberError: payload,
      };

    case SPECULATE_SHIPMENT_REQUEST:
      return {
        ...state,
        speculatedShipment: null,
        speculateShipmentInProgress: true,
        speculateShipmentError: null,
      };
    case SPECULATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        speculatedShipment: payload,
        speculateShipmentInProgress: false,
      };
    case SPECULATE_SHIPMENT_ERROR:
      return {
        ...state,
        speculateShipmentInProgress: false,
        speculateShipmentError: payload,
      };
    case CREATE_SHIPPING_LABEL_REQUEST:
      return {
        ...state,
        createShippingLabelInProgress: true,
        createShippingLabelError: null,
      };
    case CREATE_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        createShippingLabelInProgress: false,
      };
    case CREATE_SHIPPING_LABEL_ERROR:
      return {
        ...state,
        createShippingLabelInProgress: false,
        createShippingLabelError: payload,
      };
    case CREATE_REFUND_LABEL_REQUEST:
      return {
        ...state,
        createRefundLabelInProgress: true,
        createRefundLabelError: null,
      };
    case CREATE_REFUND_LABEL_SUCCESS: {
      const newRefundLabelStatuses = state.refundLabelStatuses.concat(payload);
      return {
        ...state,
        createRefundLabelInProgress: false,
        refundLabelStatuses: newRefundLabelStatuses,
      };
    }
    case CREATE_REFUND_LABEL_ERROR:
      return {
        ...state,
        createRefundLabelInProgress: false,
        createRefundLabelError: payload,
      };

    case RETRIEVE_REFUND_LABELS_REQUEST:
      return {
        ...state,
        fetchRefundLabelInProgress: true,
        fetchRefundLabelError: null,
      };
    case RETRIEVE_REFUND_LABELS_SUCCESS: {
      return {
        ...state,
        fetchRefundLabelInProgress: false,
        refundLabelStatuses: payload,
      };
    }
    case RETRIEVE_REFUND_LABELS_ERROR:
      return {
        ...state,
        fetchRefundLabelInProgress: false,
        fetchRefundLabelError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const transitionInProgress = state => {
  return state.TransactionPage.transitionInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = payload => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload,
});
const fetchTransactionError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const transitionRequest = transitionName => ({
  type: TRANSITION_REQUEST,
  payload: transitionName,
});
const transitionSuccess = () => ({ type: TRANSITION_SUCCESS });
const transitionError = e => ({
  type: TRANSITION_ERROR,
  error: true,
  payload: e,
});

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({
  type: SEND_REVIEW_ERROR,
  error: true,
  payload: e,
});

const updateDeliveryInformationRequest = () => ({
  type: UPDATE_DELIVERY_INFORMATION_REQUEST,
});
const updateDeliveryInformationSuccess = () => ({
  type: UPDATE_DELIVERY_INFORMATION_SUCCESS,
});
const updateDeliveryInformationError = e => ({
  type: UPDATE_DELIVERY_INFORMATION_ERROR,
  error: true,
  payload: e,
});

const cancelOrderPartiallyRequest = () => ({
  type: CANCEL_ORDER_PARTIALLY_REQUEST,
});
const cancelOrderPartiallySuccess = () => ({
  type: CANCEL_ORDER_PARTIALLY_SUCCESS,
});
const cancelOrderPartiallyError = e => ({
  type: CANCEL_ORDER_PARTIALLY_ERROR,
  error: true,
  payload: e,
});

const getCarrierParcelTemplatesRequest = () => ({
  type: GET_CARRIER_PARCEL_TEMPLATES_REQUEST,
});
const getCarrierParcelTemplatesSuccess = payload => ({
  type: GET_CARRIER_PARCEL_TEMPLATES_SUCCESS,
  payload,
});
const getCarrierParcelTemplatesError = e => ({
  type: GET_CARRIER_PARCEL_TEMPLATES_ERROR,
  error: true,
  payload: e,
});

const addTrackingNumberRequest = () => ({ type: ADD_TRACKING_NUMBER_REQUEST });
const addTrackingNumberSuccess = () => ({ type: ADD_TRACKING_NUMBER_SUCCESS });
const addTrackingNumberError = e => ({
  type: ADD_TRACKING_NUMBER_ERROR,
  error: true,
  payload: e,
});

const speculateShipmentRequest = () => ({ type: SPECULATE_SHIPMENT_REQUEST });
const speculateShipmentSuccess = payload => ({
  type: SPECULATE_SHIPMENT_SUCCESS,
  payload,
});
const speculateShipmentError = e => ({
  type: SPECULATE_SHIPMENT_ERROR,
  error: true,
  payload: e,
});

const createShippingLabelRequest = () => ({
  type: CREATE_SHIPPING_LABEL_REQUEST,
});
const createShippingLabelSuccess = () => ({
  type: CREATE_SHIPPING_LABEL_SUCCESS,
});
const createShippingLabelError = e => ({
  type: CREATE_SHIPPING_LABEL_ERROR,
  error: true,
  payload: e,
});

const createRefundLabelRequest = () => ({
  type: CREATE_REFUND_LABEL_REQUEST,
});
const createRefundLabelSuccess = data => ({
  type: CREATE_REFUND_LABEL_SUCCESS,
  payload: data,
});
const createRefundLabelError = e => ({
  type: CREATE_REFUND_LABEL_ERROR,
  error: true,
  payload: e,
});

const clearCreateRefundLabelError = () => ({
  type: CREATE_REFUND_LABEL_ERROR,
  error: false,
  payload: null,
});

const retrieveRefundLabelsRequest = () => ({
  type: RETRIEVE_REFUND_LABELS_REQUEST,
});
const retrieveRefundLabelsSuccess = data => ({
  type: RETRIEVE_REFUND_LABELS_SUCCESS,
  payload: data,
});
const retrieveRefundLabelsError = e => ({
  type: RETRIEVE_REFUND_LABELS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Helper to fetch correct image variants for different thunk calls
export const getImageVariants = listingImageConfig => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const showTransaction = (sdk, txId, config) =>
  sdk.transactions.show(
    {
      id: txId,
      include: [
        'customer',
        'customer.profileImage',
        'provider',
        'provider.profileImage',
        'listing',
        'listing.currentStock',
        'booking',
        'reviews',
        'reviews.author',
        'reviews.subject',
      ],
      ...getImageVariants(config.layout.listingImage),
    },
    { expand: true }
  );

const queryListings = (sdk, params, config) =>
  sdk.listings.query({
    ...params,
    include: ['author', 'images'],
    ...getImageVariants(config.layout.listingImage),
  });

const getRelatedData = async (sdk, transaction, config) => {
  const {
    protectedData: { childTransactionIds = [], type: transactionType },
    metadata: { orderDetails = {}, quantityAndProductVariation = [] },
  } = transaction.attributes;

  const itemListingIds =
    transactionType === SHOPPING_CART_TRANSACTION_TYPES.MAIN
      ? [...new Set(orderDetails.productOrder.map(item => item.listingId))]
      : transactionType === SHOPPING_CART_TRANSACTION_TYPES.CHILD
      ? quantityAndProductVariation.map(item => item.listingId)
      : [];

  const shippingProfileIds = quantityAndProductVariation.map(
    item => item.shippingProfileId
  );

  const childTransactionResponses = await Promise.all(
    childTransactionIds.map(id => showTransaction(sdk, id, config))
  );

  const itemListingResponses = await queryListings(
    sdk,
    { ids: itemListingIds },
    config
  );

  const shippingProfileResponses = await queryListings(
    sdk,
    {
      pub_shippingProfileId: shippingProfileIds.join(','),
      pub_listingType: JH_LISTING_TYPES.SHIPPING_PROFILE_LISTING,
    },
    config
  );

  return {
    childTransactionResponses,
    itemListings: denormalisedResponseEntities(itemListingResponses),
    shippingProfiles: denormalisedResponseEntities(shippingProfileResponses),
  };
};

const getRefundLineItemForChildTransaction = transaction => {
  const {
    attributes: {
      lastTransition,
      transitions,
      protectedData: { type },
      metadata: { totalRefund },
    },
  } = transaction;

  const isChildTransaction = type === SHOPPING_CART_TRANSACTION_TYPES.CHILD;

  if (isChildTransaction) {
    const isFullCanceled =
      lastTransition === TRANSITION_CANCEL ||
      lastTransition === TRANSITION_CANCEL_BY_ADMIN;

    const isPartialCanceled = transitions.some(
      ({ transition }) => transition === TRANSITION_CANCEL_ORDER_PARTIALLY
    );

    if (!isFullCanceled && isPartialCanceled) {
      const refundMoney = new Money(-1 * totalRefund.amount, DEFAULT_CURRENCY);
      const refundLineItem = {
        code: `line-item/refund`,
        includeFor: ['customer', 'provider'],
        lineTotal: refundMoney,
        quantity: new Decimal(1),
        reversal: false,
        unitPrice: refundMoney,
      };

      return refundLineItem;
    }

    return null;
  }
};

export const fetchTransaction = (id, config) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchTransactionRequest());

  try {
    const transactionResponse = await showTransaction(sdk, id, config);
    const [transaction] = denormalisedResponseEntities(transactionResponse);

    const {
      childTransactionResponses = [],
      itemListings = [],
      shippingProfiles = [],
    } = await getRelatedData(sdk, transaction, config);

    if (isEmpty(childTransactionResponses)) {
      const refundLineItem = getRefundLineItemForChildTransaction(transaction);
      if (refundLineItem) {
        transactionResponse.data.data.attributes.lineItems.push(refundLineItem);
      }
    } else {
      const canceledTransactions = childTransactionResponses.reduce(
        (acc, transaction) => {
          const {
            id: { uuid: transactionId },
            attributes: {
              lastTransition,
              transitions,
              metadata: { quantityAndProductVariation, taxData },
            },
          } = transaction.data.data;

          const { storeId, storeName } = quantityAndProductVariation[0];

          const isFullCanceled =
            lastTransition === TRANSITION_CANCEL ||
            lastTransition === TRANSITION_CANCEL_BY_ADMIN;

          const isPartialCanceled = transitions.some(
            ({ transition }) => transition === TRANSITION_CANCEL_ORDER_PARTIALLY
          );

          return isFullCanceled || isPartialCanceled
            ? [
                ...acc,
                { transactionId, storeId, storeName, isFullCanceled, taxData },
              ]
            : acc;
        },
        []
      );

      const {
        payinTotal,
        metadata: {
          orderDetails: { productOrder, shippingFees },
          taxDetails,
        },
      } = transactionResponse.data.data.attributes;

      const refunds = canceledTransactions.map(item => {
        const {
          storeId,
          storeName,
          isFullCanceled,
          taxData: currentTaxData,
        } = item;

        if (!isFullCanceled) {
          const orderedTaxData = taxDetails.find(
            item => item.storeId === storeId
          );
          return {
            storeName,
            refundTotalAmount: calculateTotalRefundForPartiallyRefundedOrder(
              currentTaxData,
              orderedTaxData
            ),
          };
        }

        const refundOrderTotalAmount = productOrder.reduce((sum, item) => {
          const {
            storeId: itemStoreId,
            price,
            quantity,
            discountAmount = 0,
          } = item;
          return itemStoreId === storeId
            ? sum + price.amount * quantity - discountAmount
            : sum;
        }, 0);

        const { shippingFeeAmount = 0, discountAmount = 0 } = shippingFees.find(
          item => item.storeId === storeId
        );

        const refundShippingTotalAmount = shippingFeeAmount - discountAmount;

        const taxAmount = taxDetails.find(item => item.storeId === storeId)
          .salesTaxAmount;

        return {
          storeName,
          refundTotalAmount:
            refundOrderTotalAmount + refundShippingTotalAmount + taxAmount,
        };
      });

      const refundTotal = refunds.reduce(
        (sum, item) => sum + item.refundTotalAmount,
        0
      );

      const refundLineItems = refunds.map(item => {
        const { storeName, refundTotalAmount } = item;
        const refundMoney = new Money(-1 * refundTotalAmount, DEFAULT_CURRENCY);
        return {
          code: `line-item/refund-of-${createSlug(storeName)}`,
          includeFor: ['customer', 'provider'],
          lineTotal: refundMoney,
          quantity: new Decimal(1),
          reversal: false,
          unitPrice: refundMoney,
        };
      });

      const updatedPayinTotal = new Money(
        payinTotal.amount - refundTotal,
        DEFAULT_CURRENCY
      );

      transactionResponse.data.data.attributes.lineItems.push(
        ...refundLineItems
      );

      transactionResponse.data.data.attributes.payinTotal = updatedPayinTotal;
    }

    dispatch(addMarketplaceEntities(transactionResponse));

    childTransactionResponses.forEach(txResponse =>
      dispatch(addMarketplaceEntities(txResponse))
    );
    dispatch(
      fetchTransactionSuccess({
        transactionResponse,
        childTransactionResponses,
        itemListings,
        shippingProfiles,
      })
    );
    return transactionResponse;
  } catch (error) {
    dispatch(fetchTransactionError(storableError(error)));
    throw error;
  }
};

export const makeTransition = (txId, transitionName, params) => (
  dispatch,
  getState,
  sdk
) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(transitionRequest(transitionName));

  return sdk.transactions
    .transition(
      { id: txId, transition: transitionName, params },
      { expand: true }
    )
    .then(response => {
      const [transaction] = denormalisedResponseEntities(response);
      const refundLineItem = getRefundLineItemForChildTransaction(transaction);
      if (refundLineItem) {
        response.data.data.attributes.lineItems.push(refundLineItem);
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(transitionSuccess());
      dispatch(fetchCurrentUserNotifications());

      // There could be automatic transitions after this transition
      // For example mark-received-from-purchased > auto-complete.
      // Here, we make one delayed update to tx.
      // This way "leave a review" link should show up for the customer.
      window.setTimeout(() => {
        sdk.transactions.show({ id: txId }, { expand: true }).then(response => {
          const [transaction] = denormalisedResponseEntities(response);
          const refundLineItem = getRefundLineItemForChildTransaction(
            transaction
          );
          if (refundLineItem) {
            response.data.data.attributes.lineItems.push(refundLineItem);
          }
          dispatch(addMarketplaceEntities(response));
        });
      }, 3000);

      return response;
    })
    .catch(e => {
      dispatch(transitionError(storableError(e)));
      log.error(e, `${transitionName}-failed`, {
        txId,
        transition: transitionName,
      });
      throw e;
    });
};

// If other party has already sent a review, we need to make transition to
// transitions.REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (
  txId,
  transition,
  params,
  dispatch,
  sdk,
  config
) => {
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition(
      { id: txId, transition, params },
      { expand: true, include, ...getImageVariants(config.layout.listingImage) }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// transitions.REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (txId, transition, params, dispatch, sdk, config) => {
  const include = REVIEW_TX_INCLUDES;
  const { listingId, ...rest } = params;
  return sdk.transactions
    .transition(
      { id: txId, transition, params: rest },
      { expand: true, include, ...getImageVariants(config.layout.listingImage) }
    )
    .then(txResponse => {
      const productID = orderData.listingId;
      const { reviewRating, reviewContent } = params;
      const reviewData = {
        rating: reviewRating,
        review: reviewContent,
        productID,
      };
      return createProductReview({
        reviewData,
        transactionId: txId,
      }).then(() => {
        dispatch(addMarketplaceEntities(txResponse));
        dispatch(sendReviewSuccess());
        return txResponse;
      });
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (tx, transitionOptionsInfo, params, config) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    reviewAsFirst,
    reviewAsSecond,
    hasOtherPartyReviewedFirst,
  } = transitionOptionsInfo;
  dispatch(sendReviewRequest());

  return hasOtherPartyReviewedFirst
    ? sendReviewAsSecond(tx?.id, reviewAsSecond, params, dispatch, sdk, config)
    : sendReviewAsFirst(tx?.id, reviewAsFirst, params, dispatch, sdk, config);
};

export const sendCartItemReview = params => (dispatch, getState, sdk) => {
  dispatch(sendReviewRequest());

  const {
    listingId,
    transactionId,
    reviewData: { reviewRating, reviewContent, variationValues },
  } = params;

  return createProductReview({
    reviewData: {
      rating: reviewRating,
      review: reviewContent,
      productID: listingId,
    },
    transactionId,
    updatedTransactionData: {
      transactionId,
      variationValues,
    },
  })
    .then(() => {
      return sdk.transactions
        .transition(
          {
            id: transactionId,
            transition: TRANSITION_REVIEW_BY_CUSTOMER,
            params: { reviewRating, reviewContent },
          },
          { expand: true }
        )
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(sendReviewSuccess());
          return response;
        });
    })
    .catch(e => {
      log.error(e, 'review-failed', {
        orderData,
        transitionName,
        params,
      });
      dispatch(sendReviewError(storableError(e)));
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value)
    ? !isEmpty(value)
    : !!value;
};

export const addDeliveryInformation = (transactionId, params) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateDeliveryInformationRequest());

  return updateDeliveryInformation({
    transactionId,
    params: {
      ...params,
      boxId: generateId(),
    },
  })
    .then(response => {
      const [transaction] = denormalisedResponseEntities(response);
      const refundLineItem = getRefundLineItemForChildTransaction(transaction);
      if (refundLineItem) {
        response.data.data.attributes.lineItems.push(refundLineItem);
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(updateDeliveryInformationSuccess());
      return response;
    })
    .catch(e => {
      dispatch(updateDeliveryInformationError(storableError(e)));
      throw e;
    });
};

export const addTrackingNumber = (transactionId, params) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(addTrackingNumberRequest());

  return addTrackingNumberApi({
    transactionId,
    ...params,
  })
    .then(response => {
      const [transaction] = denormalisedResponseEntities(response);
      const refundLineItem = getRefundLineItemForChildTransaction(transaction);
      if (refundLineItem) {
        response.data.data.attributes.lineItems.push(refundLineItem);
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(addTrackingNumberSuccess());
      return response;
    })
    .catch(e => {
      dispatch(addTrackingNumberError(storableError(e)));
      throw e;
    });
};

export const cancelOrderPartially = (
  transactionId,
  cancelItems,
  allProductIds
) => async (dispatch, getState, sdk) => {
  try {
    dispatch(cancelOrderPartiallyRequest());

    const response = await requestToCancelOrderPartially({
      transactionId,
      cancelItems,
      allProductIds,
    });

    const [transaction] = denormalisedResponseEntities(response);

    const { finalPayout } = transaction.attributes.metadata;

    if (finalPayout.amount === 0) {
      const cancelOrderInFullResponse = await sdk.transactions.transition(
        { id: transaction.id, transition: TRANSITION_CANCEL, params: {} },
        { expand: true }
      );

      dispatch(addMarketplaceEntities(cancelOrderInFullResponse));
      dispatch(cancelOrderPartiallySuccess());
      return cancelOrderInFullResponse;
    }

    const refundLineItem = getRefundLineItemForChildTransaction(transaction);
    if (refundLineItem) {
      response.data.data.attributes.lineItems.push(refundLineItem);
    }
    dispatch(addMarketplaceEntities(response));
    dispatch(cancelOrderPartiallySuccess());
    return response;
  } catch (e) {
    dispatch(cancelOrderPartiallyError(storableError(e)));
    throw e;
  }
};

export const getCarrierParcelTemplates = () => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(getCarrierParcelTemplatesRequest());

  try {
    const response = await getCarrierParcelTemplatesApi({
      carrier: DEFAULT_CARRIER,
    });
    dispatch(getCarrierParcelTemplatesSuccess(response.data.results));
    return response;
  } catch (e) {
    dispatch(getCarrierParcelTemplatesError(storableError(e)));
    throw e;
  }
};

export const speculateShipment = params => async (dispatch, getState, sdk) => {
  dispatch(speculateShipmentRequest());

  try {
    const response = await validateShippoShipment(params);
    dispatch(speculateShipmentSuccess(response.data));
    return response;
  } catch (e) {
    dispatch(speculateShipmentError(storableError(e)));
    throw e;
  }
};

export const createShippingLabel = params => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createShippingLabelRequest());

  try {
    const bodyParams = {
      ...params,
      carrier: DEFAULT_CARRIER,
    };

    const response = await createShippoLabel(bodyParams);

    const [transaction] = denormalisedResponseEntities(response);

    const refundLineItem = getRefundLineItemForChildTransaction(transaction);

    if (refundLineItem) {
      response.data.data.attributes.lineItems.push(refundLineItem);
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(createShippingLabelSuccess());
    return response;
  } catch (e) {
    dispatch(createShippingLabelError(storableError(e)));
    throw e;
  }
};

export const createRefundLabel = labelId => async (dispatch, getState) => {
  try {
    dispatch(createRefundLabelRequest());
    const transactionRef = getState().ShoppingCartTransactionPage
      .transactionRef;
    if (!transactionRef) {
      throw new Error('TransactionRef is not found');
    }

    const response = await createShippoRefundLabel(transactionRef.id.uuid, {
      labelId,
    });

    const { transactionResponse, refundLabelStatus } = response.data;
    dispatch(addMarketplaceEntities(transactionResponse));
    dispatch(createRefundLabelSuccess(refundLabelStatus));
    return response;
  } catch (error) {
    log.error(error, 'create-refund-label-failed');
    dispatch(createRefundLabelError(storableError(error)));
  }
};

const retrieveRefundLabels = (
  transactionId,
  refundLabelIds
) => async dispatch => {
  try {
    dispatch(retrieveRefundLabelsRequest());
    const refundLabelResponse = await retrieveShippoRefundLabels(
      transactionId,
      {
        refundLabelIds,
      }
    );
    const refundLabels = refundLabelResponse.data;
    dispatch(retrieveRefundLabelsSuccess(refundLabels));
  } catch (error) {
    log.error(error, 'retrieve-refund-labels-failed');
    dispatch(retrieveRefundLabelsError(storableError(error)));
  }
};

export const clearCreateRefundLabelErrorDisplay = () => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(clearCreateRefundLabelError());
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params, search, config) => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().ShoppingCartTransactionPage;
  const txRef = state.transactionRef;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, config)),
    dispatch(getCarrierParcelTemplates()),
  ]).then(([txResponse, _]) => {
    const [transaction] = denormalisedResponseEntities(txResponse);
    const { deliveryInformation } = transaction?.attributes?.metadata || {};

    if (!deliveryInformation) {
      return;
    }

    const refundLabelIds = deliveryInformation.reduce(
      (refundLabelIds, { refundLabelId }) =>
        refundLabelId ? [...refundLabelIds, refundLabelId] : refundLabelIds,
      []
    );

    if (!refundLabelIds.length) {
      return;
    }
    return dispatch(retrieveRefundLabels(transaction.id.uuid, refundLabelIds));
  });
};
