import { storableError } from '../../../util/errors';
import { getUsers, changeUserTypeCommand } from '../api';
import { denormalisedResponseEntities } from '../../../util/data';

// ================ Action types ================ //
export const FETCH_USERS_REQUEST =
  'app/AdminManageUserTypePage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS =
  'app/AdminManageUserTypePage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR =
  'app/AdminManageUserTypePage/FETCH_USERS_ERROR';

export const CHANGE_USER_TYPE_REQUEST =
  'app/AdminManageUserTypePage/CHANGE_USER_TYPE_REQUEST';
export const CHANGE_USER_TYPE_SUCCESS =
  'app/AdminManageUserTypePage/CHANGE_USER_TYPE_SUCCESS';
export const CHANGE_USER_TYPE_ERROR =
  'app/AdminManageUserTypePage/CHANGE_USER_TYPE_ERROR';

export const SEARCH_USER_REQUEST =
  'app/AdminManageUserTypePage/SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS =
  'app/AdminManageUserTypePage/SEARCH_USER_SUCCESS';
export const SEARCH_USER_ERROR =
  'app/AdminManageUserTypePage/SEARCH_USER_ERROR';

export const LOAD_MORE_USER_REQUEST =
  'app/AdminManageUserTypePage/LOAD_MORE_USER_REQUEST';
export const LOAD_MORE_USER_SUCCESS =
  'app/AdminManageUserTypePage/LOAD_MORE_USER_SUCCESS';
export const LOAD_MORE_USER_ERROR =
  'app/AdminManageUserTypePage/LOAD_MORE_USER_ERROR';

// ================ Reducer ================ //
const initialState = {
  users: [],
  currentPage: 1,
  stillLoadMore: false,
  fetchUsersInProgress: false,
  fetchUsersError: null,
  changeUserTypeInProgress: false,
  changeUserTypeError: null,
  searchUserInProgress: false,
  searchUserError: null,
  loadMoreInProgress: false,
  loadMoreError: null,
};

const AdminManageUserTypePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        users: [],
        stillLoadMore: false,
        currentPage: 1,
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      const { users, stillLoadMore } = payload;
      return { ...state, users, stillLoadMore, fetchUsersInProgress: false };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        fetchUsersError: payload,
        fetchUsersInProgress: false,
      };

    case CHANGE_USER_TYPE_REQUEST:
      return {
        ...state,
        changeUserTypeInProgress: true,
        changeUserTypeError: null,
      };
    case CHANGE_USER_TYPE_SUCCESS: {
      const { users } = state;
      const newUserData = users.map(user =>
        user.id.uuid === payload.id.uuid ? payload : user
      );

      return { ...state, users: newUserData, changeUserTypeInProgress: false };
    }
    case CHANGE_USER_TYPE_ERROR:
      return {
        ...state,
        changeUserTypeError: payload,
        changeUserTypeInProgress: false,
      };

    case SEARCH_USER_REQUEST:
      return {
        ...state,
        currentPage: 1,
        searchUserInProgress: true,
        searchUserError: null,
      };
    case SEARCH_USER_SUCCESS: {
      const { users, stillLoadMore } = payload;
      return { ...state, users, stillLoadMore, searchUserInProgress: false };
    }
    case SEARCH_USER_ERROR:
      return {
        ...state,
        searchUserError: payload,
        searchUserInProgress: false,
      };

    case LOAD_MORE_USER_REQUEST:
      return {
        ...state,
        loadMoreInProgress: true,
        loadMoreError: null,
      };
    case LOAD_MORE_USER_SUCCESS: {
      const { users, stillLoadMore, currentPage } = payload;
      return {
        ...state,
        users,
        stillLoadMore,
        currentPage,
        loadMoreInProgress: false,
      };
    }
    case LOAD_MORE_USER_ERROR:
      return {
        ...state,
        loadMoreError: payload,
        loadMoreInProgress: false,
      };

    default:
      return state;
  }
};

export default AdminManageUserTypePageReducer;

// ================ Action creators ================ //
export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});
export const fetchUsersSuccess = payload => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});
export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  payload: e,
});

export const changeUserTypeRequest = () => ({
  type: CHANGE_USER_TYPE_REQUEST,
});
export const changeUserTypeSuccess = payload => ({
  type: CHANGE_USER_TYPE_SUCCESS,
  payload,
});
export const changeUserTypeError = e => ({
  type: CHANGE_USER_TYPE_ERROR,
  payload: e,
});

export const searchUserRequest = () => ({
  type: SEARCH_USER_REQUEST,
});
export const searchUserSuccess = payload => ({
  type: SEARCH_USER_SUCCESS,
  payload,
});
export const searchUserError = e => ({
  type: SEARCH_USER_ERROR,
  payload: e,
});

export const loadMoreUserRequest = () => ({
  type: LOAD_MORE_USER_REQUEST,
});
export const loadMoreUserSuccess = payload => ({
  type: LOAD_MORE_USER_SUCCESS,
  payload,
});
export const loadMoreUserError = e => ({
  type: LOAD_MORE_USER_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const changeUserType = (userId, newUserType) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(changeUserTypeRequest());
    const response = await changeUserTypeCommand({ userId, newUserType });
    const user = denormalisedResponseEntities(response)[0];
    dispatch(
      changeUserTypeSuccess(user)
    );
    return user;
  } catch (e) {
    console.error(e);
    dispatch(changeUserTypeError(storableError(e)));
  }
};

export const searchUserByEmail = email => async (dispatch, getState, sdk) => {
  try {
    dispatch(searchUserRequest());
    const params = email ? { pub_email: email } : {};
    const response = await getUsers(params);
    const users = denormalisedResponseEntities(response);
    const { currentPage } = getState().AdminManageUserTypePage;
    const { meta } = response.data;
    dispatch(
      searchUserSuccess({
        users,
        stillLoadMore: email ? false : meta.totalPages > currentPage,
      })
    );
  } catch (e) {
    dispatch(searchUserError(storableError(e)));
  }
};

export const loadMore = nextPage => async (dispatch, getState, sdk) => {
  try {
    dispatch(loadMoreUserRequest());
    const { users } = getState().AdminManageUserTypePage;

    const response = await getUsers({ page: nextPage });
    const nextUsers = denormalisedResponseEntities(response);
    const { meta } = response.data;

    dispatch(
      loadMoreUserSuccess({
        users: [...users, ...nextUsers],
        stillLoadMore: meta.totalPages > nextPage,
        currentPage: nextPage,
      })
    );
  } catch (e) {
    dispatch(loadMoreUserError(storableError(e)));
  }
};

export const loadData = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(fetchUsersRequest());
    const { currentPage } = getState().AdminManageUserTypePage;
    const response = await getUsers();
    const users = denormalisedResponseEntities(response);
    const { meta } = response.data;
    dispatch(
      fetchUsersSuccess({ users, stillLoadMore: meta.totalPages > currentPage })
    );
  } catch (e) {
    console.error(e);
    dispatch(fetchUsersError(storableError(e)));
  }
};
